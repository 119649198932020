import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const salarySearchAPI = function (data) {
    const url = `${config.baseUrl}salary/search`;
    return postRequest({
        url,
        data,
    });
};
export const getsalaryAPI = function (data) {
    const url = `${config.baseUrl}salary/getSalary`;
    return postRequest({
        url,
        data,
    });
};
export const searchMonthSalaryAPI = function (data) {
    const url = `${config.baseUrl}salary/searchMonthSalary`;
    return postRequest({
        url,
        data,
    });
};
export const getPendingSalary = function (data) {
    const url = `${config.baseUrl}salary/getPendingSalary`;
    return postRequest({
        url,
        data,
    });
};
export const createPendingSalaryAPI = function (data) {
    const url = `${config.baseUrl}salary/createPendingSalary`;
    return postRequest({
        url,
        data,
    });
};

export const salaryCreateAPI = function (data) {
    const url = `${config.baseUrl}salary/save`;
    return postRequest({
        url,
        data,
    });
};

export const salaryUpdateAPI = function ({
    salaryId,
    data,
}) {
    const url = `${config.baseUrl}salary/update/${salaryId}`;
    return putRequest({
        url,
        data,
    });
};

export const updateIndividualSalary = function (data) {
    const url = `${config.baseUrl}salary/updateIndividualSalary`;
    return postRequest({
        url,
        data,
    });
};
export const getHubstaffData = function (data) {
    const url = `${config.baseUrl}salary/getHubstaffData`;
    return postRequest({
        url,
        data,
    });
};
