import { useState } from "react";

const ReApply = ({reApplyPayload,setReApplyPayload}) => {
  // Unique function to handle radio button change
  const handleReapplyChange = (event) => {
    const value = event.target.value === "true"; // Convert the string to a boolean
    setReApplyPayload({ ...reApplyPayload, reApply: value });
  };
    return (
        <div className="">
              <div className="d-flex align-items-center flex-wrap gap-28 px-16 pt-16 pb-10">
                <label className="form-label mb-0 text-lg">Re-Apply</label>
                {/* Radio Button 1 */}
                <div className="form-check checked-primary d-flex align-items-center gap-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    value={true}
                    name="reapply"
                    id="horizontal1"
                    checked={(reApplyPayload.reApply)} // Check if this radio button is selected
                    onChange={handleReapplyChange} // Handle state change
                  />
                  <label
                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                    htmlFor="horizontal1"
                  >
                    Yes
                  </label>
                </div>

                {/* Radio Button 2 */}
                <div className="form-check checked-secondary d-flex align-items-center gap-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="reapply"
                    id="horizontal2"
                    value={false}
                    checked={(!reApplyPayload.reApply)}
                    onChange={handleReapplyChange} // Handle state change
                  />
                  <label
                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                    htmlFor="horizontal2"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="px-16">
                <label className="form-label">Reason for rejection *</label>
                <textarea className="form-control" placeholder="Type..."
                value={reApplyPayload.reApplyComment}
                onChange={(e) => setReApplyPayload({...reApplyPayload,reApplyComment:e.target.value})}
                ></textarea>
                <small>50 characters</small>
              </div>
            </div>
    );
  };
  
  export default ReApply;