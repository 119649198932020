import React, { useEffect, useRef, useState } from 'react'
const getInitials = (name) => {
    if (!name) return '';
    const cleanName = name.replace(/\(.*?\)/g, '').trim();
    const words = cleanName.split(' ');
    const firstNameInitial = words[0]?.charAt(0).toUpperCase();
    const lastNameInitial = words[1]?.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial || ''}`;
};


const truncateNotes = (reason) => {
    return reason.length > 20 ? `${reason.substring(0, 20)}...` : reason;
};

const DeliverytableData = ({ tableData }) => {
    

      
  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code End


useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  
    console.log('Tooltips initialized:', tooltipList); // Debugging
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, [tableData]);
  
  return (
    <div> 
      <div className="TotlU">
                <ul>
                  <li className="text-dblue">₹269.9K <span className="d-block">Planed </span></li>
                  <li className="text-green">₹269.9K <span className="d-block">Delivered</span></li>
                  <li className="text-red">₹269.9K <span className="d-block">Not Achieved 5</span></li> 
                </ul> 
              </div>

        <div className="py-16 d-flex justify-content-between align-items-center"><h3 className="card-title text-violet-600">Delivery Plan</h3>
         <button type="button" className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2">Export <img src="/assets/images/left-sidebar/xls-icon.svg" className="img-fluid" alt="" />
</button>
</div>
       <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
       <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Month</th>
          <th>Milestone</th>
          <th>Planned</th>
          <th>Delivered</th>
          <th className="text-center">Status</th>
          <th>Reason</th>
          <th className="text-center">Added by</th>
          {/* <th>Action</th> */}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={row.id}>
            <td>{index + 1}</td>
            <td>{row.month}</td>
            <td>{row.milestone}</td>
            <td className="text-end">{row.planned}</td>
            <td className={`text-end ${row.status === 'Achieved' ? 'text-green' : 'text-red'}`}> {row.delivered}</td>
            <td className="text-center">
              {row.status === 'Achieved' ? (
                <span className="badge text-sm d-inline-flex align-items-center justify-content-center gap-2 fw-semibold text-success-600 bg-success-100 px-10 py-4 rounded-pill text-white"><i className="mdi mdi-check-circle mdi-24px"></i> {row.status}</span>
                
              ) : (
                <span className="badge text-sm d-inline-flex align-items-center justify-content-center gap-2 fw-semibold text-danger-600 bg-danger-100 px-10 py-4 rounded-pill text-white">
                 <i className="mdi mdi-check-circle mdi-24px"></i> {row.status}
                </span>
              )}
            </td>
            <td>
  <div
    className=""
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-custom-class="tooltip-primary"
    data-bs-title={row.reason && row.reason.trim() ? row.reason : 'Add Comment'}
  >
    {truncateNotes(row.reason || (
      <button className="badge text-sm fw-semibold bg-info-600 px-20 py-9 radius-4 text-white"  data-bs-toggle="modal" data-bs-target="#DeliveryAddComment">
        Add Comment
      </button> 
    ))}
  </div>
</td>

            <td> 


            <div className="user-cell">
                                                        <span className="user-initials">{getInitials(row.addedBy)}</span> {row.addedBy}
                                                    </div> 
            </td>
            {/* <td>
              <button style={{ color: 'blue', cursor: 'pointer', background: 'none', border: 'none' }}>Edit ✏️</button>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    </div>
  )
}

export default DeliverytableData
