import { useDispatch } from "react-redux"
import { productionAssignModuleIdAction, productionAssignTaskIdAction } from "src/containers/production-container/actions/ProductionActions"
const TaskTree = ({ taskId, task,module }) => {
  const dispatch = useDispatch();
  const handleTaskSelect = (e, task) => {
    e.stopPropagation(); // Prevent event propagation
    // setIsOpen(!isOpen)
    dispatch(productionAssignTaskIdAction({
      data: {
        taskId: task._id,
        taskName: task.taskName
      }
    }))
    dispatch(productionAssignModuleIdAction({
      data: {
        moduleId: module._id,
        moduleName: module.moduleName
      }
    }))
    document.querySelectorAll('.accordion-collapse').forEach((element) => {
      element.classList.remove('show');
    });
    document.querySelectorAll('.accordion-button').forEach((element) => {
      element.classList.add('collapsed');
    });
  }

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header" id={`flush-heading${taskId}`}>
          <button onClick={(e) => handleTaskSelect(e, task)} className="accordion-button collapsed removePlsMin" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${taskId}`} aria-expanded="false" aria-controls={`#flush-collapse${taskId}`}>
            {task.taskName}
          </button>
        </h2>
        <div id={`flush-collapse${taskId}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${taskId}`} data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
          </div>
        </div>
      </div>
    </>
  )
}
export default TaskTree