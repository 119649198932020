import React, { useRef, useEffect, useState } from 'react';
import InfoCard from './InfoCard';
import InvoiceTable from './InvoiceTable';
import InvoiceButtons from './InvoiceButtons';
import SearchInput from 'src/components/SearchInput';
import MonthPicker from 'src/components/MonthPicker';
import SummaryInvoiceTable from './SummaryInvoiceTable';
import SummaryInvoiceFilter from './SummaryInvoiceFilter';
import DatePickerInputPendingInvoive from 'src/components/DatePickerInputPendingInvoive';


const InvoiceComponent = ({ item, actionClick }) => {

  console.log("itemitemitem logs new", item);

  const [invoiceDate, setInvoiceDate] = useState('');
  const initialColumns = [
    { id: 'srNo', label: 'Sr. No.', visible: true },
    { id: 'clientInvoiceNo', label: 'Invoice Number', visible: true },
    { id: 'clientName', label: 'Customer Name', visible: true },
    { id: 'taxableAmount', label: 'Taxable Amount', visible: true },
    { id: 'gstAmount', label: 'GST Amount', visible: true },
    { id: 'totalCostInr', label: 'Total Amount', visible: true },
    { id: 'invoiceDate', label: 'Invoice Date', visible: true },
    { id: 'paymentStatus', label: 'Payment Status', visible: true },
    { id: 'paidDate', label: 'Paid Date', visible: true },
    { id: 'raisedBy', label: 'Raised By', visible: false },
    { id: 'dueDays', label: 'Due Days', visible: false },
    { id: 'rollbacked', label: 'Rollbacked', visible: false },
    { id: 'action', label: 'Action', visible: true },
    { id: 'notes', label: 'Notes', visible: false }
  ];

  const cardData = [
    {
      title: 'Total',
      totalAmount: 0, // Sum of the above two
      dueAmount: 27799237.85,   // Sum of the above two
      nonDueAmount: 7938177.50, // Sum of the above two
      dueTransactions: 103,     // Sum of the above two
      nonDueTransactions: 51,   // Sum of the above two
    },
    {
      title: 'Government',
      totalAmount: 20737415.35,
      dueAmount: 15799237.85,
      nonDueAmount: 4938177.50,
      totalTransactions: 87,
      dueTransactions: 58,
      nonDueTransactions: 29,
    },
    {
      title: 'Non-Governmental',
      totalAmount: 15000000.00,
      dueAmount: 12000000.00,
      nonDueAmount: 3000000.00,
      totalTransactions: 67,
      dueTransactions: 45,
      nonDueTransactions: 22,
    },

  ];

  const sampleData = [
    {
      invoiceNumber: 'Acx56724-25348',
      customerName: 'Natrajk (Baghu)',
      taxableAmount: '₹0',
      gstAmount: '₹0',
      totalAmount: '₹61740.00',
      invoiceDate: '16 Oct 2024',
      paymentStatus: <DatePickerInputPendingInvoive
        placeholder="MM/DD/YYYY"
        value={invoiceDate}
        onChange={setInvoiceDate} />,
      paidDate: '19 Oct 2024',
      raisedBy: 'Amrik Singh',
      dueDays: '2 Days',
      rollbacked: 'No',
      notes: 'PMDAKSH/Singrauli/OBC/11/2023 dated 08Th Dec 2023',
    },
    {
      invoiceNumber: 'Acx56724-25349',
      customerName: 'Natrajk (Baghu)',
      taxableAmount: '₹0',
      gstAmount: '₹0',
      totalAmount: '₹61740.00',
      invoiceDate: '16 Oct 2024',
      paymentStatus: <DatePickerInputPendingInvoive
        placeholder="MM/DD/YYYY"
        value={invoiceDate}
        onChange={setInvoiceDate} />,
      paidDate: '19 Oct 2024',
      raisedBy: 'Amrik Singh',
      dueDays: '2 Days',
      rollbacked: 'No',
      notes: 'PMDAKSH/Singrauli/OBC/11/2023 dated 08Th Dec 2023',
    },
    // Add more rows as needed...
  ];

  // Initialize activeTab with 0 to make the first tab active by default
  const [activeTab, setActiveTab] = useState(0);

  const handleCardClick = (index) => {
    setActiveTab(index === activeTab ? null : index); // Toggle the same tab or set a new one
  };

  const [showSummaryTable, setShowSummaryTable] = useState(false);

  // Function to handle showing the summary table
  const handleSummaryClick = () => {
    setShowSummaryTable(true);
  };


  // Function to handle hiding the summary table
  const handleSummaryClick2 = () => {
    setShowSummaryTable(false);
  };

  const calculateDueNondue = (data) => {
    const currentDate = new Date();

    let dueSum = 0.0;
    let nonDueSum = 0.0;
    let dueTotal = 0;
    let nonDueTotal = 0;

    data.forEach((invoice) => {
      const dueDate = new Date(invoice.dueDate);
      const totalCost = parseFloat(invoice.totalCostInr.$numberDecimal);

      if (currentDate > dueDate) {
        dueSum += totalCost;
        dueTotal += 1;
      } else {
        nonDueSum += totalCost;
        nonDueTotal += 1;
      }
    });

    return { dueSum, nonDueSum, dueTotal, nonDueTotal };
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 despset" style={{ display: showSummaryTable ? 'none' : 'block' }}>
          {/* Rendering InfoCards with click behavior to toggle the accordion */}
          <div className={`d-flex align-items-center justify-content-between gap-4 setbreaddrs ${item?.status.toLowerCase()}Invoice`}>
            {item?.groupOrg.map((card, index) => {
              const { dueSum, nonDueSum, dueTotal, nonDueTotal } = calculateDueNondue(card.data);
              return (
                <InfoCard
                  key={index}
                  title={card.organizationType}
                  totalAmount={card.total}
                  dueAmount={dueSum}
                  nonDueAmount={nonDueSum}
                  totalTransactions={card.data.length}
                  dueTransactions={dueTotal}
                  nonDueTransactions={nonDueTotal}
                  onClick={() => handleCardClick(index)}
                  isActive={activeTab === index} // Pass active state to style it differently
                />
              )
            })}
          </div>

          {/* Conditionally rendering the content (InvoiceTable) based on active tab */}
          {item?.groupOrg.map((row, index) => {
            return activeTab === index && (
              <div key={index} className={`col-md-12 mt-24 ${row?.organizationType}-invoice`}>
                <InvoiceTable
                  status={item?.status}
                  data={row?.data}
                  tableTitle={row?.organizationType}
                  initialColumns={initialColumns}
                  onClick={handleSummaryClick}
                  actionClick={actionClick} />
              </div>
            );
          })}
        </div>

        <div className={`col-md-12 SummaryInvoiceTable ${showSummaryTable ? '' : 'd-none'}`}>
          <SummaryInvoiceTable onClick={handleSummaryClick2} />
        </div>

      </div>
      <SummaryInvoiceFilter />
    </>
  );
};

export default InvoiceComponent;
