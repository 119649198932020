import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ClientInvoicePropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    ClientInvoiceName: PropTypes.string,
});

export const ClientInvoiceDefaultProps = { 
    ClientInvoiceName: '',
    categoryDetails: [],
    _id: undefined
};

export const ClientInvoiceYupSchema = Yup.object().shape({ 
    ClientInvoiceName: Yup.string().required('Required'),
});

export default {
    ClientInvoiceDefaultProps,
    ClientInvoicePropTypes,
    ClientInvoiceYupSchema,
};
