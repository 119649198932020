import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { productionAssignModuleIdAction } from "src/containers/production-container/actions/ProductionActions";
import TaskTree from "./TaskTree";

const ModuleTree = ({ projectId, module }) => {
  // const [isOpen,setIsOpen] = useState(false)
  const dispatch = useDispatch();
  const handleModuleSelect = (e, moduleDetails) => {
    e.stopPropagation(); // Prevent event propagation
    // setIsOpen(!isOpen)
    dispatch(productionAssignModuleIdAction({
      data: {
        moduleId: moduleDetails._id,
        moduleName: moduleDetails.moduleName
      }
    }))

  }
  return (
    <>
      {/* <div className="accordion-item">
        <h2 className="accordion-header" id="flush-heading001">
          <button onClick={(e) => handleModuleSelect(e, module)} className="accordion-button collapsed removePlsMin" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse001" aria-expanded="false" aria-controls="flush-collapse001">
            {module.moduleName}
          </button>
        </h2>
        <div id="flush-collapse001" className="accordion-collapse collapse" aria-labelledby="flush-heading001" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
          </div>
        </div>
      </div>  */}

      <div
        id={`sub-collapseOne${projectId}`}
        className="accordion-collapse collapse show"
        aria-labelledby="sub-heading6"
        data-bs-parent="#sub-accordion2"
      >
        <div className="accordion-body">
          <div
            className="accordion sub-accordion newSubCategory"
            id="sub-accordion3"
          >
            <div className="accordion-item">
              <h2
                className="accordion-header"
                id="sub-heading11"
              >
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#sub-collapseTask${module._id}`}
                  aria-expanded="true"
                  aria-controls="collapse11"
                  onClick={(e) => { handleModuleSelect(e, module) }}
                >
                  {module.moduleName}
                </button>

              </h2>
              <div
                id={`sub-collapseTask${module._id}`}
                className="accordion-collapse collapse"
                aria-labelledby="sub-heading11"
                data-bs-parent="#sub-accordion3"
              >
                <div className="accordion-body">
                  <div className="accordion accordion-flush newMoreCategory" id="accordionFlushExample">
                    {
                      module.taskIds.map((task, taskIndex) => {
                        return (
                          <TaskTree
                            task={task}
                            module={module}
                            taskId={task._id}
                            key={taskIndex}
                          />
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ModuleTree