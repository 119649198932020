import React, { useRef, useEffect, useState } from 'react';
import flatpickr from 'flatpickr';
import SelectSingalDate from './SelectSingalDate';
import SelectTypeDropdown from './SelectTypeDropdown';

const AddTimestamp = () => {

    const [milestones, setMilestones] = useState([]);

    const handleAddClick = () => {
      const newMilestone = { id: Date.now(), description: '', isExpanded: true };
      setMilestones((prevMilestones) => 
        prevMilestones.map((milestone) => ({ ...milestone, isExpanded: false }))
        .concat(newMilestone)
      );
    };
  
    const handleRemoveClick = (id) => {
      setMilestones((prevMilestones) => 
        prevMilestones.filter((milestone) => milestone.id !== id)
      );
    };
  
    const toggleExpand = (id) => {
      setMilestones((prevMilestones) => 
        prevMilestones.map((milestone) =>
          milestone.id === id
            ? { ...milestone, isExpanded: !milestone.isExpanded }
            : { ...milestone, isExpanded: false }
        )
      );
    };

    



    const [links, setLinks] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleAddLink = () => {
    if (inputValue.trim()) {
      setLinks([...links, inputValue.trim()]);
      setInputValue(''); // Clear the input field
    }
  };

  const handleRemoveLink = (index) => {
    setLinks(links.filter((_, i) => i !== index));
  };
    return (
        <div>
            <div className="modal fade cusmodl LeaveDetailsModal" id="AddTimestampModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between border-0">
                            <h6 className='modal-title text-xl'>Add Time Stamp</h6>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-between flex-column">
                                        <label htmlFor="date" className="form-label">
                                            <div className="text-neutral-900 fw-semibold text-md">Handover Date  <span className="text-danger-600">*</span></div>
                                            <small>Sales to Operate </small>
                                        </label>
                                        <SelectSingalDate />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-between flex-column">
                                        <label htmlFor="date" className="form-label">
                                            <div className="text-neutral-900 fw-semibold text-md">Kick Off Date  <span className="text-danger-600">*</span></div>

                                        </label>
                                        <SelectSingalDate />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="h-100 d-flex justify-content-between flex-column">
                                        <label htmlFor="date" className="form-label">
                                            <div className="text-neutral-900 fw-semibold text-md">Expected Project Closer Date <span className="text-danger-600">*</span></div>
                                        </label>
                                        <SelectSingalDate />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center py-16 border-top border-bottom mt-16">
                                        <div className="Text text-neutral-600 fw-medium">if this approved for continue *</div>
                                        <div className="">
                                            <div className="d-flex align-items-center flex-wrap gap-28">
                                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                    <input className="form-check-input" type="radio" name="BankDetails" id="BankDetails1" />
                                                    <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="BankDetails1" > Yes  </label>
                                                </div>
                                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                    <input className="form-check-input" type="radio" name="BankDetails" id="BankDetails2" />
                                                    <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="BankDetails2"> No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                 <div className="col-md-12 mb-16">
      {milestones.map((milestone, index) => (
        <div className="border-bottom py-8 thisdvadded" key={milestone.id}>
          <div
            className="d-flex justify-content-between align-items-center py-0 SiglShw1"
            onClick={() => toggleExpand(milestone.id)}
          >
            <div className="Text fw-semibold d-flex align-items-center gap-2 text-primary-600">
              Project milestone <span className="count">{index + 1}</span>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="btn text-danger-600 radius-8 px-10 py-2 border-0 d-flex align-items-center gap-2 text-sm"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents toggleExpand on Remove click
                  handleRemoveClick(milestone.id);
                }}
              >
                Remove <i className="mdi mdi-delete mdi-18px"></i>
              </button>
              <button className="btn text-info-600 border radius-8 px-10 py-6">
                <i className="mdi mdi-chevron-right"></i>
              </button>
            </div>
          </div>

          {milestone.isExpanded && (
            <div className="SiglShw2 my-10">
              <div className="d-flex gap-2">
                <div className="d-flex justify-content-between flex-column">
                  <label htmlFor={`date-${milestone.id}`} className="form-label">
                    <div>
                      Date<span className="text-danger-600">*</span>
                    </div>
                  </label>
                  <SelectSingalDate />
                </div>
                <div className="d-flex justify-content-between flex-column">
                  <label htmlFor={`type-${milestone.id}`} className="form-label">
                    <div>
                      Type<span className="text-danger-600">*</span>
                    </div>
                  </label>
                  <SelectTypeDropdown customClass="w-144-px" />
                </div>
              </div>
              <div className="mt-10">
                <label className="Text text-neutral-900 fw-medium form-label">
                  Description<span className="text-pink-600">*</span>
                </label>
                <textarea
                  className="form-control "
                  placeholder="Description"
                  value={milestone.description}
                  onChange={(e) =>
                    setMilestones((prevMilestones) =>
                      prevMilestones.map((item) =>
                        item.id === milestone.id ? { ...item, description: e.target.value } : item
                      )
                    )
                  }
                ></textarea>
              </div>
            </div>
          )}
        </div>
      ))}

      <div className="d-flex justify-content-between align-items-center py-10 border-bottom py-16">
        <div className="Text text-neutral-900 fw-medium">
          Project milestone<span className="text-pink-600">*</span>
        </div>
        <div>
          <button
            className="btn btn-info-600 radius-8 px-20 py-9"
            onClick={handleAddClick}
          >
            <i className="mdi mdi-plus"></i> Add
          </button>
        </div>
      </div>
    </div>

    <div className="mb-16">
      <h6 className="d-flex align-items-center gap-8 text-lg">
        <i className="mdi mdi-link-variant" /> Client Feedback Links
      </h6>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {links.map((link, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '8px 0' }}>
            <span className="badge text-sm fw-semibold w-28-px h-28-px d-flex justify-content-center align-items-center rounded-circle bg-primary-600 text-white">{index + 1}</span>
            <span className="link-text text-sm text-neutral-700" style={{ flex: 1 }}>{link}</span>
            <button 
              style={{ color: '#ff4d4f', border: 'none', background: 'none', cursor: 'pointer' }}
              onClick={() => handleRemoveLink(index)}
            >
              Remove <i className="mdi mdi-delete mdi-18px"></i>
            </button>
          </li>
        ))}
      </ul>
      <div className="d-flex align-items-center gap-8 py-10">
        <input
          type="text"
          placeholder="Client Feedback Link"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)} className="text-sm"
          style={{ flex: 1, padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
        />
        <button
          onClick={handleAddLink}
          className="plusclbtn text-lg fw-bold w-36-px h-36-px d-flex justify-content-center align-items-center rounded-circle radius-4 btn-link text-secondary-light"
          
        >
          <i className="mdi mdi-plus-circle-outline mdi-36px"></i>
        </button>
      </div>
    </div>
                                <div className="col-md-12 py-16 psstyky bg-white z-9">
                                    <div className="w-100">
                                        <button className="btn btn-info-600 radius-8 px-20 py-9 w-100"> Add Time Stamp</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTimestamp;
