import React from 'react';
import { Routes, Route } from "react-router-dom";
import SkillWiseContainer from './SkillWise/Index';
import TaskWiseContainer from './TaskWise/index';
import IndividualUserContainer from './Individual/Index';
import ModuleWiseContainer from './ModuleWise/Index';
import ClientDeliveryWiseContainer from './ClientDeliveryWise/index';
import ProjectProductionContainer from './Production/Index';
import PAndLContainer from './PAndL/Index';
import DocumentationContainer from './Documentation/index';
import VendorDeliveryContainer from './VendorDelivery/Index';
import FreelancerInvoice from 'src/components/VendorInvoicePdf/FreelancerInvoice';
import ClientInvoiceDownload from 'src/components/InvoiceDownload/ClientInvoiceDownload';
import TaxInvoiceDownload from 'src/components/InvoiceDownload/TaxInvoiceDownload';


const ViewContainer = () => {
  return (
    <>
      <Routes>
        {/* <Route index element={<SkillWiseContainer  />} />  */}
        <Route path="department/:projectId" element={<SkillWiseContainer />} />
        <Route path="task/:projectId" element={<TaskWiseContainer />} />
        <Route path="individual/:projectId" element={<IndividualUserContainer />} />
        <Route path="client-delivery/:projectId" element={<ClientDeliveryWiseContainer />} />
        <Route path="module/:projectId" element={<ModuleWiseContainer />} />
        <Route path="production/:projectId" element={<ProjectProductionContainer />} />
        <Route path="profit-and-loss/:projectId" element={<PAndLContainer />} />
        <Route path="vendor-delivery/:projectId" element={<VendorDeliveryContainer />} />
        <Route path="documentation/:projectId" element={<DocumentationContainer />} />
        <Route path="freelancer-invoice-preview/:mainId" element={<FreelancerInvoice />} />
        <Route path="tax-invoice-preview/:mainId" element={<TaxInvoiceDownload />} />
      </Routes>
    </>
  )
}

export default ViewContainer