import { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams, useLocation, Link } from 'react-router-dom';
import ProjectPermissionHOC from 'src/hoc/ProjectPermissionHOC';

const TopTabs = () => {
    const { projectId } = useParams();
    const { currentRecord} = useSelector(state => state.ProjectReducer, shallowEqual);
    const { _id: userId,userScopes } = useSelector(state => state.auth?.user, shallowEqual);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('department');

    useEffect(() => {
        // Check the current path and set the active tab accordingly
        if (location.pathname.includes('/department')) {
            setActiveTab('department');
        } else if (location.pathname.includes('/module')) {
            setActiveTab('module');
        }
        else if (location.pathname.includes('/task')) {
            setActiveTab('task');
        }
        else if (location.pathname.includes('/individual')) {
            setActiveTab('individual');
        }
        else if (location.pathname.includes('/client-delivery')) {
            setActiveTab('client-delivery');
        }
        else if (location.pathname.includes('/vendor-delivery')) {
            setActiveTab('Vendor Delivery');
        }
        else if (location.pathname.includes('/production')) {
            setActiveTab('production');
        }
        else if (location.pathname.includes('/timestamp')) {
            setActiveTab('Time Stamp');
        }
        else if (location.pathname.includes('/DeliveryPlanCMD')) {
            setActiveTab('Delivery Plan');
        }
        else if (location.pathname.includes('/profit-and-loss')) {
            setActiveTab('profit-and-loss');
        }
        else if (location.pathname.includes('/feedbacklog')) {
            setActiveTab('Feedback Log');
        }
        else if (location.pathname.includes('/documentation')) {
            setActiveTab('documentation');
        }
    }, [location.pathname]);

    const TabsConst = [
        { name: "Department",scope: 'view_report.skill.view', url: `/projects/view/department/${projectId}` },
        { name: "Task", scope: 'view_report.task.view',url: `/projects/view/task/${projectId}` },
        { name: "Individual",scope: 'view_report.individual.view', url: `/projects/view/individual/${projectId}` },
        { name: "Client-Delivery",scope: 'view_report.client_delivery.view', url: `/projects/view/client-delivery/${projectId}` },
        { name: "Vendor Delivery",scope: 'view_report.skill.view', url: `/projects/view/vendor-delivery/${projectId}` },//temp for vendor delivery
        { name: "Module",scope: 'view_report.module.view', url: `/projects/view/module/${projectId}` },
        { name: "Production",scope: 'view_report.production.view', url: `/projects/view/production/${projectId}` },
        // { name: "Time Stamp",scope: 'view_report.production.view', url: `/timestamp` },
        // { name: "Delivery Plan",scope: 'view_report.production.view', url: `/DeliveryPlanCMD` },
        // { name: "Project Documentation", url: `/projects/view/project-documentation/${projectId}` },
        // { name: "Vendor Payment", url: `/projects/view/vendor-payment/${projectId}` },
        { name: "Profit-and-Loss",scope: 'view_report.profit_and_loss', url: `/projects/view/profit-and-loss/${projectId}` },
        // { name: "Feedback Log",scope: 'view_report.feedback_log.view', url: `/projects/view/feedbacklog/${projectId}` },
        // { name: "Documentation",scope: 'view_report.documentation.view', url: `/projects/view/documentation/${projectId}` },
        // { name: "Vendor Production",scope: 'view_report.vendor_delivery.view', url: `/projects/view/vendor-production/${projectId}` },
    ];
    const permissions = currentRecord?.permissions?.length > 0 ? currentRecord?.permissions[0]?.permissions:[];
    const isFullAccess = currentRecord?.participants?.some(participent => participent.userId._id === userId && participent.role === "Manager");
    const isFullAccessLocal = userScopes?.some(permission => permission == "92");
    return (
        <div className="tabsAllPages"> 
        {/* topfixedsciky */}
            <nav className="tabs">
                <ul className="-primary nav my-16">
                    {TabsConst.map((tab) => (
                         <ProjectPermissionHOC scope={tab.scope} scopeMasterList={permissions} isFullAccess={isFullAccess||isFullAccessLocal}>
                        <li key={tab.name} className={activeTab.toLowerCase() === tab.name.toLowerCase() ? 'active' : ''}>
                            <Link
                                to={tab.url}                                
                            >
                                {tab.name}
                            </Link>
                        </li>
                        </ProjectPermissionHOC>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default TopTabs;
