import { shallowEqual, useSelector } from "react-redux"

const CommentModal=()=>{
const {currentRecord}=useSelector(state=>state.ProductionReducer,shallowEqual)

    return (
<div
  className="modal fade cusmodl"
  id="CommentModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  >
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title text-xl" id="exampleModalLabel">
          Comment
        </h5>
        <button
          type="button"
          className="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
         ✗
        </button>
      </div>
      <div className="modal-body">
        <p>
          {currentRecord?.comment}
        </p>
      </div>
    </div>
  </div>
  </div>
    )
}

export default CommentModal