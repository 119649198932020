import React, { useEffect, useRef, useState } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

const MonthPicker = ({ handleChangeDate,lastMonthMidDate }) => {
  const inputRef = useRef(null);
  const leftBtnRef = useRef(null);
  const rightBtnRef = useRef(null);
  const calendarIconRef = useRef(null);
  const flatpickrInstance = useRef(null);
  const [selectedDate, setSelectedDate] = useState(lastMonthMidDate||new Date());

  useEffect(() => {
    // Initialize flatpickr with a unique input reference
    flatpickrInstance.current = flatpickr(inputRef.current, {
      plugins: [
        monthSelectPlugin({
          shorthand: false,
          dateFormat: "F Y",
          altFormat: "F Y",
          theme: "light"
        })
      ],
      defaultDate: selectedDate,
      onChange: (selectedDates) => {
        const newDate = selectedDates[0];
        setSelectedDate(newDate); // Update state with selected date
        handleChangeDate(newDate); // Call parent handler
      }
    });

    // Event handlers for month navigation
    const handleLeftClick = () => {
      const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() - 1));
      setSelectedDate(new Date(newDate)); // Update state
      handleChangeDate(newDate);
    };

    const handleRightClick = () => {
      const newDate = new Date(selectedDate.setMonth(selectedDate.getMonth() + 1));
      setSelectedDate(new Date(newDate)); // Update state
      handleChangeDate(newDate);
    };

    const handleCalendarIconClick = () => {
      flatpickrInstance.current.open();
    };

    // Attach event listeners
    leftBtnRef.current.addEventListener("click", handleLeftClick);
    rightBtnRef.current.addEventListener("click", handleRightClick);
    calendarIconRef.current.addEventListener("click", handleCalendarIconClick);

    // Cleanup event listeners and flatpickr on unmount
    return () => {
      leftBtnRef.current?.removeEventListener("click", handleLeftClick);
      rightBtnRef.current?.removeEventListener("click", handleRightClick);
      calendarIconRef.current?.removeEventListener("click", handleCalendarIconClick);
      flatpickrInstance.current?.destroy();
    };
  }, [selectedDate, handleChangeDate]);

  // Sync flatpickr with the selectedDate state whenever it changes
  useEffect(() => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.setDate(selectedDate, false); // Update flatpickr without triggering onChange
    }
    inputRef.current.value = flatpickr.formatDate(selectedDate, "F Y"); // Display selected date in input
  }, [selectedDate]);

  return (
    <div className="monthpeker d-flex align-items-center gap-2 justify-content-end position-relative">
      <button
        ref={leftBtnRef}
        className="leftBtn page-link bg-primary-50 text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-44-px w-48-px">
        <iconify-icon icon="iconamoon:arrow-left-2-light" className="text-xxl"></iconify-icon>
      </button>
      <div className="position-relative">
        <span ref={calendarIconRef} className="calendar-icon position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
          <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
        </span>
        <input
          ref={inputRef}
          type="text"
          placeholder="Select Date.."
          className="form-control radius-8 h-44-px"
          readOnly
        />
      </div>
      <button
        ref={rightBtnRef}
        className="rightBtn page-link bg-primary-50 text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-44-px w-48-px">
        <iconify-icon icon="iconamoon:arrow-right-2-light" className="text-xxl"></iconify-icon>
      </button>
    </div>
  );
};

export default MonthPicker;