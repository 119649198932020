import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { VENDOR_DELIVERY_ACTIONS } from '../actions/VendorDeliveryActions';
import { VendorDeliveryDefaultProps } from '../VendorDeliveryPropTypes';

const initialState = () => ({
    currentRecord: { ...VendorDeliveryDefaultProps },
    assignedProjects: [],
    assignedTasks: [],
    assignedModules: [],
    selectedClient: {clientId:null,ClientProjectCode:null},
    selectedProject: {projectId:null,ProjectSubCode:null},
    selectCategory: {categoryId:null,categoryName:null},
    selectSubCategory: {subCategoryId:null,subCategoryName:null},
    selectedMonth: new Date().getMonth() + 1,
    selectedYear: new Date().getFullYear(),
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    totalSummary:{
        totalPaidUnits: 0,
        totalUnPaidUnits: 0,
        totalPaidAmount: 0,
        FullyPaidCount: 0,
        PartiallyPaidCount: 0
    },
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                totalSummary: {
                    ...state.totalSummary, 
                    ...action.data.summary
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_CURRENT_CLIENT_PRODUCTION:{
            return {
                ...state,
                currentRecord: action.data,
            };
        }

        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_ASSIGNED_PROJECTS_SEARCH: {
            return {
                ...state,
                assignedProjects: action.data,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_TASK_BY_PROJECT_SEARCH: {
            return {
                ...state,
                assignedTasks: action.data,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_MODULE_BY_TASK_SEARCH: {
            return {
                ...state,
                assignedModules: action.data,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_ASSIGN_CATEGORY: {
            return {
                ...state,
                selectCategory: action.data,
                selectSubCategory: {subCategoryId:null,subCategoryName:null},
            };
        }
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_ASSIGN_SUB_CATEGORY: {
            return {
                ...state,
                selectSubCategory: action.data,
            };
        }        
        case VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_RESET: {
            return {
                ...state,
                currentRecord: { ...VendorDeliveryDefaultProps },
            };
        }

        default:
            return state;
    }
};
