import React, { useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { size } from 'lodash';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { vendorDeliveryInvoiceGetAPI } from 'src/api/VendorDeliveryApi';
import { convertToWords, formatDate } from 'src/utils/utils';

const FreelancerInvoice = () => {
    const { mainId } = useParams();
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
   // Function to handle the PDF generation and download
   const handleDownload = () => {
    const element = document.getElementById('invoiceContent'); // Element to be converted to PDF

    // Use html2canvas to capture the content as an image
    html2canvas(element, {
        scale: 5, // Increase the scale for better quality
        useCORS: true, // Enable CORS to handle external images (if any)
        logging: true, // Log the process to console for debugging
        backgroundColor: '#ffffff', // Make sure background is white (can adjust if you need)
    }).then((canvas) => {
        // Convert canvas to image data
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('l', 'mm', 'a4'); // A4 paper size in mm (portrait mode)

        // Adjust the image size to fit the PDF page
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const scaleX = pdfWidth / canvasWidth;
        const scaleY = pdfHeight / canvasHeight;
        const scale = Math.min(scaleX, scaleY); // Scale to fit within the page

        const xOffset = (pdfWidth - canvasWidth * scale) / 2; // Center horizontally
        const yOffset = 0; // Set yOffset to 0 to start from the top

        // Add image to PDF with calculated position and scaling
        pdf.addImage(imgData, 'JPEG', xOffset, yOffset, canvasWidth * scale, canvasHeight * scale);

        // Save the PDF with a name
        pdf.save('invoice.pdf');
    });
};
const getInvoice =async(id) => {
  const invoice = await vendorDeliveryInvoiceGetAPI({_id:id}); 
  setInvoices(invoice?.data?.data || [])
  setLoading(false)
}
useEffect(()=>{   
  if(mainId){
    getInvoice(mainId)
  }
},[mainId])

if (loading) {
    return <div>Loading...</div>; // Render loading indicator
  }
 
    return (

        <>
            {/* Button to trigger the PDF download */}
            <button onClick={handleDownload}>Download PDF</button>

            <div  id="invoiceContent" style={{ width: "100%", maxWidth: 1080, margin: "auto", color: "#333", fontFamily: "Inter, sans-serif", backgroundColor: "#fff", padding: 20, borderRadius: 8, lineHeight: 1.5, fontSize: 14 }}>
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n        body,p,h3,h2,h1,h4{\n  font-family: Inter, sans-serif;\n  font-optical-sizing: auto; \n  font-style: normal;\n}\n"
                    }}
                />
               
               <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 10}}>
               <div style={{ fontSize: "40px", color: "#0073EA", padding: "0px 0px 0px 0px", borderRadius: "8px 8px 0px 0", margin: 0, lineHeight: "1.3", fontWeight: "bold" }}>Invoice <div style={{fontSize: "14px", fontWeight: "bold",color: "#7B7E8B",}}>INVOICE NO: <span style={{color: "#000" }}>{invoices?.invoiceNo}</span></div></div>
               <span><img src="/assets/images/acadecraft-logo.svg" alt="" /></span>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{ borderTop: "1px solid #ccc",borderBottom: "1px solid #ccc",padding: "10px 0" }}>
                    <div className="w-100 d-flex align-items-center" style= {{fontSize: "10px",color: "#7B7E8B",textTransform: "uppercase",fontWeight:'normal',lineHeight: 1.6 }}>Project Code: <span className="fw-bold" style={{ fontSize: "10px", fontWeight:500,color: "#000",paddingLeft:"5px", }}>{invoices?.projectId.RefClientId.ClientProjectCode}</span></div>
                    <div className="w-100"  style= {{fontSize: "10px",color: "#7B7E8B",textTransform: "uppercase",fontWeight:'normal',lineHeight: 1.6 }}>Invoice Date: <span style={{ fontSize: "10px", fontWeight:500,color: "#000",paddingLeft:"5px", }}>{formatDate(invoices?.date)}</span></div>
                    {/* <div className="w-100"  style= {{fontSize: "10px",color: "#7B7E8B",textTransform: "uppercase",fontWeight:'normal',lineHeight: 1.6 }}>Delivery period: <span style={{ fontSize: "10px", fontWeight:500,color: "#000",paddingLeft:"5px", }}>August-September</span></div> */}
                    <div className="w-100"  style= {{fontSize: "10px",color: "#7B7E8B",textTransform: "uppercase",fontWeight:'normal',lineHeight: 1.6 }}>Project Manager: <span style={{ fontSize: "10px", fontWeight:500,color: "#000",paddingLeft:"5px", }}>{`${invoices?.userId.firstName} ${invoices?.userId.lastName}`}</span></div> 
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", marginBottom: 0  }}>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 15, lineHeight: "1.4", fontWeight: "500",color: "#0073EA" }}>From</p>
                        <p style={{ margin: 0, fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>{`${invoices?.vendorId.firstName} ${invoices?.vendorId.lastName}`}</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", marginTop: 0, fontWeight: "bold" }}>GSTIN: NA</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex" }}>Add: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId.address}</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>City: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId.city}</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId?.state}</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State Code: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId?.state_code}</span></p>
                    </div>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 12, lineHeight: "1.6",fontWeight: "500", color: "#0073EA" }}>To</p>
                        <p style={{ margin: "2px 0", fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>Acadecraft Pvt. Ltd.</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", marginTop: 0, fontWeight: "bold" }}>GSTIN: 09AANCA8296L2ZP</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex", marginTop: 10 }}>Address of Delivery: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>H-54, Sector 63, Gautam Buddha Nagar, Noida, UP-201301</span></p> 
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>City: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Noida</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Uttar Pradesh</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State Code: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>09</span></p>
                    </div> 
                </div> 
                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 7, fontSize: 12, lineHeight: "1.5" }}>
                    <thead>
                        <tr style={{ backgroundColor: "#fff", color: "#7B7E8B", fontWeight: "normal", verticalAlign: "middle" }}>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", width: "50px" }}>Sr. No.</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Description of Goods/Services</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Unit of Measurement</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Unit Price</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Total Units</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Total Value</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            invoices?.invoiceDetails?.map((item, index) => (
                                <tr key={index}>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle", width: "50px" }}>{(index+1)}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.deliveryId.productionSlug?.split('>')?.slice(-2)?.join('>')}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.deliveryId.uom}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.perUnitCost}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.paidUnit}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle",textAlign: "right" }}>₹{item.amount}</td>
                            </tr>
                            ))
                        }
                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #ddd", padding: 8, textAlign: "end", lineHeight: "1.6", fontSize: 12, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                Total
                            </td>
                            <td colSpan={4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "end", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle",textAlign: "right" }}>
                            ₹{invoices.totalAmount}
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", verticalAlign: "middle" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Rounding off
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                ₹{invoices.totalAmount}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in Fig)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 14, color: "#323338",fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                INR{invoices.totalAmount.toLocaleString()}/-
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in words)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                               {convertToWords(invoices.totalAmount)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Amount of tax subject to reverse charges
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan={4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #ddd", verticalAlign: "middle" }}>
                                <table style={{ width: "100%", borderCollapse: "collapse", verticalAlign: "middle" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", borderTop: 0, borderLeft: 0, padding: "0 8px 7px 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Holder Name</td>
                                            <td style={{ border: "1px solid #ddd", borderTop: 0, padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>Acadecraft Private Limited</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Name of Bank</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICI BANK LTD</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account No</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>071901527809</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Type</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>SAVING</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>IFSC Code</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICIC0000719</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Swift Code</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICINBBCTS</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Bank Address</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>Sector 18 Noida</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan={4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                <p style={{ lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold" }}>For Acadecraft Private Limited</p>
                                <p><img src="/assets/images/authirisedsignatory1.svg" /></p>
                                <p style={{ lineHeight: "1.6", fontSize: 10, marginBottom: "0", color: "#323338", fontWeight: "normal", verticalAlign: "middle" }}>Authorised Signatory</p>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <div style={{ marginTop: 10, fontSize: 12, lineHeight: "1.6" }}>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: "normal", display: "flex" }}>
                        <span style={{ fontWeight: "bold" }}>Note:</span> This invoice was system
                        generated, signature are not required
                    </p>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: "normal", display: "flex" }} >
                        <span style={{ fontWeight: "bold" }}>Declaration:</span> We declare that
                        this invoice shows the actual price of the goods described and that all
                        particulars are true and correct.
                    </p>
                </div>
            </div>
         

        </>
    );
};

export default FreelancerInvoice
