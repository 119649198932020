import { put, call } from 'redux-saga/effects';
import { vendorDeliveryCreateAPI, vendorDeliverySearchAPI, vendorDeliveryUpdateAPI } from 'src/api/VendorDeliveryApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { toast } from 'react-toastify';
import { VENDOR_DELIVERY_ACTIONS } from '../actions/VendorDeliveryActions';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(vendorDeliverySearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });
        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(vendorDeliverySearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {
            
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }

        
        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(vendorDeliveryCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        })); 
        toast("Production Created")
        
        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT_ERROR,
        });
    }
}

function* update(productionId, payload) {
    try {
        yield call(vendorDeliveryUpdateAPI, {
            productionId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        if(payload.deleted===true){
            toast("Production deleted")
        }
        else{
            toast("Production updated")
        } 
        
        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH, search),
    takeFirstSagaUtil(VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT, upsert),
    takeFirstSagaUtil(VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_EDIT, edit),
];
