import { useState } from "react";

function SelectTypeDropdown({customClass}) {
  const [selectedOption, setSelectedOption] = useState("Select Type");

  const handleSelect = (label) => {
    setSelectedOption(label);
  };

  return (
    <div className={`btn-group w-100 ${customClass || ""}`}>
      <button
        type="button"
        className="btn rounded-2 bg-white border d-flex justify-content-between align-items-center text-sm"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-reference="parent"
      >
        {selectedOption} <i className="mdi mdi-chevron-down mdi-18px"></i>
      </button>
      <ul className="dropdown-menu">
        <li className="mb-8">
          <div
            className="form-check checked-primary d-flex align-items-center gap-2 radio-check"
            onClick={() => handleSelect("Alpha")}
          >
            <input className="form-check-input" type="radio" name="radio" id="radio1" />
            <label className="form-check-label line-height-1 fw-medium text-secondary-light text-sm" htmlFor="radio1">
              Alpha
            </label>
          </div>
        </li>
        <li className="mt-8">
          <div
            className="form-check checked-primary d-flex align-items-center gap-2 radio-check"
            onClick={() => handleSelect("Beta")}
          >
            <input className="form-check-input" type="radio" name="radio" id="radio2" />
            <label className="form-check-label line-height-1 fw-medium text-secondary-light text-sm" htmlFor="radio2">
              Beta
            </label>
          </div>
        </li>
        <li className="mt-8">
          <div
            className="form-check checked-primary d-flex align-items-center gap-2 radio-check"
            onClick={() => handleSelect("Gold")}
          >
            <input className="form-check-input" type="radio" name="radio" id="radio3" />
            <label className="form-check-label line-height-1 fw-medium text-secondary-light text-sm" htmlFor="radio3">
              Gold
            </label>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default SelectTypeDropdown
