import { useState } from "react"
import Attendance from "./Attendance"
import HubStaffHours from "./HubStaffHours"
import Information from "./Information"
import ProjectModuleTaskCost from "./ProjectModuleTaskCost"
import ProductionStatus from "./ProductionStatus"
import Productivity from "./Productivity"
import ProjectModuleWiseCost from "./ProjectModuleWiseCost"
import ProjectWiseCost from "./ProjectWiseCost"
import PerfomanceProductionList from "./PerfomanceProductionList"
import ProductivityvsHubstaff from "./ProductivityvsHubstaff"
import ProductionReview from "./ProductionReview"



const EmployeePerfomanceContainer=()=>{
    const [projectId, setProjectId] = useState(null);
    const [moduleId, setModuleId] = useState(null);
    const [user,setUser] = useState({})
    const [salaryDetails,setSalaryDetails] = useState(0)
    return (
        <>
         <div> 
            <div className="row gy-4">
                <Information user={user} setUser={setUser} salaryDetails={salaryDetails} setSalaryDetails={setSalaryDetails}/>
                <Attendance/>
                <HubStaffHours/>
                {/* <Productivity/> */}
                <ProductivityvsHubstaff />
                <ProductionReview />
                <ProjectWiseCost setProjectId={setProjectId} user={user}/>
                <ProjectModuleWiseCost projectId={projectId} setModuleId={setModuleId}/>
                <ProjectModuleTaskCost moduleId={moduleId}/>
                <PerfomanceProductionList/>
                 
            </div>
        </div>
        </>
    )
}
export default EmployeePerfomanceContainer