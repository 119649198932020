import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Highcharts3D from 'highcharts/highcharts-3d';
import { useCallback, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { getProductivityVsActivityAPI } from 'src/api/PerfomanceAPI';
Highcharts3D(Highcharts);

const Productivity = () => {
  const { employeeID, userId } = useParams();
  const [series, setSeries] = useState([]);
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  
  const [where, setWhere] = useState({
    userId,
    $expr: {
      $and: [
        { $eq: [{ $year: "$date" }, selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] }
      ]
    }
  });

  const [whereMySql, setWhereMySql] = useState({
    empid: employeeID,
    year: selectedYear,
    month: selectedMonth,
  });

  const chartRef = useRef(null); // Create a ref for the chart
  const [isLeftDisabled, setIsLeftDisabled] = useState(true); // Initially, left button is disabled
  const [isRightDisabled, setIsRightDisabled] = useState(false); // Initially, right button is enabled

  const search = useCallback(async () => {
    const hubstaffData = await getProductivityVsActivityAPI({ where, whereMySql });
    setSeries(hubstaffData.data.series);
  }, [where, whereMySql]);

  useEffect(() => {
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, selectedYear] },
          { $eq: [{ $month: "$date" }, selectedMonth] }
        ]
      }
    });

    setWhereMySql({
      ...whereMySql,
      year: selectedYear,
      month: selectedMonth
    });
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    search();
  }, [where, whereMySql]);


  const customText = " (55%)";

  const options = {
    chart: {
      type: 'column',
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 1,
        depth: 0,
        viewDistance: 40,
      },
      panning: true,
      panKey: 'shift', // Hold shift key to scroll
    },
    title: {
      text: 'Productivity Vs Activity',
      align: 'left',
      style: {
        float: 'left',
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: 'category',
      title: {
        text: 'Date',
      },
      labels: {
        formatter: function () {
            return `${this.value}</br>${customText}`; // Append custom text to the date
        },
    },
      scrollbar: {
        enabled: true, 
      },
      max: 6, // Initially show only 7 days (indices 0 to 6)
    },
    yAxis: {
      title: {
        text: 'Percentage Value',
      },
      min: 0,
    },
    
    series: series,
    colors: ['#22C55E', '#487fff', '#EF4444'],
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
      floating: true,
      x: -10,
      y: 10,
    },
     plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        const value = this.y;
                        const hours = Math.floor(value);
                        const minutes = Math.round((value % 1) * 60);
                        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`; // Format as HH:MM
                    },
                },
            },
        },
    tooltip: {
      shared: true,
      formatter: function () {
        let tooltipText = `<b></b><br/>`;
        this.points.forEach(point => {
          tooltipText += `${point.series.name}: <b>${point.y} %</b><br/>`;
        });
        return tooltipText;
      },
    },
  };

  const updateButtonState = () => {
    const chart = chartRef.current?.chart; // Use optional chaining to ensure chartRef is not null
    if (!chart) return; // Ensure chart is not null
    const xAxis = chart.xAxis[0];
    const min = xAxis.min;
    const max = xAxis.max;
    const dataLength = series[0]?.data.length || 0; // Ensure data length exists

    // Disable left button if at the start
    setIsLeftDisabled(min === 0);
    // Disable right button if at the end
    setIsRightDisabled(max >= dataLength - 1);
  };

  const handleScrollProductivityLeft = () => {
    const chart = chartRef.current?.chart; // Access the chart instance using ref
    if (!chart) return;
    chart.xAxis[0].setExtremes(chart.xAxis[0].min - 1, chart.xAxis[0].max - 1); // Scroll left by 1
    updateButtonState(); // Update button state after scroll
  };

  const handleScrollProductivityRight = () => {
    const chart = chartRef.current?.chart; // Access the chart instance using ref
    if (!chart) return;
    chart.xAxis[0].setExtremes(chart.xAxis[0].min + 1, chart.xAxis[0].max + 1); // Scroll right by 1
    updateButtonState(); // Update button state after scroll
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card h-100 p-0">
          <div className="card-body p-24">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef} // Attach the ref to the chart component
              callback={updateButtonState} // Callback to update button state when chart is rendered
            />
            <div className="d-flex justify-content-end p-2 gap-2">
              <button 
                className="btn btn-primary" 
                onClick={handleScrollProductivityLeft} 
                disabled={isLeftDisabled} // Disable button based on state
              >
                <i className="mdi mdi-keyboard-backspace"></i>
              </button>
              <button 
                className="btn btn-primary" 
                onClick={handleScrollProductivityRight} 
                disabled={isRightDisabled} // Disable button based on state
              >
                <i className="mdi mdi-keyboard-backspace mdi-rotate-180"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Productivity
