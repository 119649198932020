import { useState } from "react";
import { toast } from 'react-toastify';
import { createPendingSalaryAPI } from "src/api/SalaryApi";

const CreatePendingSalaryButton = ({ employee, where,search }) => {
    const [creating, setCreating] = useState(false);
    const handleCreatePendingSalary = async (employee) => {
        setCreating(true);
        if (window.confirm("Are you sure you want to create pending salary for selected employees?")) {           
            const data = {
                employeeId: employee._id,
                month: where.month,
                year: where.year,
            }           
            try {
                 const response = await createPendingSalaryAPI(data);
                 search();
                toast.success(response.data.message);
                const responseData = response.data.data[0];
                window.open(`/add-salary-slip/${responseData._id}`, '_blank');
            } catch (error) {
                // console.error(error)
                toast.error(error.response?.data?.message);
            }
        }
    }
    return (
        <>
            {creating && <div className="loader"></div>}
            <button className="btn btn-sm btn-primary-600 radius-8"
                onClick={() => (handleCreatePendingSalary(employee))}
            >Create</button>
        </>
    )
}
export default CreatePendingSalaryButton