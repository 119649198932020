import React, { useState } from "react";
import CreatePendingSalaryButton from "./CreatePendingSalaryButton";

// Utility function to get initials from name
const getInitials = (name) => {
  if (!name) return '';
  const words = name.trim().split(' ');
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ''}`;
};

// Utility function to generate color from a string (e.g., client name)
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // Ensure it's a valid hex color
};

const AddEmployeeSalaryTable = ({ employees, where,search }) => {
  // Sample data array
  // const [employees, setEmployees] = useState([
  //   { id: 1, name: "Kiran Mazumdar", salary: 18500 },
  //   { id: 2, name: "Hasmukhbhai Parekh", salary: 18500 },
  //   { id: 3, name: "Dhirubhai Ambani", salary: 18500 },
  //   { id: 4, name: "Seth Shiv Narayan Birla", salary: 18500 },
  //   { id: 5, name: "J. R. D. Tata", salary: 18500 },
  //   { id: 6, name: "Shiv Nadar", salary: 18500 },
  //   { id: 7, name: "Arjun Malhotra", salary: 18500 },
  //   { id: 8, name: "Qimat Rai Gupta", salary: 18500 },
  //   { id: 9, name: "Raghvendra Singh", salary: 18500 },
  //   { id: 10, name: "Karsanbhai Patel", salary: 18500 },
  //   { id: 11, name: "Gautam Adani", salary: 18500 },
  //   { id: 12, name: "Gautam Adani", salary: 18500 },
  // ]);

  // Filter and selection states
  const [nameFilter, setNameFilter] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // Unique filter options
  const uniqueNames = [...new Set(employees.map((employee) => `${employee.firstName} ${employee.lastName}`))];

  // Handle filter changes
  const handleCheckboxChange = (filter, setFilter, value) => {
    if (filter.includes(value)) {
      setFilter(filter.filter((item) => item !== value));
    } else {
      setFilter([...filter, value]);
    }
  };

  // Filter the employee data based on selected filters
  const filteredEmployees = employees.filter((employee) => {
    const matchName = nameFilter.length ? nameFilter.includes(`${employee.firstName} ${employee.lastName}`) : true;
    return matchName;
  });

  // Handle select/unselect all rows
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(filteredEmployees.map((emp) => emp.id));
    } else {
      setSelectedRows([]);
    }
  };

  // Handle individual row selection
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  return (
    <div>
      <table className="table basic-border-table mt-24 table-hover">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox" className="form-check-input"
                onChange={handleSelectAll}
                checked={selectedRows.length === filteredEmployees.length && filteredEmployees.length > 0}
                style={{ width: "24px", height: "24px" }}
              />
            </th>
            <th className="align-middle">S.No.</th>
            <th className="align-middle">
              <div className="d-flex justify-content-between align-items-center">
                Employees Name
                <div className="dropdown">
                  <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-filter-variant mdi-24px"></i>
                  </button>
                  <ul className="dropdown-menu border  dropdown-menu-end mx-hight200px">
                    {uniqueNames.map((name, index) => (
                      <li key={index} className="dropdown-item">
                        <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={name}
                            checked={nameFilter.includes(name)}
                            onChange={() => handleCheckboxChange(nameFilter, setNameFilter, name)}
                          />
                          <label className="form-check-label">{name}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </th>
            <th className="align-middle">Salary</th>
            <th className="align-middle">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.map((employee, index) => (
            <tr key={employee.id}>
              <td>
                <input
                  type="checkbox" className="form-check-input"
                  checked={selectedRows.includes(employee.id)}
                  onChange={() => handleSelectRow(employee.id)}
                  style={{ width: "24px", height: "24px" }}
                />
              </td>
              <td>{index + 1}</td>
              <td>
                <span
                  className="client-initials img imgsSet"
                  style={{
                    backgroundColor: stringToColor(`${employee.firstName} ${employee.lastName}`),
                    color: "#fff",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                  }}
                >
                  {getInitials(`${employee.firstName} ${employee.lastName}`)}
                </span>
                {`${employee.firstName} ${employee.lastName}`}
              </td>
              <td>{employee.monthlySalary}</td>
              <td>
                <CreatePendingSalaryButton 
                employee={employee} 
                where={where}
                search={search}
                 />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddEmployeeSalaryTable;
