import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { VENDOR_ACTIONS } from '../actions/VendorActions';
import { VendorDefaultProps } from '../VendorPropTypes';

const initialState = () => ({
    currentRecord: { ...VendorDefaultProps },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    invoiceSearch: {
        data: [],
        currentPage: 0,
        pages: 0,
        total: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case VENDOR_ACTIONS.VENDOR_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case VENDOR_ACTIONS.VENDOR_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case VENDOR_ACTIONS.VENDOR_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        case VENDOR_ACTIONS.VENDOR_INVOICE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case VENDOR_ACTIONS.VENDOR_INVOICE_SEARCH_SUCCESS: {
            return {
                ...state,
                invoiceSearch: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case VENDOR_ACTIONS.VENDOR_INVOICE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case VENDOR_ACTIONS.VENDOR_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case VENDOR_ACTIONS.VENDOR_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case VENDOR_ACTIONS.VENDOR_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case VENDOR_ACTIONS.VENDOR_RESET: {
            return {
                ...state,
                currentRecord: { ...VendorDefaultProps },
            };
        }

        default:
            return state;
    }
};
