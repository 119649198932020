import React, { useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { size } from 'lodash';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { vendorDeliveryInvoiceGetAPI } from 'src/api/VendorDeliveryApi';
import { convertToWords, formatDate } from 'src/utils/utils';

const TaxInvoiceDownload = ({ contentRef2 }) => {
    const { mainId } = useParams();
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const currency = "₹"
    // Function to handle the PDF generation and download
    const handleDownload = () => {
        const element = document.getElementById('invoiceContent'); // Element to be converted to PDF

        // Use html2canvas to capture the content as an image
        html2canvas(element, {
            scale: 5, // Increase the scale for better quality
            useCORS: true, // Enable CORS to handle external images (if any)
            logging: true, // Log the process to console for debugging
            backgroundColor: '#ffffff', // Make sure background is white (can adjust if you need)
        }).then((canvas) => {
            // Convert canvas to image data
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('l', 'mm', 'a4'); // A4 paper size in mm (portrait mode)

            // Adjust the image size to fit the PDF page
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            const scaleX = pdfWidth / canvasWidth;
            const scaleY = pdfHeight / canvasHeight;
            const scale = Math.min(scaleX, scaleY); // Scale to fit within the page

            const xOffset = (pdfWidth - canvasWidth * scale) / 2; // Center horizontally
            const yOffset = 0; // Set yOffset to 0 to start from the top

            // Add image to PDF with calculated position and scaling
            pdf.addImage(imgData, 'JPEG', xOffset, yOffset, canvasWidth * scale, canvasHeight * scale);

            // Save the PDF with a name
            pdf.save('invoice.pdf');
        });
    };
    const getInvoice = async (id) => {
        const invoice = await vendorDeliveryInvoiceGetAPI({ _id: id });
        setInvoices(invoice?.data?.data || [])
        setLoading(false)
    }
    useEffect(() => {
        if (mainId) {
            getInvoice(mainId)
        }
    }, [mainId])
    if (loading) {
        return <div>Loading...</div>; // Render loading indicator
    }
    return (
        <>
        {/* Button to trigger the PDF download */}
        <button onClick={handleDownload}>Download PDF</button>
        <div>
            <div ref={contentRef2}
                id="invoiceContent"
                style={{ width: "100%", maxWidth: 1080, margin: "auto", color: "#333", fontFamily: "Inter, sans-serif", backgroundColor: "#fff", padding: 20, borderRadius: 8, lineHeight: "1.5", fontSize: 14 }} >
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n        body,p,h3,h2,h1,h4{\n  font-family: Inter, sans-serif;\n  font-optical-sizing: auto; \n  font-style: normal;\n}\n"
                    }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 0, borderBottom: "1px solid #ccc" }}>
                    <span style={{ fontSize: "40px", color: "#fff", backgroundColor: "#007bff", padding: "0px 30px 15px 30px", borderRadius: "8px 8px 0px 0", margin: 0, height: "66px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }}>{currency === "₹" ? 'Tax' : ''} Invoice</span>
                    <span><img src="/assets/images/acadecraft-logo.svg" alt="" /></span>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", marginBottom: 0, borderBottom: "10px solid #0073EA" }}>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 12, lineHeight: "1.6", color: "#0073EA" }}>From</p>
                        <p style={{ margin: 0, fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>Acadecraft Private Limited</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", marginTop: 10 }}>GSTIN: {invoices?.vendorId.gstNo}</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex" }}>Add: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>H-54, Sector 63, Gautam Buddha Nagar, Noida, UP-201301</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>City: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Noida</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Uttar Pradesh</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State Code: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>09</span></p>
                    </div>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 12, lineHeight: "1.6", color: "#0073EA" }}>To</p>
                        <p style={{ margin: "2px 0", fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>{`${invoices?.vendorId.firstName} ${invoices?.vendorId.lastName}`}.</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", fontWeight: "bold" }}>GSTIN: {invoices?.vendorId.gstNo}</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex" }}>Add: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId.address}</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>City: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId.city}</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId.state}</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State Code: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{invoices?.vendorId.state_code}</span></p>
                    </div>
                    <div style={{ flex: 1, padding: 10, textAlign: "right" }}>
                        <div style={{ display: "inline-block", textAlign: "left" }}>
                            <p style={{ margin: "2px 0", fontSize: 15, lineHeight: "1.4", fontWeight: "bold", display: "flex" }}><span style={{ display: "inline-block", fontWeight: "normal", paddingRight: "5px" }}>Invoice No:</span> {invoices?.invoiceNo}</p>
                            <p style={{ margin: "2px 0", fontSize: 10, lineHeight: "1.6", marginTop: 20, color: "#7B7E8B", display: "flex" }}>Invoice Date: <span style={{ fontWeight: "bold", color: "#323338", paddingLeft: "5px" }}>{formatDate(invoices?.date)}</span></p>
                        </div>
                    </div>
                </div>

                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 15, fontSize: 12, lineHeight: "1.5" }}>
                    <thead>
                        <tr style={{ backgroundColor: "#fff", color: "#7B7E8B", fontWeight: "normal", verticalAlign: "middle" }}>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Sr. No.</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Description of Goods/Services</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Unit of Measurement</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Unit Price</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Total Units</th>
                            {currency === "₹" && (
                                <>
                                    <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>HSN/SAC Code</th>
                                </>
                            )}
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Total Value</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", textAlign: "center" }} colSpan={2}>CGST</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", textAlign: "center" }} colSpan={2}>SGST/UTGST</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", textAlign: "center" }} colSpan={2}>IGST</th>
                        </tr>
                        <tr>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                        </tr>
                        {currency === "₹" && (
                            <tr>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 9, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                            </tr>
                        )}
                    </thead>

                    <tbody>
                        {
                            invoices?.invoiceDetails?.map((item, index) => (
                                <tr>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{(index + 1)}</td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.deliveryId.productionSlug?.split('>')?.slice(-2)?.join('>')}</td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.deliveryId.uom}</td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.perUnitCost}</td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item.paidUnit}</td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>172560</td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle", textAlign: "right" }}>{(item.amount-(item.taxAmount||0))}</td>
                                    {
                                        invoices.taxType === "CGST" ? (
                                            <>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>9%</span></td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{(item.amount * 0.09).toFixed(2)}</td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>9%</span></td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{(item.amount * 0.09).toFixed(2)}</td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>18%</span></td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>0.00</td>
                                            </>

                                        ) : (
                                            <>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>9%</span></td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>0.00</td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>9%</span></td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>0.00</td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>18%</span></td>
                                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{(item.amount * 0.18).toFixed(2)}</td>
                                            </>

                                        )
                                    }

                                </tr>
                            ))
                        }




                        <tr>
                            <td colSpan={6} style={{ border: "1px solid #ddd", padding: 8, textAlign: "end", lineHeight: "1.6", fontSize: 12, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                Total
                            </td>
                            <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle", textAlign: "right" }}>
                                &#8377;{invoices.totalAmount}
                            </td>
                            {
                                invoices.taxType === "CGST" ? (
                                    <>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                            {(invoices.taxAmount/2)?.toFixed(2)}
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                          {(invoices.taxAmount/2)?.toFixed(2)}
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                            0.00
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                            0.00
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                            0.00
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                          {(invoices.taxAmount)?.toFixed(2)}
                                        </td>
                                    </>
                                )
                            }
                        </tr>
                        <tr>
                            <td colSpan={currency === "₹" ? 7 : 2} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", verticalAlign: "middle" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Rounding off
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                &#8377;{invoices.totalAmount}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in Fig)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 14, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                INR {invoices.totalAmount.toFixed().toLocaleString()}/-
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in words)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                {convertToWords(invoices.totalAmount.toFixed())}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                {currency === "₹" ? 'Amount of tax subject to reverse charges' : ''}
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan={currency === "₹" ? 6 : 4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                        </tr>

                        <tr>
                            <td colSpan={currency === "₹" ? 7 : 2} style={{ border: "1px solid #ddd", verticalAlign: "middle" }}>
                                <table style={{ width: "100%", borderCollapse: "collapse", verticalAlign: "middle" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", borderTop: 0, borderLeft: 0, padding: "0 8px 7px 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Holder Name</td>
                                            <td style={{ border: "1px solid #ddd", borderTop: 0, padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>Acadecraft Private Limited</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Name of Bank</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICI BANK LTD</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account No</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>071901527809</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Type</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>SAVING</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>IFSC Code</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICIC0000719</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Swift Code</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICINBBCTS</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Bank Address</td>
                                            <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>Sector 18 Noida</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan={currency === "₹" ? 6 : 4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 9, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                <p style={{ lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold" }}>For Acadecraft Private Limited</p>
                                <p><img src="/assets/images/authirisedsignatory1.svg" /></p>
                                <p style={{ lineHeight: "1.6", fontSize: 10, marginBottom: "0", color: "#323338", fontWeight: "normal", verticalAlign: "middle" }}>Authorised Signatory</p>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <div style={{ marginTop: 10, fontSize: 12, lineHeight: "1.6" }}>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: "normal", display: "flex" }}>
                        <span style={{ fontWeight: "bold" }}>Note:</span> This invoice was system
                        generated, signature are not required
                    </p>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: "normal", display: "flex" }} >
                        <span style={{ fontWeight: "bold" }}>Declaration:</span> We declare that
                        this invoice shows the actual price of the goods described and that all
                        particulars are true and correct.
                    </p>
                </div>
            </div>

        </div>
        </>
  )
}

export default TaxInvoiceDownload;
