import React, { useState } from "react";

const ProductionReviewActionModal = () => {
  const [selectedOption, setSelectedOption] = useState("yes"); // Default value

  const handleChange = (event) => {
    setSelectedOption(event.target.value); // Update state on change
  };

  const handleModalClose = () => {
    setSelectedOption("yes"); // Reset state when modal closes
  };
    


  return (
    <div>
      {/* Modal */}
      <div className="modal fade cusmodl" id="ActionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
           {/* <div className="modal-header justify-content-between">
             
               <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >✗</button>
            </div> */}
            <div className="modal-body"> 
            <h6 className="modal-title text-xl text-center" id="exampleModalLabel">
                Consider for Deduction
              </h6>
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-28 py-24">
                  {/* Yes Radio Button */}
                  <div className="form-check checked-primary d-flex align-items-center gap-2">
                    <input
                      className="form-check-input"
                      type="radio" data-bs-dismiss="modal"
                      name="deductionOption" // Unique group name
                      id="horizontal1"
                      value="yes"
                     // Dynamically check
                      onChange={handleChange} // Update state on change
                    />
                    <label
                      className="form-check-label line-height-1 fw-medium text-secondary-light"
                      htmlFor="horizontal1"
                    >
                      Yes
                    </label>
                  </div>

                  {/* No Radio Button */}
                  <div className="form-check checked-secondary d-flex align-items-center gap-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deductionOption" // Unique group name
                      id="horizontal2" data-bs-dismiss="modal"
                      value="no"
                      // Dynamically check
                      onChange={handleChange} // Update state on change
                    />
                    <label
                      className="form-check-label line-height-1 fw-medium text-secondary-light"
                      htmlFor="horizontal2"
                    >
                      No
                    </label>
                  </div>
                </div>
                
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionReviewActionModal;
 