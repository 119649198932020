import React from 'react';

const GstStateList = () => {
    const states = [
        { "value": "35", "label": "Andaman & Nicobar Islands" },
        { "value": "28", "label": "Andhra Pradesh (Old)" },
        { "value": "37", "label": "Andhra Pradesh" },
        { "value": "12", "label": "Arunachal Pradesh" },
        { "value": "10", "label": "Bihar" },
        { "value": "04", "label": "Chandigarh" },
        { "value": "22", "label": "Chhattisgarh" },
        { "value": "25", "label": "Daman & Diu" },
        { "value": "26", "label": "Dadra & Nagar Haveli" },
        { "value": "07", "label": "Delhi" },
        { "value": "30", "label": "Goa" },
        { "value": "24", "label": "Gujarat" },
        { "value": "06", "label": "Haryana" },
        { "value": "02", "label": "Himachal Pradesh" },
        { "value": "20", "label": "Jharkhand" },
        { "value": "29", "label": "Karnataka" },
        { "value": "32", "label": "Kerala" },
        { "value": "31", "label": "Lakshadweep" },
        { "value": "38", "label": "Ladakh" },
        { "value": "14", "label": "Manipur" },
        { "value": "23", "label": "Madhya Pradesh" },
        { "value": "27", "label": "Maharashtra" },
        { "value": "17", "label": "Meghalaya" },
        { "value": "15", "label": "Mizoram" },
        { "value": "13", "label": "Nagaland" },
        { "value": "21", "label": "Orissa" },
        { "value": "34", "label": "Puducherry" },
        { "value": "03", "label": "Punjab" },
        { "value": "08", "label": "Rajasthan" },
        { "value": "11", "label": "Sikkim" },
        { "value": "33", "label": "Tamil Nadu" },
        { "value": "36", "label": "Telangana" },
        { "value": "16", "label": "Tripura" },
        { "value": "05", "label": "Uttarakhand" },
        { "value": "09", "label": "Uttar Pradesh" },
        { "value": "19", "label": "West Bengal" }
      ]      

    return states;
};

export default GstStateList;
