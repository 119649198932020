export const VENDOR_DELIVERY_ACTIONS = {
    VENDOR_DELIVERY_SEARCH: 'VENDOR_DELIVERY_SEARCH',
    VENDOR_DELIVERY_SEARCH_SUCCESS: 'VENDOR_DELIVERY_SEARCH_SUCCESS',
    VENDOR_DELIVERY_SEARCH_ERROR: 'VENDOR_DELIVERY_SEARCH_ERROR',

    VENDOR_DELIVERY_CURRENT_VENDOR_DELIVERY: 'VENDOR_DELIVERY_CURRENT_VENDOR_DELIVERY',
    
    VENDOR_DELIVERY_ASSIGNED_PROJECTS_SEARCH: 'VENDOR_DELIVERY_ASSIGNED_PROJECTS_SEARCH',
    VENDOR_DELIVERY_TASK_BY_PROJECT_SEARCH: ' VENDOR_DELIVERY_TASK_BY_PROJECT_SEARCH',
    VENDOR_DELIVERY_MODULE_BY_TASK_SEARCH: ' VENDOR_DELIVERY_MODULE_BY_TASK_SEARCH',

    VENDOR_DELIVERY_ASSIGN_CLIENT: 'VENDOR_DELIVERY_ASSIGN_CLIENT',
    VENDOR_DELIVERY_ASSIGN_PROJECT: 'VENDOR_DELIVERY_ASSIGN_PROJECT',
    VENDOR_DELIVERY_ASSIGN_CATEGORY: 'VENDOR_DELIVERY_ASSIGN_CATEGORY',
    VENDOR_DELIVERY_ASSIGN_SUB_CATEGORY: 'VENDOR_DELIVERY_ASSIGN_SUB_CATEGORY',

    VENDOR_DELIVERY_UPSERT: 'VENDOR_DELIVERY_UPSERT',
    VENDOR_DELIVERY_UPSERT_SUCCESS: 'VENDOR_DELIVERY_UPSERT_SUCCESS',
    VENDOR_DELIVERY_UPSERT_ERROR: 'VENDOR_DELIVERY_UPSERT_ERROR',

    VENDOR_DELIVERY_EDIT: 'VENDOR_DELIVERY_EDIT',

    VENDOR_DELIVERY_RESET: 'VENDOR_DELIVERY_RESET',
};

export const vendorDeliveryResetAction = () => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_RESET,
});

export const vendorDeliverySearchAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_SEARCH,
    ...payload,
});

export const vendorDeliveryEditAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_EDIT,
    ...payload,
});

export const vendorDeliveryUpsertAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_UPSERT,
    ...payload,
});
export const vendorDeliveryProjectAssigneeSearchAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_ASSIGNED_PROJECTS_SEARCH,
    ...payload,
});
export const vendorDeliveryTaskByProjectSearchAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_TASK_BY_PROJECT_SEARCH,
    ...payload,
});
export const vendorDeliveryModuleByTaskSearchAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_MODULE_BY_TASK_SEARCH,
    ...payload,
});



export const vendorDeliveryAssignProjectIdAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_ASSIGN_PROJECT,
    ...payload,
});
export const vendorDeliveryAssignCategoryAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_ASSIGN_CATEGORY,
    ...payload,
});
export const vendorDeliveryAssignSubCategoryAction = (payload) => ({
    type: VENDOR_DELIVERY_ACTIONS.VENDOR_DELIVERY_ASSIGN_SUB_CATEGORY,
    ...payload,
});
export default {
    VENDOR_DELIVERY_ACTIONS,
    vendorDeliverySearchAction,
    vendorDeliveryUpsertAction,
    vendorDeliveryEditAction,
};
