import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { getFormattedMonthYear, sumWorkingTimes } from 'src/utils/utils';

 

const HubstaffTime = ({hubstaffTime,initialValues}) => { 
  const { hubstaff } = useSelector((state) => state.SalaryReducer?.search?.data, shallowEqual);
  const { totalWorkingTime, totalAllowedWorkingTime,totalIdleTime  } = sumWorkingTimes(hubstaff, 0); 
     // Initialize tooltips when data or columns change
     useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        return () => {
            tooltipList.forEach(tooltip => tooltip.dispose());
        };
    }, []); // Reinitialize tooltips when either data or columns change


  return (
    <>   
      <div
  className="modal fade cusmodl HubstaffTimeModal"
  id="HubstaffTimeModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  style={{ display: "none" }}
  aria-hidden="true"
>
  <div className="modal-dialog modal-xl modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header d-flex justify-content-between"> 
        <h6 className="modal-title" id="exampleModalLabel">
        Hubstaff Time
        </h6> 
        <img src='/assets/images/hubstaff1.svg' className='img-fluid' />
      </div>
      <div className="modal-body pt-0">
        <div className="d-flex align-items-center justify-content-between py-16">
        <div className="">{totalWorkingTime} 
          {
              hubstaffTime.additional>0?<span className="text-green">+ {hubstaffTime.additional}</span>:<span className="text-red">{hubstaffTime.additional}</span>
          }
        </div>
        <div className="">{getFormattedMonthYear(initialValues?.salaryMonth, initialValues?.salaryYear)}</div> 
        </div>

        <div className="d-flex border align-items-center mt-16">
        <div class="badge text-md fw-semibold px-16 py-16 rounded-0 d-flex align-items-center gap-2 text-neutral-400 border-end w-100 justify-content-center">Exact Hours <span class="badge text-md fw-semibold  h-32-px d-flex justify-content-center align-items-center radius-10 bg-primary-700  text-white">{initialValues?.lagHourDetails?.exactHours}</span></div>
        <div class="badge text-md fw-semibold px-16 py-9 rounded-0 d-flex align-items-center gap-2 text-neutral-400 border-end w-100 justify-content-center"> 
        <div className="text-start">
        <span className="text-green d-block mb-10">{totalWorkingTime}</span>
        Total Time
        </div>
        </div>
        <div class="badge text-md fw-semibold px-16 py-9 rounded-0 d-flex align-items-center gap-2 text-neutral-400 border-end w-100 justify-content-center"> 
        <div className="text-start">
        <span className="text-red d-block mb-10">{totalIdleTime} </span>
        Idle Hours
        </div>
        </div>
        </div>
       
       
        <table className="table basic-border-table mb-0 table-hover" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={headerCellStyle}>S.No.</th>
            <th style={headerCellStyle}>Start Date</th>
            <th className="text-center" style={headerCellStyle}>Time</th>
            <th className="text-center" style={headerCellStyle}>Activity</th>
            {/* <th className="text-center" style={headerCellStyle}>Exact Hours</th> */}
            <th className="text-end" style={headerCellStyle}>Idle Hours</th>
          </tr>
        </thead>
        <tbody>
          {hubstaff?.map((row, index) => (
            <tr key={row.id} style={row.dayType === 'Half Day' ? { backgroundColor: '#e8f4ff' } : {}}>
              <td style={cellStyle}>{index + 1}</td>
              <td style={cellStyle}>
                {row.Date}{' '}
                {row.dayType && (
                  <span
                    style={{
                      ...dayTypeLabelStyle,
                      backgroundColor: row.dayType === 'Half Day' ? '#ff5f5f' : '#5fafff',
                    }}
                  >
                    {row.dayType}
                  </span>
                )}
              </td>
              <td className="text-center" style={cellStyle}>{row.Time}</td>
              <td className="text-center" style={{ ...cellStyle, color: 'green' }}>{row.Activity}</td>
              {/* <td className="text-center" style={cellStyle}>{row.exactHours}</td> */}
              <td className="text-end" style={cellStyle}>{row.idle}</td>
            </tr>
          ))}
        </tbody>
      </table>
         
      </div>
    </div>
  </div>
</div>

    </>
  )
}

const headerCellStyle = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    fontWeight: 'bold',
    textAlign: 'left',
  };
  
  const cellStyle = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  };
  
  const dayTypeLabelStyle = {
    color: 'white',
    padding: '2px 6px',
    borderRadius: '12px',
    fontSize: '12px',
    marginLeft: '8px',
  };
  
  
export default HubstaffTime