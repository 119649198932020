import React, { useState } from "react";

const MeasurementDropdown = () => {
  const [selectedOption, setSelectedOption] = useState(null); 
  const [searchTerm, setSearchTerm] = useState(""); // New state for search input
  
  const options = [
    "Per Page",
    "Per Book",
    "Per PDF",
    "Per File",
    "Per QID",
    "Per Assessment",
    "Per Question",
    "Per Solution",
    "Per Word",
    "Per Character",
    "Per Minute",
    "Per Hour"
  ];

  const handleRadioChange = (option) => {
    setSelectedOption(option); 
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderButtonLabel = () => {
    return selectedOption ? selectedOption : "Select Measurement";
  };

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dropdown">
      <button
        className="btn border px-18 text-neutral-900 py-9 dropdown-toggle toggle-icon w-100 d-flex justify-content-between align-items-center w-200-px text-md"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {renderButtonLabel()}
      </button>
      <div className="dropdown-menu">
        <form className="navbar-search">
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange} // Update search term on input change
          />
          <span className="icon">
            <iconify-icon icon="ion:search-outline" className="icon" />
          </span>
        </form>
        <ul className="mx-hight200px px-12 py-12">
          {filteredOptions.map((option, index) => (
            <li key={index}>
              <label className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0 dropdown-item">
                {option}
                <input
                  type="radio"
                  name="measurement"
                  checked={selectedOption === option}
                  onChange={() => handleRadioChange(option)}
                />
                <span className="checkmark" />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MeasurementDropdown;
