import React from 'react'
import ProjectCardTimeStamp from './ProjectCardTimeStamp' 
import FeedbackLinksList from './FeedbackLinksList';
import AddTimestamp from './AddTimestamp';
import TopTabs from 'src/containers/project-container/View/TopTabs';

const TimeStamp = () => {

  const projectData = [
    {
        title: 'Project Milestone 1',
        date: '16 Oct 2024',
        badgeText: 'Alpha',
        badgeClass: 'bg-pink-600',
        description: 'Intrinsically incubate intuitive opportunities and real-time potentialities for change for interoperable meta-itself.',
    },
    {
        title: 'Project Milestone 2',
        date: '20 Oct 2024',
        badgeText: 'Gold',
        badgeClass: 'bg-warning-800',
        description: 'Leverage synergies for advancing scalable frameworks and innovating future-ready solutions.',
    },
    {
        title: 'Project Milestone 3',
        date: '20 Oct 2024',
        badgeText: 'Beta',
        badgeClass: 'bg-success-600',
        description: 'Leverage synergies for advancing scalable frameworks and innovating future-ready solutions.',
    },
    // Add more project objects as needed
];



const initialLinks = [
  { id: 1, url: 'www.dribbble.com/shots/1', date: '16 Oct 2024', updatedBy: 'Nidhi Sharma' },
  { id: 2, url: 'www.dribbble.com/shots/2', date: '16 Oct 2024', updatedBy: 'Nidhi Sharma' },
  { id: 3, url: 'www.dribbble.com/shots/3', date: '16 Oct 2024', updatedBy: 'Nidhi Sharma' },
];



  return (
    <>
    <div className="card">
    <div className="card-body">
    <div className="d-flex justify-content-between align-items-center border-bottom  pb-16">
                <div className="">
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width={30}
                        height={30}
                      >
                        <g data-name="20-Arrow Left">
                          <path
                            fill="#0073ea"
                            d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                          />
                          <path
                            fill="#0073ea"
                            d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                          />
                        </g>
                      </svg>
                    </button>{" "}
                    Time Stamp
                  </h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a
                        href="/"
                        className="d-flex align-items-center gap-1 hover-text-primary"
                      >
                        <iconify-icon
                          icon="solar:home-smile-angle-outline"
                          className="icon text-lg"
                        ></iconify-icon>
                        Home
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">Time Stamp</li>
                  </ul>
                </div> 
              </div>
     <div className=" border-bottom mb-16">
     <TopTabs />
     </div>
      <button className="btn btn-info-600 radius-8 px-20 py-9 mb-24" data-bs-toggle="modal" data-bs-target="#AddTimestampModal"><i className="mdi mdi-plus"></i> Add</button>
      <div className="TotlU border-start-0 border-end-0 rounded-0 mb-24">
                <ul>
                  <li className="border-0">16 Oct 2024 <span className="d-block">Handover Date <span className="text-sm">Sales to Operate</span> </span></li>
                  <li className="text-green border-0">16 Oct 2024 <span className="d-block">Kick Off Date</span></li> 
                  <li className="text-blue-500 border-0 text-dblue">16 Oct 2024<span className="d-block"> Expected Project Closer Date</span></li>
                  <li className="border-0"><i className="mdi mdi-check-circle text-green"></i> Yes  <span className="d-block">Approved for Continue</span></li> 
                </ul> 
              </div>


              <div className="col-xxl-12 col-sm-12"> 
                {projectData.map((project, index) => (
                <ProjectCardTimeStamp
                    key={index}
                    title={project.title}
                    date={project.date}
                    badgeText={project.badgeText}
                    badgeClass={project.badgeClass}
                    description={project.description}
                />
            ))}
            </div>
            <div className="col-md-12 mt-24"> 
            <FeedbackLinksList initialLinks={initialLinks} />
            </div>

    </div>
    </div>

    <AddTimestamp />
    </>
  )
}

export default TimeStamp
