import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form,Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { vendorDeliveryInvoiceCreateAPI, vendorInvoicePaymentCreateAPI } from "src/api/VendorDeliveryApi";
import FormikInput from "src/components/formik/FormikInput";
import { useRef } from 'react';
import { VendorPaymentYupSchema } from './VendorPropTypes';

const Render=(props)=>{
    const { 
        isSubmitting ,
        isValid, 
        dirty,
        setFieldValue,
        values
      } = props;
      const dateInputRef = useRef(null);  // Create a ref for the input  
      useEffect(() => {
        // Initialize flatpickr and disable time picker
        flatpickr(dateInputRef.current, {
          enableTime: false,  // Disable time picker
          dateFormat: "Y/m/d",  // Date format MM/DD/YYYY
          onChange: (selectedDates, dateStr) => {
            if (selectedDates.length > 0) {
              const selectedDate = selectedDates[0];
                const date = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
                setFieldValue('date', date);
          }
        }
        });
      }, []);
      return (
        <>
         <Form autoComplete="disabled"> 
                    <div className={`col-md-12 mb-20 vvan-none'}`}>
                      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Date</label>
                      <div className="position-relative">
                      <Field
                            className="form-control radius-8 bg-base"
                            id="date"
                            type="text"
                            name="date" // Link to Formik state using 'name'
                            placeholder="MM/DD/YYYY"
                            innerRef={dateInputRef}  // Attach ref to the input using innerRef
                            onChange={(e) => setFieldValue('date', e.target.value)}  // Manually update the value
                          />
                          <ErrorMessage
                            name="date" // Link ErrorMessage to the 'startDate' field
                            component="div"
                            className="text-danger"
                          />
                        <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                          <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
                        </span>
                      </div>
                    </div>
                     
                    <div className={`col-md-12 mb-20 d-flex justify-content-between align-items-start gap-2 vvan-none'}`}> 
                      <div>
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Number <span className="text-red">*</span></label> 
                        <FormikInput
                          type="number"
                          name="invoicePendingUnit"
                          className="form-control"
                          placeholder={0}
                        />
                      </div> 
                    </div> 
                    <div className={`col-md-12 mb-20 d-flex justify-content-between align-items-start gap-2 vvan-none'}`}> 
                      <div>
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Client Invoice No <span className="text-red">*</span></label> 
                        <FormikInput
                          type="text"
                          name="clientInvNo"
                          className="form-control"
                          placeholder={0}
                        />
                      </div> 
                    </div> 
                    <div className='py-16 psstyky bg-white z-9'>
                        <button  type="submit" 
                        className="btn btn-primary w-100 mt-16" 
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        disabled={!isValid || !dirty || isSubmitting}
                        >
                          Submit{" "}
                        </button>
                  </div>
                </Form>
        </>
      );
}


 
// const handleGenerateSingleInvoice=async(row)=>{
//     try {        
//       const payload = {
//         projectId: row.projectId._id,
//         vendorId: row.vendorId._id,
//         totalUnits: row.totalDurations,
//         totalAmount: row.totalCost,
//         invoiceDetails: [{
//           deliveryId: row._id, 
//           perUnitCost: row.perUnitCost,
//           totalUnit: row.totalDurations,
//           paidUnit: row.totalDurations
//         }]
//       }
//     const result = await vendorDeliveryInvoiceCreateAPI(payload)
//     toast.success('Vendor invoice submitted successfully!'); 
//   } catch (error) {
//     toast.error('Failed to submit form! ' + error.message);
//   } finally {
    
//   }
//   }
const GeneratePaymentPopup = ({ invoicePayload,search }) => {
  const payloadWithModifiedDate = { ...invoicePayload, date: new Date().toISOString().split('T')[0] };
    const handleSubmit = useCallback(async (values, { setSubmitting, resetForm }) => {        
        try {
       const payload = {
        clientId: values.clientId._id,
        projectId: values.projectId._id,        
        vendorId: values.vendorId._id,
        amount: values.amount,        
        date: values.date,         
      } 
      const result = await vendorInvoicePaymentCreateAPI(payload)
      toast.success('Vendor invoice Payment submitted successfully!'); 
          resetForm(); // Reset the form after successful submission
          search();
        } catch (error) {
          toast.error('Failed to submit form! ' + error.message);
        } finally {
          setSubmitting(false);
           
        }
      }, [
        invoicePayload
      ]);
    return (
        <div
            className="modal fade cusmodl"
            id="GeneratePaymentPopup"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-sm modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                        <div className="modal-body pb-0">
                        <Formik
                            initialValues={payloadWithModifiedDate}
                            validationSchema={VendorPaymentYupSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {(formikProps) => (
                            <Render
                                {...formikProps} 
                            />
                            )}
                        </Formik>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default GeneratePaymentPopup;
