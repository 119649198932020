import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { vendorDeliveryProjectSearchAPI } from 'src/api/ReportsAPI';
import { ExportReactCSV } from 'src/components/ExportReactCSV';
import InvoiceTableModal from '../project-container/View/VendorDelivery/InvoiceTableModal';
import ViewInvoiceListModal from '../project-container/View/VendorDelivery/ViewInvoiceListModal';
import GenerateInvoicePopup from '../project-container/View/VendorDelivery/GenerateInvoicePopup';
import { vendorDeliverySearchAction } from '../project-container/View/VendorDelivery/actions/VendorDeliveryActions';
import { projectOAD_CMD_DetailsAction } from '../project-container/actions/ProjectActions';
import VendorAddClientDelivery from '../project-container/View/VendorDelivery/VendorAddClientDelivery';
import { vendorInvoiceSearchAction } from './actions/VendorActions';
import VendorInvoiceTable from './VendorInvoiceTable';


const VendorInvoiceList = () => {
    const { invoiceSearch, totalSummary } = useSelector((state) => state.VendorReducer, shallowEqual);
    const [searchQuery, setSearchQuery] = useState("");
    const [search, setSearch] = useState(false);
    const [deliveries, setDeliveries] = useState([]);
    const [totalcost, setTotalCost] = useState(0)
    const [totalSearch, setTotalSearch] = useState(0)
    const [where, setWhere] = useState({});
    const [selectedDelivery, setSelectedDelivery] = useState([]);
    const [invoicePayload, setInvoicePayload] = useState({})

    const handleSearchInputChange = (e) => {
        const query = e.target.value;
    };

    const handleDateRangeChange = (selectedDates) => {
    }
    const dispatch = useDispatch();
    useEffect(() => {
        // Timeout to ensure tooltips are initialized after rendering
        const timer = setTimeout(() => {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

            // Cleanup on unmount or if the tooltips need to be re-initialized
            return () => {
                tooltipList.forEach(tooltip => tooltip.dispose());
            };
        }, 100); // Delay initialization to ensure DOM is fully updated

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, []);

    useEffect(() => {
        handleSearch(where);
    }, [
        dispatch,
        where
    ]);
    const handleSearch = useCallback(() => {
        dispatch(vendorInvoiceSearchAction({
            where: where
        }));
    }, [dispatch, where])
    const ExportInExcel = useMemo(() => {
        if (invoiceSearch?.data?.length === 0) {
            return [];
        }
        const results = [];
        invoiceSearch?.data?.forEach((row, index) => {
            results.push({
                SrNo: index + 1,
                // UpdatedBy: `${row.userId.firstName} ${row.userId.lastName}`,
                // VendorName: `${row.vendorId.firstName} ${row.vendorId.lastName}`,
                // productionSlug: row.productionSlug,
                // moduleName: row.moduleId.moduleName,
                // totalDurations: row.totalDurations,
                // perUnitCost: row.perUnitCost,
                // totalCost: row.totalCost,
            });
        });
        return results;
    }, [
        invoiceSearch
    ]);

    return (
        <>
            {/* <VendorDeliverySavePopup projectSearch={project} projectId={projectId} handleChange={handleChange} /> */}
            <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card h-100">
                        <div className="card-body p-24">
                            <div className="border-bottom  pb-16">
                                <h2 className="fw-semibold mb-16 font-24">
                                </h2>
                                <ul className="d-flex align-items-center gap-2">
                                    <li className="fw-medium">
                                        <a
                                            href="#"
                                            className="d-flex align-items-center gap-1 hover-text-primary"
                                        >
                                            <iconify-icon
                                                icon="solar:home-smile-angle-outline"
                                                className="icon text-lg"
                                            ></iconify-icon>
                                            Special Access
                                        </a>
                                    </li>
                                    <li>-</li>
                                    <li className="fw-medium">Vendor Invoice </li>
                                </ul>
                            </div>
                            <div className="d-flex gap-2 align-items-center py-24 bg-white postionTop-0">

                                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                                <DatePickerComponent
                                    onDateRangeChange={handleDateRangeChange}
                                />
                                <div className="FilterBtn d-flex gap-2"><button type="button" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"></path></svg> Filter</button>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    {/* <button type="button" className="btn btn-primary-600 radius-8 px-14 py-9 text-sm"  data-bs-toggle="modal" data-bs-target="#combinedinvoiveModal">Generate Combined Invoice</button> */}
                                    {/* <button type="button" className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2">Export<img src="/assets/images/left-sidebar/xls-icon.svg" className="img-fluid" /></button> */}
                                    <ExportReactCSV csvData={ExportInExcel} fileName={"VendorInvoiceList-" + new Date().toLocaleDateString()} />
                                </div>
                            </div>

                            <div className="TotlU">
                                <ul>
                                    <li>
                                        {totalSummary?.totalPaidUnits + totalSummary?.totalUnPaidUnits}
                                        <span className="d-block">Total</span></li>
                                    <li className="text-green">{totalSummary?.totalPaidUnits} <span className="d-block">Total Paid Unit</span></li>
                                    <li>{totalSummary?.totalUnPaidUnits} <span className="d-block">Total Unpaid Unit</span></li>
                                    <li className="text-dblue">₹{totalSummary?.totalPaidAmount} <span className="d-block">Total Paid Amount</span></li>
                                    <li>{totalSummary?.FullyPaidCount} <span className="d-block">Fully Paid</span></li>
                                    <li>{totalSummary?.PartiallyPaidCount} <span className="d-block">Partially Paid</span></li>
                                </ul>

                            </div>

                            <VendorInvoiceTable
                                data={invoiceSearch.data || []}
                                total={invoiceSearch.total}
                                selectedDelivery={selectedDelivery}
                                setSelectedDelivery={setSelectedDelivery}
                                setInvoicePayload={setInvoicePayload}
                                status="Fully Paid"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <InvoiceTableModal selectedDelivery={selectedDelivery} />
            <ViewInvoiceListModal selectedDelivery={selectedDelivery} />

        </>
    )
}

export default VendorInvoiceList