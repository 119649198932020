import React, { useState } from 'react'
import ReactDOM from "react-dom";
import { Formik, Field, Form } from "formik";
const InvoiceActionModal = ({ handleActionSubmit, editData }) => {
    // console.log("editData", editData?.status);

    return (
        <>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#RollbackInvoice">
                Launch demo modal
            </button>
            <div
                className="modal fade pcdModal producSecMAin cusmodl"
                id="RollbackInvoice"
                tabIndex={-1}
                aria-labelledby="addClientDeliverySec"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-end"><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ message: "" }}
                                onSubmit={async (values, { resetForm }) => {
                                    // await new Promise((resolve) => setTimeout(resolve, 500));
                                    // alert(JSON.stringify(values, null, 2));
                                    handleActionSubmit(values)
                                    resetForm();
                                }}
                            >
                                <Form>
                                    <h5 className="card-title text-black text-center mb-24">Are you sure to {editData?.status === 'Cancelled' ? "Cancel" : "Rollback"} invoice?</h5>
                                    <div>
                                        <label htmlFor="name" className="text-md fw-bold mb-8">Reason
                                            {/* <span className='text-red'>*</span> */}
                                        </label>
                                        <Field
                                            id="message"
                                            name="message"
                                            as="textarea"
                                            rows="4"
                                            className="form-control"
                                            placeholder="Write your message here..."
                                        />
                                    </div>

                                    <div className="d-flex justify-content-between align-items-center gap-4 mt-16">
                                        <button type="submit" data-bs-dismiss="modal" aria-label="Close" className="btn btn-info-600 radius-8 px-20 py-11 w-100">Yes</button>
                                        <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-outline-neutral-900 radius-8 px-20 py-11 w-100">No</button>
                                    </div>
                                </Form>
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceActionModal
