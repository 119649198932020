import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { withFormikDevtools } from 'formik-devtools-extension';
import config from "src/config";
import { postRequest, putRequest } from 'src/utils/RequestUtil';
import Loading from '../main-container/Loading';
import FileUpload from 'src/components/FileUpload';
import { ClientDefaultProps, ClientYupSchema, defaultClientFilterProps } from './ClientPropTypes';
import { isProduction } from 'src/config/index';
import PropTypes from 'prop-types';
import FormikInput from 'src/components/formik/FormikInput';
import FormikTextarea from 'src/components/formik/FormikTextarea';
import FormikMySelect from 'src/components/formik/FormikMySelect';
import AssignTask from './AssignTask';
import ManageColum from './ManageColum';
import FilterPopup from './FilterPopup';
import ClientList from './ClientList';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clientSearchAction } from './actions/ClientActions';
import ClientStatusCounter from './ClientStatusCounter';
import SearchInput from 'src/components/SearchInput';
import NewSubProjectAlter from './NewSubProjectAlter';
import DatePickerComponent from 'src/components/DatePickerComponent';
import { clientSearchAPI } from 'src/api/ClientApi'; 
import FormikRadioBox from 'src/components/formik/FormikRadioBox';
import CountrySelector from 'src/components/CountrySelector';
import Pagination from 'src/components/Pagination';
import ProjectPermissionPopup from '../project-container/ProjectPermissionPopup';
import { getProjectPermissionsByUserAPI } from 'src/api/ProjectApi';

const Render = (props) => {

  if (!isProduction()) {
    withFormikDevtools(props);
  }

  const {
    isSubmitting,
    setCountry,
    files,
    setFiles,
    isMSME,
    setIsMSME,
    isOtherService,
    setIsOtherService,
  } = props;

  const serviceOptions = [
    { value: 'contentDevelopment', label: 'Content Development' },
    { value: 'eLearningServices', label: 'eLearning Services' },
    { value: 'accessibility', label: 'Accessibility' },
    { value: 'editorialPrePress', label: 'Editorial & Pre Press' },
    { value: 'videoSolutions', label: 'Video Solutions' },
    { value: 'other', label: 'other'},
  ];
  return (
    <Form autoComplete="disabled">
      <div className="assignPopoHeight">
        <div className="selectSec pt-0">
          <label htmlFor="ClientRegisteredName">Registered Name *</label>
          <FormikInput
            type="text"
            name="ClientRegisteredName"
            className="form-control"
            placeholder="Registered Name"
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">Commercial Name *</label>
          <FormikInput
            type="text"
            name="ClientCommercialName"
            className="form-control"
            placeholder="Enter Commercial Name"
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">GST</label>
          <FormikInput
            type="text"
            name="ClientGST"
            className="form-control"
            placeholder="eg, 22AAAAA0000A1Z5"
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">Country *</label>
          <CountrySelector
            name="ClientAddress"
            className="form-control"
            rows={4}
            cols={50}
            placeholder="Select Country"
            setCountry={setCountry}
            defaultValue={""}
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">Address *</label>
          <FormikTextarea
            name="ClientAddress"
            className="form-control"
            rows={4}
            cols={50}
            placeholder="Enter Address"
            defaultValue={""}
          />
        </div>
        <div className="col-12">
              <label class="form-label">Is MSME Registered</label>
              <div class="d-flex align-items-center flex-wrap gap-28">
                  <div class="form-check checked-primary d-flex align-items-center gap-2">
                      <FormikRadioBox
                      value={true} 
                      checked={isMSME}
                      name="isMSME" 
                      label="Yes"
                      className="form-check-input"
                      onClick={() => setIsMSME(true)}
                      />
                  </div>
                      <div class="form-check checked-secondary d-flex align-items-center gap-2">
                      <FormikRadioBox
                      value={false}
                      checked={(!isMSME)}
                      name="isMSME" 
                      label="No"
                      className="form-check-input"
                      onClick={() => setIsMSME(false)}
                      />
                      </div>
              </div>                         
      </div>
          {
            isMSME && (
              <div className="selectSec pt-0">
                <label htmlFor="">MSME Registration No</label>
                <FormikInput
                  type="text"
                  name="ClientMSMERegistrationNo"
                  className="form-control"
                  placeholder="eg, 22AAAAA0000A1Z5"
                />
              </div>
            )
          }
        <div className="selectSec pt-0">
          <label htmlFor="">Website</label>
          <FormikInput
            type="text"
            name="ClientWebsite"
            className="form-control"
            placeholder="www.website.com"
          />
        </div>
        <div className="selectSec droupPosiSet">
          <label htmlFor="" className="">
            Service
          </label>
          <FormikMySelect
            name="ClientService"
            options={serviceOptions}
            placeholder="Select Services"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOption) => {
              const isOtherService = selectedOption.some(option => option.value === "other");
              setIsOtherService(isOtherService);
            }}
          />
        </div>
        {
          isOtherService && (
            <div className="selectSec droupPosiSet">
              <label htmlFor="" className="">
                Other
              </label>
              <FormikInput
                type="text"
                name="otherService"
                className="form-control"
                placeholder="Please Enter the other service"
              />
            </div>
          )
        }
        <div className="selectSec pt-0">
          <label htmlFor="">Phone No.</label>
          <FormikInput
            type="text"
            name="ClientPhoneNo"
            className="form-control"
            placeholder="Enter Phone No"
          />
        </div> 
        <FileUpload setFiles={setFiles} files={files} />
      </div>
      <div className="btnBsticky">
        <button
          type="submit"
          className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"  
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>
    </Form>
  );
};

Render.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

let setSubmitting = () => { };

const ClientContainer = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupCounter, setGroupCounter] = useState({clientCount:0,projectCount:0,ProfitAndLoss:0});
  const [projectStatus, setProjectStatus] = useState([]);
  const [profitAndLoss,setProfitAndLoss] = useState([])
  const [OAD,setOAD] = useState([])
  const [CMD,setCMD] = useState([])
  const [where,setWhere] = useState({ProjectStatus:"Active"});
  const [isFlatPikerReset, setIsFlatPikerReset] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [country,setCountry] = useState('India');
  const [files,setFiles]=useState([]);
  const [isMSME,setIsMSME] = useState(false);
  const [isOtherService,setIsOtherService] = useState(false);
  const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual);
  const { currentSelectedProjectId} = useSelector(state => state.ClientReducer, shallowEqual);

  const [selectedUser, setSelectedUser] = useState({});
  const [userSelectedProjectPermission, setUserSelectedProjectPermission] = useState([]);


  useEffect(() => {
    setIsLoading(true);
    let isMounted = true
    dispatch(clientSearchAction())
    getClientList()
    getUserList()

    return () => {
      isMounted = false;
    }
  }, [where,searchTerm,pageNo]);
  const getClientList = async () => {
    let body = {
      pageLength: 100,
      currentPage: pageNo,
      where ,
      searchTerm
    };

    // const response = await postRequest({
    //   url: `${config.baseUrl}client/get`
    // })
    const response = await clientSearchAPI(body);
    const projectIds = response.data.data1.map(project => project._id);
    const profitAndLossResponse = await postRequest({
      url: `${config.baseUrl}client/getProfitAndLoss`,
      data:{projectIds}
    })
    setProfitAndLoss(profitAndLossResponse.data.data)
    setClients(response.data.data);
    setProjects(response.data.data1);
    setGroupCounter({
      ...groupCounter,
      clientCount:response.data.clientCount,
      projectCount:response.data.projectCount,
      ProfitAndLoss:response.data.ProfitAndLoss
    })
    setTotalPages(response.data.projectCount);
    setProjectStatus([...response.data.projectStatusCount])
     setOAD(response.data.OADList)
     setCMD(response.data.CMDList)
    setIsLoading(false);
  }

  const [users, setUsers] = useState('');
  const getUserList = async (where = {}) => {
    const response = await postRequest({
      url: `${config.baseUrl}user/get`,
      data: { where }
    })
    setUsers(response.data.data);
  }


  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    try {
      values.country = country;
      values.isMSME = isMSME;
      values.isOtherService = isOtherService;
      values.files=files;
      values.RefCreatedBy= userId;
      values.ClientService=(isOtherService)?values.otherService:values.ClientService?.map(item => item.value)

      const url = `${config.baseUrl}client/save`;
      const result = await postRequest({
        url,
        data: values,
      });
      await getClientList();
      dispatch(clientSearchAction())
      toast.success('Client submitted successfully!');
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      toast.error('Failed to submit form.');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const filteredData = projects.filter(item =>
    item.clients.ClientProjectCode.toLowerCase().includes(searchTerm) ||
    item.clients.ClientPhoneNo.toLowerCase().includes(searchTerm) ||
    item.clients.ClientRegisteredName.toLowerCase().includes(searchTerm) ||
    item.clients.ClientCommercialName.toLowerCase().includes(searchTerm) ||
    item.clients.ClientWebsite.toLowerCase().includes(searchTerm)
  );

  const [edit, setEdit] = useState({ clientId: null, projectId: null, ProjectExpected: '' });
  const [edit3, setEdit3] = useState({ clientId: null, projectId: null, RatePerFile: '' })

  const handleEditClick = (clientId, projectId, value) => {
    setEdit({ clientId, projectId, ProjectExpected: value });
  };

  const handleEditClick1 = (clientId, projectId, value) => {
    setEdit3({ clientId, projectId, RatePerFile: value });
  };

  const handleInputChange = (e) => {
    setEdit(prev => ({ ...prev, ProjectExpected: e.target.value }));
  };

  //// for RatePerFile //////
  const handleInputChange1 = (e) => {
    setEdit3(prev => ({ ...prev, RatePerFile: e.target.value }));
  };

  const handleSaveClick = async () => {

    setIsLoading(true);
    try {
      const url = `${config.baseUrl}project/update/${edit.projectId}`;
      const result = await putRequest({
        url,
        data: edit,
      });
      toast.success('Update successfully!');
    } catch (error) {
      toast.error('Failed to save.');
    } finally {
      setIsLoading(false);
    }

    // setClients(clients.map(client =>
    //   client._id === edit.clientId ? {
    //     ...client,
    //     projects: client.projects.map(project =>
    //       project._id === edit.projectId ? { ...project, ProjectExpected: Number(edit.ProjectExpected) } : project
    //     )
    //   } : client
    // ));
    await getClientList();
    handleCancelClick()
  };

  ///// for RatePerFile /////

  const handleSaveClick1 = async () => {

    setIsLoading(true);
    try {
      const url = `${config.baseUrl}project/update/${edit3.projectId}`;
      const result = await putRequest({
        url,
        data: edit3,
      });
      toast.success('Update successfully!');
    } catch (error) {
      toast.error('Failed to save.');
    } finally {
      setIsLoading(false);
    }

    setClients(clients.map(client =>
      client._id === edit3.clientId ? {
        ...client,
        projects: client.projects.map(project =>
          project._id === edit3.projectId ? { ...project, RatePerFile: Number(edit3.RatePerFile) } : project
        )
      } : client
    ));
    handleCancelClick1()
  };


  const handleCancelClick = () => {
    setEdit({ clientId: null, projectId: null, ProjectExpected: '' });
  };

  const handleCancelClick1 = () => {
    setEdit3({ clientId: null, projectId: null, RatePerFile: '' });
  };
  const [edit2, setEdit2] = useState({ clientId: null, projectId: null, ProjectStatus: '' });

  const handleEditStatusClick = (clientId, projectId, value) => {
    setEdit2({ clientId, projectId, ProjectStatus: value });
  };
  const handleInputStatusChange = async (ProjectStatus) => {
    setEdit2(prev => ({ ...prev, ProjectStatus: ProjectStatus }));
    setIsLoading(true);
    try {
      const url = `${config.baseUrl}project/update/${edit2.projectId}`;
      const result = await putRequest({
        url,
        data: { "ProjectStatus": ProjectStatus },
      });
      toast.success('Update successfully!');
      await getClientList();
    } catch (error) {
      toast.error('Failed to save.');
    } finally {
      setIsLoading(false);
    }
    setClients(clients.map(client =>
      client._id === edit2.clientId ? {
        ...client,
        projects: client.projects.map(project =>
          project._id === edit2.projectId ? { ...project, ProjectStatus: ProjectStatus } : project
        )
      } : client
    ));
    // e.target.closest('ul').classList.remove('show');
    setEdit2({ clientId: null, projectId: null, ProjectStatus: '' })
  };

  const handleNewProject = async () => {
    setIsLoading(true);
    try {
      const url = `${config.baseUrl}client/add-project`;
      const result = await postRequest({
        url,
        data: { clientId: clientId },
      });
      await getClientList();
      dispatch(clientSearchAction())
      toast.success('Project submitted successfully!');
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form.');
    } finally {
      setIsLoading(false);
    }
  }

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) { 
      setIsFlatPikerReset(false)
      const [startDate, endDate] = selectedDates.map(date => {
        // Ensure that the date is treated as UTC and not auto-adjusted
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); 
        return utcDate.toISOString().split("T")[0]; // Get date part only (YYYY-MM-DD)
      });  
      setWhere({...where, ts: { $gte: startDate, $lte: endDate } })
    }
  }
 
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
    setSearchTerm(searchKey.toLowerCase());     
  };

  const resetFilter = () => {
    document.getElementById('search').value = '';
    setIsFlatPikerReset(true)
    setSearchTerm('');
    setWhere({});
    // setFilterDefaultProps(defaultClientFilterProps);
  }

  const handleLoadPermissions = useCallback(async(user) => { 
    setSelectedUser(user);
    const response = await getProjectPermissionsByUserAPI(user._id,currentSelectedProjectId);
    if (response && response.data) {
      const permissions = response.data.data[0]?.permissions || [];
      setUserSelectedProjectPermission(permissions);
    }
  },[currentSelectedProjectId])


  // const [columnsVisibility, setColumnsVisibility] = useState({
  //   SNo: true,
  //   createdDate: true,
  //   projectCode: true,
  //   sub: true,
  //   changeStatus: true,
  //   profitLoss: true,
  //   OAD: true,
  //   CMD: true,
  //   NetCost: true,
  //   NetDelivered: true,
  //   DS: true,
  //   expected: true,
  //   registeredName: false,
  //   assignTo: false,
  //   commercialName: false,
  //   addSubProject: true,
  // });

  // const handleViewAllChange = (e) => {
  //   const isChecked = e.target.checked;
  //   const updatedVisibility = Object.fromEntries(
  //     Object.keys(columnsVisibility).map((key) => [key, isChecked])
  //   );
  //   setColumnsVisibility(updatedVisibility);
  // };

  // const handleColumnChange = (column) => {
  //   setColumnsVisibility((prev) => ({
  //     ...prev,
  //     [column]: !prev[column],
  //   }));
  // };

  // const handleApply = () => {
  //   // Your apply logic here
  // };

  
 // Initial state for column visibility
 const [columnsVisibility, setColumnsVisibility] = useState({
  SNo: true,
  createdDate: true,
  projectCode: true,
  sub: true,
  changeStatus: true,
  profitLoss: true,
  OAD: true,
  CMD: true,
  NetCost: true,
  NetDelivered: true,
  DS: true,
  expected: true,
  registeredName: false,
  assignTo: false,
  commercialName: false,
  addSubProject: true,
  action: true,
});

// Load persisted state from localStorage on mount
useEffect(() => {
  const savedColumns = localStorage.getItem('columnsVisibility');
  if (savedColumns) {
    setColumnsVisibility(JSON.parse(savedColumns));
  }
}, []);

// Save state to localStorage
const handleApply = () => {
  localStorage.setItem('columnsVisibility', JSON.stringify(columnsVisibility));
  //alert('Column visibility applied!');
};
const handleApplyReset = () => {
  localStorage.removeItem('columnsVisibility');
  window.location.reload();
  //alert('Column visibility applied!');
};

// Handle "View All" checkbox
const handleViewAllChange = (e) => {
  const isChecked = e.target.checked;
  const updatedVisibility = Object.fromEntries(
    Object.keys(columnsVisibility).map((key) => [key, isChecked])
  );
  setColumnsVisibility(updatedVisibility);
};

// Handle individual column checkbox
const handleColumnChange = (column) => {
  setColumnsVisibility((prev) => ({
    ...prev,
    [column]: !prev[column],
  }));
};
  
  return (
    <>
      {isLoading && <Loading />}
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <div className="d-flex justify-content-between align-items-center border-bottom  pb-16">
                <div className="">
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width={30}
                        height={30}
                      >
                        <g data-name="20-Arrow Left">
                          <path
                            fill="#0073ea"
                            d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                          />
                          <path
                            fill="#0073ea"
                            d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                          />
                        </g>
                      </svg>
                    </button>{" "}
                    My Clients
                  </h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a
                        href="/"
                        className="d-flex align-items-center gap-1 hover-text-primary"
                      >
                        <iconify-icon
                          icon="solar:home-smile-angle-outline"
                          className="icon text-lg"
                        ></iconify-icon>
                        Home
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">User Client Access</li>
                  </ul>
                </div>
                <a
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#addClientpop"
                  aria-controls="addClientpop"
                  className="btn btn-primary text-sm btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  <iconify-icon
                    icon="ic:baseline-plus"
                    className="icon text-xl line-height-1"
                  ></iconify-icon>{" "}
                  New Client
                </a>
              </div> 
                <ClientStatusCounter 
                  groupCounter={groupCounter}
                  projectStatus={projectStatus}
                />
              <div className="d-flex gap-2 align-items-center py-16 mt-16 postionTop-0 bg-white z-10"> 
                 <SearchInput 
                 handleSearchInputChange={handleSearchInputChange}
                 />
                 <DatePickerComponent 
                 isFlatPikerReset={isFlatPikerReset}
                 onDateRangeChange={handleDateRangeChange} 
                 />    
                    <select
                      className="selectStatus form-select w-240-px"
                      id="slectIconsShow"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "") {
                          // Remove the ProjectStatus key if "All" is selected
                          const updatedWhere = { ...where };
                          delete updatedWhere.ProjectStatus;
                          setWhere(updatedWhere);
                        } else {
                          // Otherwise, set the ProjectStatus key
                          setWhere({ ...where, ProjectStatus: value });
                        }
                      }}
                    >
                      <option value="" selected>All</option>
                      <option value="Active" selected={"Active"}>Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Hide">Hidden</option>
                    </select>          
                <button type="reset"  
                onClick={resetFilter}
                className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                >
                  Reset
                </button>
                {/* <div className="dropdown">
        <button className="btn bg-base px-18 py-9 dropdown-toggle toggle-icon border d-flex align-items-center gap-2" type="button" data-bs-toggle="dropdown">
          Manage Columns
        </button>
        <div className="dropdown-menu border wsetColumn">
          {/* View All checkbox  
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="viewAll"
              checked={Object.values(columnsVisibility).every((val) => val)}
              onChange={handleViewAllChange}
            />
            <label className="form-check-label" htmlFor="viewAll">
              View All
            </label>
          </div>
          <div className="mx-hight200px">
            {/* Individual column checkboxes  
            {Object.keys(columnsVisibility).map((column) => (
              <div className="form-check" key={column}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={column}
                  checked={columnsVisibility[column]}
                  onChange={() => handleColumnChange(column)}
                />
                <label className="form-check-label" htmlFor={column}>
                  {column}
                </label>
              </div>
            ))}
          </div>
          <button className="btn btn-primary mt-16 w-100" onClick={handleApply}>
            Apply
          </button>
        </div>
      </div> */}
      <div className="dropdown">
      <button
        className="btn bg-base px-18 py-9 dropdown-toggle toggle-icon border d-flex align-items-center gap-2"
        type="button"
        data-bs-toggle="dropdown"
      >
        Manage Columns
      </button>
      <div className="dropdown-menu border wsetColumn">
        {/* View All checkbox */}
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="viewAll"
            checked={Object.values(columnsVisibility).every((val) => val)}
            onChange={handleViewAllChange}
          />
          <label className="form-check-label" htmlFor="viewAll">
            View All
          </label>
        </div>
        <div className="mx-hight200px">
          {/* Individual column checkboxes */}
          {Object.keys(columnsVisibility).map((column) => (
            <div className="form-check" key={column}>
              <input
                type="checkbox"
                className="form-check-input"
                id={column}
                checked={columnsVisibility[column]}
                onChange={() => handleColumnChange(column)}
              />
              <label className="form-check-label" htmlFor={column}>
                {column}
              </label>
            </div>
          ))}
        </div>
        <button className="btn btn-primary mt-16 w-100" onClick={handleApply}>
          Apply
        </button>
        <button className="btn btn-danger mt-16 w-100" onClick={handleApplyReset}>
          Reset
        </button>
      </div>
    </div>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-neutral-900">
                  Clients Details
                </h6>
              </div> 
              <ClientList
                filteredData={filteredData}
                handleEditClick={handleEditClick}
                handleEditStatusClick={handleEditStatusClick}
                isLoading={isLoading}
                handleCancelClick={handleCancelClick}
                edit={edit}
                handleInputChange={handleInputChange}
                handleInputStatusChange={handleInputStatusChange}
                handleSaveClick={handleSaveClick}

                handleInputChange1={handleInputChange1}
                handleSaveClick1={handleSaveClick1}
                handleCancelClick1={handleCancelClick1}
                handleEditClick1={handleEditClick1}
                edit3={edit3}
                profitAndLoss={profitAndLoss}
                setClientId={setClientId}
                pageNo={pageNo} 
                OAD={OAD}
                CMD={CMD}
                columnsVisibility={columnsVisibility}
        handleViewAllChange={handleViewAllChange}
        handleColumnChange={handleColumnChange}
        handleApply={handleApply}
              />
              {/* <Pagination 
                pageNo={pageNo} 
                total={totalPages} 
                setPageNo={setPageNo}
              /> */}
            </div>
          </div>
        </div>
      </div>

      {/* === Start Add Client Sec ===  */}
      <div
        className="offcanvas offcanvas-end filterSecPopup"
        id="addClientpop"
        aria-labelledby="addClientSec"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="addClientSec">
            Add Client
          </h5>
          <button
            type="button"
            className="btn-close d-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body pt-0 pb-0">
          <Formik
            initialValues={ClientDefaultProps}
            validationSchema={ClientYupSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formikProps) => (
              <Render
                {...formikProps}
                setCountry={setCountry}
                files={files}
                setFiles={setFiles}
                isMSME={isMSME}
                setIsMSME={setIsMSME}
                isOtherService={isOtherService}
                setIsOtherService={setIsOtherService}
              />
            )}
          </Formik>
        </div>
      </div>
      <FilterPopup />
      <AssignTask
        // users={users}
        // getUserList={getUserList}
        handleLoadPermissions={handleLoadPermissions}
        role="Manager"
        getClientList={getClientList}
      />
      <ManageColum />
      <NewSubProjectAlter handleNewProject={handleNewProject} />
      <ProjectPermissionPopup
      selectedUser={selectedUser} 
      userSelectedProjectPermission={userSelectedProjectPermission}
      />
    </>

  )
}

export default ClientContainer