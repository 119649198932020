import React from 'react';
import Highcharts from 'highcharts';
import { useDispatch } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import { perfomanceSetSelectedProjectAction } from 'src/containers/employee-container/Perfomance/actions/PerfomanceActions';

const VehicleFuelChart = ({ projects=[],user, totalProjectsLabel,setProjectId }) => {
    // Calculate total cost and total projects
    const totalCost = projects.reduce((sum, project) => sum + project.amount, 0);
    const costSalaryRatio = ((totalCost / user?.monthlySalary||0)*100).toFixed(2);
    const totalProjects = projects.length;
    const dispatch = useDispatch();

    // Create chart data from the projects array
    const chartData = projects.map((project) => ({
        name: project.name,
        y: project.amount,
        color: project.color,
        projectId: project.projectId,
    }));

    // Chart configuration options
    const chartOptions = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
        },
        title: {
            text: '',
            align: 'left',
        },
        credits: {
            enabled: false, // Disable credits
        },
        tooltip: {
            pointFormat:
                '<b>{point.name}</b>: {point.percentage:.1f}%<br>' +
                'Amount: &#8377;{point.y}', // Adding amount in the tooltip
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format:
                        '<span style="opacity: 0.7; color:#20A271; font-size: 1.2em; font-weight:normal;">{point.percentage:.1f}%</span><br>' +
                        '<span style="font-size: 1.2em; color:#323338; font-weight:normal;"><b>{point.name}</b></span><br>' +
                        '<span style="opacity: 0.8; color:#7B7E8B; font-weight:normal;">&#8377;{point.y}</span>', // Amount in data labels
                    connectorColor: 'rgba(128,128,128,0.5)',
                },
                // Adding click event to capture clicked section value
                events: {
                    click: function (event) {
                        const clickedPoint = event.point; 
                        setProjectId(clickedPoint.projectId);
                        dispatch(perfomanceSetSelectedProjectAction({data:clickedPoint.name}));
                        // console.log('Clicked section:', {
                        //     name: clickedPoint.name,
                        //     y: clickedPoint.y,
                        //     percentage: clickedPoint.percentage,
                        //     projectId: clickedPoint.projectId,
                        // });    
                        // // You can add any other actions here
                        // alert(`You clicked on ${clickedPoint.projectId} - Amount: ₹${clickedPoint.y}`);
                    },
                },
            },
        },
        series: [
            {
                name: 'Status',
                data: chartData, // Dynamic chart data
            },
        ],
    };

    return (
        <div className="d-flex justify-content-between chartsetnewbg border-top border-bottom mt-24 rounded-12">
            <div className="chartsetnew1">
                {/* Chart section */}
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </div>
            {/* Total cost and total project section */}
            <div className="chartsetvalue1 tcostprojt border-bottom border-start">
                <div className="w-100 border-bottom px-24 pb-10 pt-16">
                    <div className="d-flex justify-content-between align-items-center tCost">
                        Total Cost <span>₹{`${totalCost.toFixed(2)}(${costSalaryRatio} %)`}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center tProject">
                        {/* Use the dynamic label here */}
                        {totalProjectsLabel || 'Total Projects'} <span>{totalProjects}</span>
                    </div>
                </div>

                {/* Project list section */}
                <div className="max-h-350-px overflow-auto px-24 pt-24">
                    {/* Sort projects by amount before mapping */}
                    {projects
                        .sort((a, b) => b.amount - a.amount) // Sorting in descending order
                        .map((project, index) => (
                            <button
                                key={index}
                                className="d-flex align-items-center justify-content-between gap-3 mb-24 w-100 btnchrtpie"
                                style={{
                                    borderLeft: `4px solid ${project.color}`, // Add dynamic border color
                                    padding: '0 0 0 10px', // Add padding to make the border more visible
                                    borderRadius: '0px', // Optional: Add rounded corners for better appearance
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h6 className="text-md mb-0 fw-medium">{project.name}</h6>
                                        <span className="text-sm text-secondary-light fw-medium">
                                            ₹{project.amount.toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                                <span className="text-success-500 text-md fw-medium">
                                    {project.percentage}%
                                </span>
                            </button>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default VehicleFuelChart;
