export const VENDOR_ACTIONS = {
    VENDOR_SEARCH: 'VENDOR_SEARCH',
    VENDOR_SEARCH_SUCCESS: 'VENDOR_SEARCH_SUCCESS',
    VENDOR_SEARCH_ERROR: 'VENDOR_SEARCH_ERROR',

    VENDOR_INVOICE_SEARCH: 'VENDOR_INVOICE_SEARCH',
    VENDOR_INVOICE_SEARCH_SUCCESS: 'VENDOR_INVOICE_SEARCH_SUCCESS',
    VENDOR_INVOICE_SEARCH_ERROR: 'VENDOR_INVOICE_SEARCH_ERROR',

    VENDOR_UPSERT: 'VENDOR_UPSERT',
    VENDOR_UPSERT_SUCCESS: 'VENDOR_UPSERT_SUCCESS',
    VENDOR_UPSERT_ERROR: 'VENDOR_UPSERT_ERROR',

    VENDOR_EDIT: 'VENDOR_EDIT',

    VENDOR_RESET: 'VENDOR_RESET',
};

export const vendorResetAction = () => ({
    type: VENDOR_ACTIONS.VENDOR_RESET,
});

export const vendorSearchAction = (payload) => ({
    type: VENDOR_ACTIONS.VENDOR_SEARCH,
    ...payload,
});

export const vendorInvoiceSearchAction = (payload) => ({
    type: VENDOR_ACTIONS.VENDOR_INVOICE_SEARCH,
    ...payload,
});

export const vendorEditAction = (payload) => ({
    type: VENDOR_ACTIONS.VENDOR_EDIT,
    ...payload,
});

export const vendorUpsertAction = (payload) => ({
    type: VENDOR_ACTIONS.VENDOR_UPSERT,
    ...payload,
});

export default {
    VENDOR_ACTIONS,
    vendorSearchAction,
    vendorUpsertAction,
    vendorEditAction,
};
