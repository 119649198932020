import { values } from "lodash";
import React, { useEffect, useState } from "react";
import { vendorSearchAPI } from "src/api/VendorApi";

const VendorSearchClient = ({handleVendorSelect}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null); // State to track the selected vendor
  const [isDropdownOpen, setIsDropdownOpen] = useState(true); // State to control the dropdown visibility
    const [vendors, setVendors] = useState([]);
    const [where,setWhere] = useState({});
  // Example vendor data
  // const vendors = [
  //   { id: 1, name: "Ahmad Monis", panCardNo: "ABCTY1234F" },
  //   { id: 2, name: "John Doe", panCardNo: "XCYZ1234L" },
  //   { id: 3, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  //   { id: 4, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  //   { id: 5, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  //   { id: 6, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  //   { id: 7, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  //   { id: 8, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  //   { id: 9, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  //   { id: 10, name: "Jane Smith", panCardNo: "ABJTS1287F" },
  // ];

  // Function to generate initials from name
  const getInitials = (name) => {
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0].toUpperCase()).join("");
    return initials;
  };

  // Filter vendors based on search term (name or PAN card)
  const filteredVendors = vendors?.filter(
    (vendor) =>
      vendor.firstName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      vendor.panCardNo?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  // Function to remove selected vendor
  const handleRemoveVendor = () => {
    setSelectedVendor(null);
    handleVendorSelect(null);
    setSearchTerm(""); // Reset the search term when vendor is removed
  };

  // Function to handle vendor selection and close dropdown
  const handleSelectVendor = (vendor) => {
    handleVendorSelect(vendor);
    setSelectedVendor(vendor);
    setSearchTerm(""); // Clear the search term after selecting
    setIsDropdownOpen(false); // Close the dropdown after selection
  };
  const getVendorList =async(where) => {
    const vendors = await vendorSearchAPI({currentPage:0,pageLength:100,where});
    setVendors(vendors.data.data.data);
  }
  useEffect(() => {
    getVendorList(where); 
  }, [
    where
  ]);

  return (
    <>
      <div className="dropdownSearchVender w-100">
        <div className="icon-field">
          <span className="icon">
            <iconify-icon icon="f7:person" />
          </span>
          <input
            type="search"
            name="#0"
            className="form-control"
            placeholder="Search vendor by name or PAN card number"
            value={searchTerm}
            autoComplete="off"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setIsDropdownOpen(true); // Open dropdown on search input
            }}
          />
        </div>

        {/* Show dropdown only if there is a search term and dropdown is open */}
        {searchTerm && isDropdownOpen && filteredVendors.length > 0 && (
          <ul className="mx-hight200px dropdownmenu px-12 py-12" style={{ display: "block" }}>
            {filteredVendors.map((vendor) => (
              <li key={vendor.id}>
                <label className="C_radioBtn C_CkeckBtn C_CkeckBtn2 cklset my-0 text-start">
                  <div className="d-flex align-items-start gap-2 mb-0 linset">
                    <span
                      className="UsIMgName d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "rgb(195, 224, 133)", width: 40, height: 40 }}
                    >
                      {getInitials(`${vendor.firstName} ${vendor.lastName}`)}
                    </span>
                    <div className="flex-grow-1">
                      <h6 className="text-md mb-1 fw-semibold">{`${vendor.firstName} ${vendor.lastName}`}</h6>
                      <p className="text-sm text-secondary-light fw-normal mb-0">
                        Pan Card: {vendor.panCardNo}
                      </p>
                    </div>
                  </div>
                  <input
                    type="radio"
                    name="vendor"
                    checked={selectedVendor?._id === vendor?._id}
                    onChange={() => handleSelectVendor(vendor)} // Select vendor and close dropdown
                  />
                  <span className="checkmark" />
                </label>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Display selected vendor below the dropdown */}
      {selectedVendor && (
        <div className="selectedVendor mt-4">
          <div className="d-flex align-items-center justify-content-between pt-12">
            <div className="d-flex align-items-start gap-2 mb-20 linset">
              <span
                className="UsIMgName d-flex align-items-center justify-content-center"
                style={{ backgroundColor: "rgb(195, 224, 133)", width: 40, height: 40 }}
              >
                {getInitials(`${selectedVendor.firstName} ${selectedVendor.lastName}`)}
              </span>
              <div className="flex-grow-1">
                <h6 className="text-md mb-1 fw-semibold">{`${selectedVendor.firstName} ${selectedVendor.lastName}`}</h6>
                <p className="text-sm text-secondary-light fw-normal mb-0">
                  Pan Card: {selectedVendor.panCardNo}
                </p>
              </div>
            </div>
            <button type="button" class="btn rounded-pill btn-info-100 text-info-600 radius-8 px-20 py-6" onClick={handleRemoveVendor}>Remove</button> 
          </div>
        </div>
      )}
    </>
  );
};

export default VendorSearchClient;
