import React from 'react'
import { CSVLink } from 'react-csv'


export const ExportReactCSV = ({ csvData, fileName,Label }) => {
    return (
        <CSVLink data={csvData} filename={fileName}>
        <button type="button" className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2">{Label||"Export"}     
            <img
                src="/assets/images/left-sidebar/xls-icon.svg"
                className="img-fluid"
                alt=""
            />            
        </button>
        </CSVLink> 
    )
}