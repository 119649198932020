import React from "react";
import { costFormat } from "src/utils/CommonUtils";

const InfoCard = ({ title, totalAmount, dueAmount, nonDueAmount, totalTransactions, dueTransactions, nonDueTransactions, onClick, isActive }) => {
  // Helper function to conditionally return class based on the value (amount or count)
  const getAmountClass = (value) => {
    return value < 0 ? "text-red" : "textdblue"; // text-red for negative, textdblue for positive or zero
  };  

  return (
    <button
      className={`card setAcontTab w-100 ${isActive ? 'active' : ''}`}
      onClick={onClick} // Trigger the onClick handler passed from parent
    >
      <div className="header">
        <span>{title}</span>
        <span className="badge">{totalTransactions}</span>
      </div>
      <div className={`total-amount border-bottom pb-12 ${getAmountClass(totalAmount)}`}>
        {costFormat(totalAmount)}
      </div>
      <div className="details">
        <div className="due d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <span>Due</span>
            <span className={`count ${getAmountClass(dueTransactions)}`}>
              {dueTransactions}
            </span>
          </div>
          <span className={`amount ${getAmountClass(dueAmount)}`}>
            {costFormat(dueAmount)}
          </span>
        </div>
        <div className="non-due d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2 textdueset">
            <span>Non-due</span>
            <span className={`count ${getAmountClass(nonDueTransactions)}`}>
              {nonDueTransactions}
            </span>
          </div>
          <span className={`amount ${getAmountClass(nonDueAmount)}`}>
            {costFormat(nonDueAmount)}
          </span>
        </div>
      </div>
    </button>
  );
};

export default InfoCard;
