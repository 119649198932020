import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { projectAssigneeLoadAction, setProjectAssigneeAction } from 'src/containers/client-container/actions/ClientActions';
import { projectEditAction } from 'src/containers/project-container/actions/ProjectActions';
 
export const useAssignPopup = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { _id: userId, roleDetails } = useSelector(state => state.auth?.user, shallowEqual);

  const handleOpenAssignPopup = useCallback((project, client,source) => {
    const projectParticipantIds = source === 'project' ? project?.participants?.map((participant) => participant.userId._id) : project?.participants?.map((participant) => participant._id);
    dispatch(projectAssigneeLoadAction({
      data: projectParticipantIds,
      projectId: project._id,
      projectCode: client.ClientProjectCode,
      projectSub: project.ProjectSubCode,
    }));
  }, [dispatch]);

  const handleProjectAssigneeDetails = useCallback((data) => {
    const projectParticipants = data?.map((participant) => participant.userId);
    dispatch(setProjectAssigneeAction({data:projectParticipants}));
  }, [dispatch]);

  const viewProject = useCallback((type, projectId,data) => {
    const currentProject = data.find((project) => project._id === projectId);
    dispatch(projectEditAction({
      _id: currentProject._id,
      userId: userId
    }));
    localStorage.setItem("projectId", projectId);
    if (type === "view") {
      window.open(`/projects/view/department/${projectId}`, '_blank');
    }
    else {
      navigation(`/projects/task/${projectId}`);
    }
  });
  return { 
    handleOpenAssignPopup,
    handleProjectAssigneeDetails,
    viewProject
 };
};