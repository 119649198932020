import React, { useState, useEffect, useRef } from 'react'
import SummariesComponent from './SummariesComponent';
import { calculateDeviation, costFormat } from 'src/utils/CommonUtils';
import { useSticky } from 'src/hooks/useSticky';

// Function to get initials from manager name
const getInitials = (name) => {
  const nameParts = name.split(' ');
  const initials = nameParts.map(part => part[0]).join('');
  return initials;
};

// Function to determine background color based on manager's name
const getBackgroundColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 70%, 50%)`; // HSL for more variation
  return color;
};

// Function to determine text color based on background color (light or dark)
const getTextColor = (bgColor) => {
  const rgb = bgColor.match(/\d+/g).map(Number);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness > 150 ? '#000' : '#fff'; // Light or dark text based on background
};

// Function to determine text color based on delivery percentage
const getClassName = (value) => {
  return value.includes('-') ? 'text-red' : 'text-green';
};


const PddstatsManager = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props?.search)
  }, [props])


  // Group by managerId and sum the totals
  const groupedData = Object.values(
    data.reduce((acc, item) => {
      if (!acc[item.managerId]) {
        acc[item.managerId] = {
          managerId: item.managerId,
          profilePicture: item.profilePicture,
          fullName: item.fullName.trim() ? item.fullName : "No Manager", // add fullName on first encounter
          totalOad: 0,
          totalcmd: 0,
          totalDeliveryCost: 0,
        };
      }
      acc[item.managerId].totalOad += item.totalOad;
      acc[item.managerId].totalcmd += item.totalcmd;
      acc[item.managerId].totalDeliveryCost += item.totalDeliveryCost;
      return acc;
    }, {})
  );
  // Sort by fullName in ascending order
  groupedData.sort((a, b) => a.fullName.localeCompare(b.fullName));

  // console.log("groupedData22", groupedData);



  const trimmedQuery = (typeof props.searchQuery === 'string' ? props.searchQuery : '').trim().toLowerCase();
  const filteredData = trimmedQuery
    ? groupedData.filter((item) =>
      // item.ClientProjectCode.toLowerCase().includes(trimmedQuery) ||
      item.fullName.toLowerCase().includes(trimmedQuery)
    )
    : groupedData; // Return all data if the searchQuery is empty

  const [totalOAD2, totalCMD2, totalDelivery2] = filteredData.reduce(
    ([oadSum, cmdSum, deliverySum], item) => [
      oadSum + (item.totalOad || 0),
      cmdSum + (item.totalcmd || 0),
      deliverySum + (item.totalDeliveryCost || 0)
    ],
    [0, 0, 0]
  );

  const summaries2 = [
    { title: 'Total Manager', value: filteredData.length },
    { title: 'Total OAD', value: costFormat(totalOAD2) },
    { title: 'Total Delivered', value: costFormat(totalDelivery2) },
    { title: 'Total CMD', value: costFormat(totalCMD2) },
  ];

  // const { isSticky, elementRef } = useSticky(); // Use the custom hook
  // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start

  return (

    <>
      <div className="TotlUbgset pb-24">
        <SummariesComponent summaries={summaries2} /></div>
        <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Manager</th>
              <th>OAD</th>
              <th>CMD</th>
              <th>Delivered</th>
              <th>% Delivered</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">No data available.</td>
              </tr>
            ) : (
              filteredData.map((row, index) => {
                const bgColor = getBackgroundColor(row.fullName);
                const textColor = getTextColor(bgColor);
                const deviation = calculateDeviation(row.totalDeliveryCost, row.totalOad)
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        {row.profilePicture ? (
                          <img
                            src={row.profilePicture}
                            alt={row.fullName}
                            className="initials-image"
                            style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: '8px' }}
                          />
                        ) : (
                          <span
                            className="initials-circle UsIMgName"
                            style={{ backgroundColor: bgColor, color: textColor }}>
                            {getInitials(row.fullName)}
                          </span>
                        )}
                        {row.fullName}
                      </div>
                    </td>
                    <td>{costFormat(row.totalOad)}</td>
                    <td>{costFormat(row.totalcmd)}</td>
                    <td>{costFormat(row.totalDeliveryCost)}</td>
                    <td className={`text-end ${getClassName(deviation)}`}>
                      {deviation}
                    </td>
                  </tr>
                );
              }))}
          </tbody>
        </table>
      </div>
    </>

  )
}

export default PddstatsManager
