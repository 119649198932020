import React, { useState, useEffect, useRef } from "react";

const HSNSearchDropdown = ({ name, setFieldValue, index, hsnData, value = '' }) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [selectedHSN, setSelectedHSN] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Start with dropdown closed
  const dropdownRef = useRef(null); // Reference to the dropdown container

  // console.log("indexindexindex", hsnData[index], index, value);

  // Filter HSN based on search term (code or description)
  const filteredHSN = hsnData.filter(
    (hsn) =>
      hsn.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      hsn.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to handle HSN selection and close dropdown
  const handleSelectHSN = (hsn) => {
    setSelectedHSN(hsn);
    setFieldValue(name, hsn.code);
    setSearchTerm(hsn.code); // Set searchTerm to the selected HSN's code
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  // Close dropdown if click is outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Sync searchTerm when `value` changes (for editing)
  useEffect(() => {
    if (value) {
      setSearchTerm(value); // Update searchTerm if the value changes (for editing)
    }
  }, [value, hsnData, index]);

  return (
    <div ref={dropdownRef} className="dropdownSearchHSN w-100">
      <div className="icon-field">
        <span className="icon">
          <iconify-icon icon="f7:person" />
        </span>
        <input
          type="search"
          className="form-control text-sm"
          placeholder="HSN/SCN"
          value={searchTerm}
          autoComplete="off"
          onChange={(e) => {
            const value = e.target.value
            setSearchTerm(value);
            setFieldValue(name, value);
            setIsDropdownOpen(true); // Open dropdown on search input
          }}
        />
      </div>

      {/* Show dropdown only if there is a search term and dropdown is open */}
      {searchTerm && isDropdownOpen && filteredHSN.length > 0 && (
        <ul className="mx-hight200px dropdown-menu px-12 py-12 w-440-px" style={{ display: "block" }}>
          {filteredHSN.map((hsn) => (
            <li key={hsn.id}>
              <label className="C_radioBtn C_CkeckBtn C_CkeckBtn2 cklset my-0 text-start">
                <div className="d-flex align-items-start gap-2 mb-0 linset">
                  <div className="flex-grow-1">
                    <h6 className="text-md mb-1 fw-semibold">{hsn.code}</h6>
                    <p className="text-sm text-secondary-light fw-normal mb-0">
                      Description: {hsn.description}
                    </p>
                  </div>
                </div>
                <input
                  type="radio"
                  name="hsn"
                  checked={selectedHSN?.id === hsn.id}
                  onChange={() => handleSelectHSN(hsn)} // Select HSN and close dropdown
                />
                <span className="checkmark" />
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HSNSearchDropdown;
