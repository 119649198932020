import React, { useState } from 'react'
import DeliverytableData from './DeliverytableData';
import TopTabs from 'src/containers/project-container/View/TopTabs';
import DeliveryPlanOAD from './DeliveryPlanOAD';
import DeliveryAddComment from './DeliveryAddComment';

const DeliveryPlanCMD = () => {
  // Define the tab data dynamically

  const [tableData, setTableData] = useState([
    {
      id: 1,
      month: "August 2025",
      milestone: "1-10 Days",
      planned: "₹69598.00",
      delivered: "₹58598.00",
      status: "Achieved",
      reason: "PMD/AKS/Singrauli/08C/11/2023 dated 08th Dec 2023...",
      addedBy: "Nidhi Sharma",
    },
    {
      id: 2,
      month: "August 2025",
      milestone: "11-20 Days",
      planned: "₹69598.00",
      delivered: "₹58598.00",
      status: "Achieved",
      reason: "PMD/AKS/Singrauli/08C/11/2023 dated 08th Dec 2023...",
      addedBy: "Nidhi Sharma",
    },
    {
      id: 3,
      month: "August 2025",
      milestone: "21-30 Days",
      planned: "₹69598.00",
      delivered: "₹58598.00",
      status: "Not Achieved",
      reason: "",
      addedBy: "Nidhi Sharma",
    },
  ]);

  const [tableData2, setTableData2] = useState([
    {
      id: 1,
      month: "August 2025",
      //   milestone: "1-10 Days",
      planned: "₹69598.00",
      delivered: "₹58598.00",
      status: "Achieved",
      reason: "PMD/AKS/Singrauli/08C/11/2023 dated 08th Dec 2023...",
      addedBy: "Nidhi Sharma",
    },
    {
      id: 2,
      month: "August 2025",
      //   milestone: "11-20 Days",
      planned: "₹69598.00",
      delivered: "₹58598.00",
      status: "Achieved",
      reason: "PMD/AKS/Singrauli/08C/11/2023 dated 08th Dec 2023...",
      addedBy: "Nidhi Sharma",
    },
    {
      id: 3,
      month: "August 2025",
      //   milestone: "21-30 Days",
      planned: "₹69598.00",
      delivered: "₹58598.00",
      status: "Not Achieved",
      reason: "",
      addedBy: "Nidhi Sharma",
    },
  ]);

  const tabsData = [
    {
      id: 'cmd-plan',
      title: 'CMD Delivery Plan',
      content: <DeliverytableData tableData={tableData} />,
      active: true,
    },
    {
      id: 'oad-plan',
      title: 'OAD Delivery Plan',
      content: <DeliveryPlanOAD tableData={tableData2} className="" />,
      active: false,
    },
  ];

  // State to track the active tab
  const [activeTab, setActiveTab] = useState(tabsData[0].id);

  // Tab change handler
  const handleTabChange = (id) => {
    setActiveTab(id);
  }; 

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center border-bottom  pb-16 position-relative z-3 pt-24 bg-white">
            <div className="">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                Delivery Plan
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                    <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Delivery Plan</li>
              </ul>
            </div>
          </div>
          <TopTabs />
          <ul
            className="nav button-tab nav-pills mb-16 mt-24 gap-4"
            id="dynamic-tabs"
            role="tablist"
          >
            {tabsData.map((tab) => (
              <li className="nav-item" role="presentation" key={tab.id}>
                <button
                  className={`nav-link badge text-sm fw-semibold border border-info-600 text-info-600 bg-transparent px-20 py-9 radius-4 text-white ${activeTab === tab.id ? 'active bg-info-600' : ''
                    }`}
                  id={`${tab.id}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#${tab.id}`}
                  type="button"
                  role="tab"
                  aria-controls={tab.id}
                  aria-selected={activeTab === tab.id}
                  onClick={() => handleTabChange(tab.id)}
                >
                  {tab.title}
                </button>
              </li>
            ))}
          </ul>
          <div className="tab-content" id="dynamic-tabs-content">
            {tabsData.map((tab) => (
              <div
                key={tab.id}
                className={`tab-pane fade ${activeTab === tab.id ? 'active show' : ''
                  }`}
                id={tab.id}
                role="tabpanel"
                aria-labelledby={`${tab.id}-tab`}
              >
                {tab.content}
              </div>
            ))}
          </div>


        </div>
      </div>
      <DeliveryAddComment />
    </div>
  )
}

export default DeliveryPlanCMD
