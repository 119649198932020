import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form,Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import config from "src/config";
import { postRequest } from 'src/utils/RequestUtil';
import FormikInput from "src/components/formik/FormikInput";
import FormikTextarea from "src/components/formik/FormikTextarea";
import MeasurementDropdown from "./MeasurementDropdown";
import VendorSearchClient from "./VendorSearchClient";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useParams } from "react-router-dom";
import { VendorDeliveryDefaultProps, VendorDeliveryYupSchema } from "./VendorDeliveryPropTypes";
import { productionFIleRemoveAPI, userProductionFileAPI } from "src/api/ProductionApi";
import { getProjectForClientDeliveryFromAPI } from "src/api/ClientProductionApi";
import { productionProjectAssigneeSearchAction } from "src/containers/production-container/actions/ProductionActions";
import ProjectTree from "./ProductionTree/ProjectsTree";
import ClientDeliveryBreadCrumbs from "../ClientDeliveryWise/DeliveryTree/ClientDeliveryBreadCrumbs";
import BreadCrumbs from "./ProductionTree/BreadCrum";

const Render = (props) => {
  // if (!isProduction()) {
  //   withFormikDevtools(props);
  // }
  const {
    files,
    showModal,
    handleRemoveFile, 
    handleUploadFile, 
    isSubmitting ,
    isValid, 
    dirty,
    setFieldValue,
    values
  } = props;
    const dateInputRef = useRef(null);  // Create a ref for the input  
    useEffect(() => {
      // Initialize flatpickr and disable time picker
      flatpickr(dateInputRef.current, {
        enableTime: false,  // Disable time picker
        dateFormat: "Y/m/d",  // Date format MM/DD/YYYY
        onChange: (selectedDates, dateStr) => {
          if (selectedDates.length > 0) {
            const selectedDate = selectedDates[0];
              const date = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
              setFieldValue('date', date);
        }
      }
      });
    }, []);
    useEffect(() => {
      values.totalCost = (parseInt(values.totalDurations||0)*parseInt(values.perUnitCost||0)).toFixed(2);
    }, [values]);
    const handleVendorSelect = (selectedVendor) => {
      console.log('selectedVendor',selectedVendor);
      setFieldValue('vendorId',(selectedVendor?._id)?selectedVendor._id:null);
    };

  const handleUnitOfMeasureSelect = (selectedUnit) => {
    setFieldValue('uom', selectedUnit);
  };

  return (
    <>
     <Form autoComplete="disabled">
                {/* <div className=""> */}
                <ProjectTree showModal={showModal} />
                <BreadCrumbs/>
                {/* </div> */}
                {/* Use d-none for elements to hide after next button click */} 
                <div className={`col-md-4 mb-20 vvan-none'}`}>
                  <label className="form-label fw-semibold text-primary-light text-sm mb-8">Date</label>
                  <div className="position-relative">
                  <Field
                        className="form-control radius-8 bg-base"
                        id="editstartDate"
                        type="text"
                        name="date" // Link to Formik state using 'name'
                        placeholder="MM/DD/YYYY"
                        innerRef={dateInputRef}  // Attach ref to the input using innerRef
                        onChange={(e) => setFieldValue('date', e.target.value)}  // Manually update the value
                      />
                      <ErrorMessage
                        name="date" // Link ErrorMessage to the 'startDate' field
                        component="div"
                        className="text-danger"
                      />
                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                      <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
                    </span>
                  </div>
                </div>
                <div className={`col-md-12 mb-20 position-relative vvan-none'}`}>
                  <label className="form-label fw-semibold text-primary-light text-sm mb-8">Vendor</label>
                  <VendorSearchClient handleVendorSelect={handleVendorSelect} />
                  <ErrorMessage
                        name="vendorId" // Link ErrorMessage to the 'startDate' field
                        component="div"
                        className="text-danger"
                      />
                </div>
                <div className={`col-md-12 mb-20 d-flex justify-content-between align-items-start gap-2 vvan-none'}`}>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Unit of Measurement <span className="text-red">*</span></label>
                    <MeasurementDropdown  handleUnitOfMeasureSelect={handleUnitOfMeasureSelect} />
                    <ErrorMessage
                        name="uom" // Link ErrorMessage to the 'startDate' field
                        component="div"
                        className="text-danger"
                      />
                  </div>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Number <span className="text-red">*</span></label> 
                    <FormikInput
                      type="number"
                      name="totalDurations"
                      className="form-control"
                      placeholder={0}
                    />
                  </div>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Per Unit Cost <span className="text-red">*</span></label>
                    <div className="icon-field">
                      <span className="icon" style={{ top: 6 }}>
                        &#8377;
                      </span> 
                      <FormikInput
                      type="number"
                      name="perUnitCost"
                      className="form-control"
                      placeholder={0}
                    />
                    </div>
                  </div>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Total Cost</label> 
                    <FormikInput
                      type="number"
                      name="totalCost"
                      className="form-control"
                      readOnly
                      placeholder={0}
                    />
                  </div>
                  <div>
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">HSN/SAC Code</label> 
                    <FormikInput
                      type="text"
                      name="hsnCode"
                      className="form-control"                      
                      placeholder="172560"
                    />
                  </div>
                </div>
                <div className={`col-md-12 mb-20 vvan-none'}`}>
                  <div className="produComment">
                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">Comment</label>
                    <FormikTextarea
                      name="comment"
                      className="form-control"
                      rows={4}
                      cols={50}
                      placeholder="Write comment here..."
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className='py-16 psstyky bg-white z-9'>
                    <button  type="submit" 
                    className="btn btn-primary w-100 mt-16" 
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    disabled={!isValid || !dirty || isSubmitting}
                    >
                      Submit{" "}
                    </button>
              </div>
            </Form>
    </>
  );
};

const VendorAddClientDelivery = ({search}) => {
  const { projectId } = useParams();
  // Function to format the date
  const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual); 
  const { selectModule,selectTask } = useSelector(state => state.ProductionReducer, shallowEqual)
  const {currentRecord} = useSelector((state) => state.ProjectReducer,shallowEqual)

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([])
  // State variables
  const [headingText, setHeadingText] = useState("Add Vendor Delivery");
  const modalRef = useRef(null);
  // Handler for Submit button click
  const handleSubmit = useCallback(async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    try {
      const productionSlugParts = [
        currentRecord?.RefClientId?.ClientProjectCode,  // Check for ClientProjectCode
        currentRecord?.ProjectSubCode,                 // Check for ProjectSubCode
        selectModule?.moduleName,
        selectTask?.taskName,
      ];
      values.userId = userId
      values.clientId = currentRecord.RefClientId?._id
      values.projectId = currentRecord._id      
      values.moduleId = selectModule?.moduleId;
      values.taskId = selectTask.taskId
      values.productionSlug = productionSlugParts.filter(Boolean).join('>');
      if(!(await handleExternalValidation(values))) return

      const url = `${config.baseUrl}vendorDelivery/save`;
      const result = await postRequest({
        url,
        data: values,
      });
      search()
      // dispatch(clientProductionSearchAction())
      toast.success('Vendor delivery submitted successfully!');
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      toast.error('Failed to submit form! ' + error.message);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  }, [
    currentRecord?._id,
    currentRecord?.RefClientId?._id,
    selectModule,
    selectTask,
    userId,
  ]);

   const handleExternalValidation = async (values) => {
    // Define the validation schema
    const validationSchema = Yup.object().shape({        
        userId: Yup.string().required('Session Expired Please Login Again from the existing panel'), 
        projectId: Yup.string().required('Please select a project'),
        moduleId: Yup.string().required('Please select a Module'),
        taskId: Yup.string().required('Please select a Task'),
        vendorId: Yup.string().required('Please select a vendor'),
        uom: Yup.string().required('Please select a UOM'),
    });  
    try {
      // Validate the values object
      await validationSchema.validate(values, { abortEarly: false });    
      return true;
    } catch (errors) {
      // Handle validation errors
      errors.inner.forEach(error => {
        // Display the error message for each field
        toast.error(error.message);
      });
      return false;
    }
  };

  const handleUploadFile = async (event) => {
    const files = event.target.files;
    const payload = { file: files[0], by: userId };
    const fileResponse = await userProductionFileAPI(payload);
    setFiles(prevFiles => [...prevFiles, fileResponse.data.data]);
  };

  const handleRemoveFile = async (data) => {
    const remove = await productionFIleRemoveAPI({ ids: [data._id] })
    if (remove)
      setFiles(files.filter(item => item._id !== data._id))
  }

  const loadProjectIntoTree = async (projectId) => {
    const projects = await getProjectForClientDeliveryFromAPI(projectId)
    dispatch(productionProjectAssigneeSearchAction({
      data: projects.data.data
    }));
  }

  useEffect(() => {
    loadProjectIntoTree(projectId);
  }, [projectId]);

  // Reset form on modal show
  useEffect(() => {
    const modalElement = modalRef.current;
    const handleModalShow = () => {
      // resetForm();
    };
    // Listen for the modal show event
    modalElement.addEventListener('show.bs.modal', handleModalShow);
    // Cleanup event listener on unmount
    return () => {
      modalElement.removeEventListener('show.bs.modal', handleModalShow);
    };
  }, []);

  return (
    <>
      

      <div
        ref={modalRef}
        className="modal fade pcdModal producSecMAin cusmodl"
        id="vendoraddClientDelivery"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between pb-0 border-0">
              <h6 className="mb-2 fw-bold text-lg mb-0">{headingText}</h6>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
            </div>
           
            <div className="modal-body pb-0">
            <Formik
                initialValues={VendorDeliveryDefaultProps}
                validationSchema={VendorDeliveryYupSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {(formikProps) => (
                  <Render
                    {...formikProps}
                    files={files}
                    // showModal={showModal}
                    handleUploadFile={handleUploadFile}
                    handleRemoveFile={handleRemoveFile}
                  />
                )}
              </Formik>
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorAddClientDelivery;
