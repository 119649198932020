import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Highcharts3D from 'highcharts/highcharts-3d';
import DateRangePickerWithAll from 'src/components/DateRangePickerWithAll';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import MonthPicker from 'src/components/MonthPicker';
import { getHubStaffHourDateWiseAPI } from 'src/api/PerfomanceAPI';
Highcharts3D(Highcharts);

const HubStaffHours = () => {
    const { employeeID, userId } = useParams();
    const [series, setSeries] = useState([]);
    const [dateRangeHubstaff, setDateRangeHubstaff] = useState([moment().subtract(29, 'days'), moment()]);
    const { selectedMonth, selectedYear } = useSelector((state) => state.ProductionReducer, shallowEqual);
    
    const [where, setWhere] = useState({
        userId,
        $expr: {
            $and: [
                { $eq: [{ $year: "$date" }, selectedYear] },
                { $eq: [{ $month: "$date" }, selectedMonth] }
            ]
        }
    });
    const [whereMySql, setWhereMySql] = useState({
        empid: employeeID,
        year: selectedYear,
        month: selectedMonth,
    });

    const chartRef = useRef(null); // Create a ref for the chart
    const [isLeftDisabled, setIsLeftDisabled] = useState(true); // Initially, left button is disabled
    const [isRightDisabled, setIsRightDisabled] = useState(false); // Initially, right button is enabled

    useEffect(() => {
        search(where);
    }, [where, whereMySql]);

    const search = useCallback(async () => {
        const hubstaffData = await getHubStaffHourDateWiseAPI({ where, whereMySql });
        setSeries(hubstaffData.data.series);
    }, [where, whereMySql]);

    useEffect(() => {
        setWhere({
            ...where,
            $expr: {
                $and: [
                    { $eq: [{ $year: "$date" }, selectedYear] },
                    { $eq: [{ $month: "$date" }, selectedMonth] }
                ]
            }
        });
        setWhereMySql({
            ...whereMySql,
            year: selectedYear,
            month: selectedMonth
        });
    }, [selectedMonth, selectedYear]);

    const formatTime = (value) => {
        if (value < 1) {
            return `${Math.round(value * 60)} min`; // Convert hours to minutes
        } else {
            return `${Math.floor(value)} hrs ${Math.round((value % 1) * 60)} min`; // Show as hours and minutes
        }
    };

    const customText = " (55%)"; // Your custom text

    const options = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 1,
                depth: 0,
                viewDistance: 40,
            },
            panning: true, // Enable panning for scroll functionality
            panKey: 'shift', // Hold shift key to scroll
        },
        title: {
            text: 'Hubstaff Hours',
            align: 'left',
            style: {
                float: 'left',
            },
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Date',
            },
            labels: {
                formatter: function () {
                    return `${this.value}`; // Append custom text to the date
                },
            },
            scrollbar: {
                enabled: true, // Enable horizontal scrollbar
            },
            max: 6, // Initially show only 7 days (indices 0 to 6)
        },
        yAxis: {
            title: {
                text: 'Hours',
            },
            min: 0,
            scrollbar: {
                enabled: true, // Enable vertical scrollbar
                liveRedraw: false, // This helps in improving performance when the content is large
            },
        },
        series: series.map((s) => ({
            ...s,
            data: s.data.map(([day, time]) => [day, time]), // Ensure data is correctly mapped
        })),
        colors: ['#22C55E', '#487fff', '#EF4444'],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: 10,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        const value = this.y;
                        const hours = Math.floor(value);
                        const minutes = Math.round((value % 1) * 60);
                        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`; // Format as HH:MM
                    },
                },
            },
        },
        tooltip: {
            shared: true,
            formatter: function () {
                let tooltipText = `<b>Date: ${this.key.replace("<br/>","")} </b><br/>`; // Add custom text next to the date
                this.points.forEach((point) => { 
                    tooltipText += `${point.series.name}: <b>${formatTime(point.y)}</b><br/>`; // Use the formatTime function
                });
                return tooltipText;
            },
        },
    };

    const updateButtonState = () => {
        const chart = chartRef.current?.chart; // Use optional chaining to ensure chartRef is not null
        if (!chart) return; // Ensure chart is not null
        const xAxis = chart.xAxis[0];
        const min = xAxis.min;
        const max = xAxis.max;
        const dataLength = options.series[0]?.data.length || 0; // Use `options` instead of `chartOptions`
    
        // Disable left button if at the start
        setIsLeftDisabled(min === 0);
        // Disable right button if at the end
        setIsRightDisabled(max >= dataLength - 1);
    };
    
    
    
      const handleScrollProductivityLeft = () => {
        const chart = chartRef.current?.chart; // Access the chart instance using ref
        if (!chart) return;
        chart.xAxis[0].setExtremes(chart.xAxis[0].min - 7, chart.xAxis[0].max - 7); // Scroll left by 1
        updateButtonState(); // Update button state after scroll
      };
    
      const handleScrollProductivityRight = () => {
        const chart = chartRef.current?.chart; // Access the chart instance using ref
        if (!chart) return;
        chart.xAxis[0].setExtremes(chart.xAxis[0].min + 7, chart.xAxis[0].max + 7); // Scroll right by 1
        updateButtonState(); // Update button state after scroll
      };

    return (
        <>
            <div className="col-md-12">
                <div className="card h-100 p-0">
                    <div className="card-body p-24">
                        <div className="ms-auto d-flex justify-content-end inwth">
                            {/* Optional date pickers could go here */}
                        </div>
                        <div className="wdefine1">
                            <div className="wdefine2">
                                <HighchartsReact highcharts={Highcharts} options={options}
                                 ref={chartRef} // Attach the ref to the chart component
                                 callback={updateButtonState} // Callback to update button state when chart is rendered
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end p-2 gap-2">
              <button 
                className="btn btn-primary" 
                onClick={handleScrollProductivityLeft} 
                disabled={isLeftDisabled} // Disable button based on state
              >
                <i className="mdi mdi-keyboard-backspace"></i>
              </button>
              <button 
                className="btn btn-primary" 
                onClick={handleScrollProductivityRight} 
                disabled={isRightDisabled} // Disable button based on state
              >
                <i className="mdi mdi-keyboard-backspace mdi-rotate-180"></i>
              </button>
            </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HubStaffHours