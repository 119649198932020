import React, { useState } from 'react';
import { dateFormat, tenureFormat } from 'src/utils/utils';

// Helper function to generate random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to calculate contrast color (black or white)
const getContrastColor = (hexColor) => {
  // Remove the # character
  hexColor = hexColor.substring(1);

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate brightness using the formula
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // If brightness is less than 128, return white, otherwise return black
  return brightness < 128 ? '#FFFFFF' : '#000000';
};

const EmployeeDetails = ({ user, personal }) => {
  // Function to extract initials (e.g., N from Nandita and S from Sharma)
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.length > 1 ? nameParts[0][0] + nameParts[1][0] : nameParts[0][0];
    return initials.toUpperCase();
  };

  // Generate dynamic background color and contrast text color
  const backgroundColor = getRandomColor();
  const textColor = getContrastColor(backgroundColor);
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const previousMonth = date.toLocaleString('default', { month: 'long' });
  // const currentMonth = (new Date().getMonth()-1).toLocaleString('default', { month: 'long' });
  return (
    <div className="employee-details my-24 border-bottom">
      <h2 className="card-title">Employee Details</h2>
      <div className="employee-ftdtal d-flex justify-content-between border-bottom py-16">
        <div className="employee-header d-flex align-items-center gap-2">
          <span className="profile-pic">
            {user?.profilePicture ? (
              <img
                src={user?.profilePicture}
                alt={`${user?.firstName} ${user?.lastName}`}
              />
            ) : (
              <div
                className="initials-placeholder"
                style={{
                  backgroundColor: backgroundColor,
                  color: textColor,
                }}
              >
                {getInitials(`${user?.firstName} ${user?.lastName}`)}
              </div>
            )}
          </span>
          <div>
            <h3 className="card-title text-violet-600">
              {`${user?.firstName} ${user?.lastName}`} <span className="badge text-sm fw-semibold text-primary-600 bg-primary-100 px-20 py-9 radius-16 text-white ms-4">({user?.employeeID})</span>
            </h3>
            <p className="mb-0">{user?.designationId?.designationName}</p>
            <p className="mb-0">Department: <span className="fw-semibold text-black">{user?.departmentId?.departmentName}</span></p>
          </div>
        </div>
        <div className="contact-info text-end">
          <p><span className="fw-bold text-xl d-block">{previousMonth}</span></p> 
        </div>
      </div>
      <div className="bank-details d-flex justify-content-between align-items-center">
        <div>Account No:<span className="fw-bold d-block"> {user?.accountno}</span></div>
        <div>IFSC:<span className="fw-bold d-block"> {user?.ifsc}</span></div>
        <div>Bank Name:<span className="fw-bold d-block"> {user?.bankname}</span></div>
        <div>Tenure:<span className="fw-bold d-block"> {tenureFormat(user?.joindate)}</span></div>
        <div>Date of Joining:<span className="fw-bold d-block"> {dateFormat(user?.joindate,false)}</span></div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
