import config from 'src/config';
import { deleteAsyncRequest, getRequest, postAsyncRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const productionSearchAPI = function (data) {
    const url = `${config.baseUrl}production/search`;
    return postRequest({
        url,
        data,
    });
};
export const getproductionAPI = function (data) {
    const url = `${config.baseUrl}production/getproduction`;
    return postRequest({
        url,
        data,
    });
};

export const productionCreateAPI = function (data) {
    const url = `${config.baseUrl}production/save`;
    return postRequest({
        url,
        data,
    });
};
export const resyncHubStaffDataAPI = function (data) {
    const url = `${config.baseUrl}salary/resync-hubstaff`;
    return postRequest({
        url,
        data,
    });
};

export const productionUpdateAPI = function ({
    productionId,
    data,
}) {
    const url = `${config.baseUrl}production/update/${productionId}`;
    return putRequest({
        url,
        data,
    });
};

export const productionFIleRemoveAPI = function (
    data
) {
    const url = `${config.baseUrl}file/delete`;
    return deleteAsyncRequest({
        url,
        data,
    });
};
export const userProductionFileAPI = function (data) {
    const fd = new FormData();
  
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        const jsonString = JSON.stringify(data[key]);
        fd.append(key, jsonString);
      } else if (data[key] instanceof FileList) {
        // If it's a FileList, iterate over it to append each file
        Array.from(data[key]).forEach((file, index) => {
          fd.append(`${key}[${index}]`, file); // appending each file
        });
      } else if (data[key] instanceof File) {
        fd.append(key, data[key]); // Directly append the file
      } else {
        fd.append(key, data[key]);
      }
    });
  
    const url = `${config.baseUrl}file/upload`;
    return postAsyncRequest({
      url,
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  export const getDeliveryDeviationAPI = function (data) {
    const url = `${config.baseUrl}clientProduction/getDeliveryDeviation`;
    return postRequest({
        url,
        data,
    });
};
  export const getMTDDeliveryAPI = function (data) {
    const url = `${config.baseUrl}clientProduction/getMTDDelivery`;
    return postRequest({
        url,
        data,
    });
};
  export const updateCostUnit = function (data) {
    const url = `${config.baseUrl}clientProduction/updateCostUnit`;
    return postRequest({
        url,
        data,
    });
};
  export const updateCloserDate = function (data) {
    const url = `${config.baseUrl}clientProduction/updateCloserDate`;
    return postRequest({
        url,
        data,
    });
};
export const getTaskProductionCountAPI = function (taskId, moduleId) {
  const url = `${config.baseUrl}production/getTaskProductionCount/${taskId}/${moduleId}`;
  return getRequest({
      url
  });
};
export const updateProduction = function ({productionId,data}) {
  const url = `${config.baseUrl}production/update/${productionId}`;
  return putRequest({
      url,
      data,
  });
};