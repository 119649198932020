import React from 'react'

const DeliveryAddComment = () => {
    return (
        <div>
            <div class="modal fade cusmodl" id="DeliveryAddComment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header d-flex justify-content-between">
                            <h5 class="modal-title" id="exampleModalLabel">Add Comment</h5>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
                            </div>
                            <div class="modal-body">
                               <textarea className="form-control" placeholder="Add Comment"></textarea>
                               <button className="btn btn-primary w-100 mt-16">Submit</button>
                                </div>
                                </div>
                                </div>
                                </div>
        </div>
    )
}

export default DeliveryAddComment
