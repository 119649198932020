import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { CLIENT_INVOICE_ACTIONS } from '../actions/ClientInvoiceActions';
import { ClientInvoicePropTypes } from '../ClientInvoicePropTypes';

const initialState = () => ({
    currentRecord: { ...ClientInvoicePropTypes },

    search: {
        data: [],
        groupCounts: { allUsers: 0, inHouseUsers: 0, orangeUsers: 0 },
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    uploadReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {

        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }


        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }


        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPLOAD: {
            return {
                ...state,
                uploadReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPLOAD_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                uploadReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPLOAD_ERROR: {
            return {
                ...state,
                uploadReqStatus: REQUEST_STATUS.ERROR,
            };
        }


        case CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_RESET: {
            return {
                ...state,
                currentRecord: {
                    _id: undefined,
                    firstName: '',
                    lastName: '',
                    email: '',
                    employeeID: '',
                    phone: '',
                    isAdmin: false,
                    status: true,
                    username: '',
                    password: 'acadecraft@123',
                    otherInformation: {
                        designationId: '',
                        departmentId: '',
                        locationId: '',
                        reportingManagerId: null,
                    },
                },
            };
        }

        default:
            return state;
    }
};
