export const CLIENT_INVOICE_ACTIONS = {
    CLIENT_INVOICE_SEARCH: 'CLIENT_INVOICE_SEARCH',
    CLIENT_INVOICE_SEARCH_SUCCESS: 'CLIENT_INVOICE_SEARCH_SUCCESS',
    CLIENT_INVOICE_SEARCH_ERROR: 'CLIENT_INVOICE_SEARCH_ERROR',

    CLIENT_INVOICE_UPSERT: 'CLIENT_INVOICE_UPSERT',
    CLIENT_INVOICE_UPSERT_SUCCESS: 'CLIENT_INVOICE_UPSERT_SUCCESS',
    CLIENT_INVOICE_UPSERT_ERROR: 'CLIENT_INVOICE_UPSERT_ERROR',



    CLIENT_INVOICE_UPLOAD: 'CLIENT_INVOICE_UPLOAD',
    CLIENT_INVOICE_UPLOAD_SUCCESS: 'CLIENT_INVOICE_UPLOAD_SUCCESS',
    CLIENT_INVOICE_UPLOAD_ERROR: 'CLIENT_INVOICE_UPLOAD_ERROR',

    CLIENT_INVOICE_EDIT: 'CLIENT_INVOICE_EDIT',

    CLIENT_INVOICE_RESET: 'CLIENT_INVOICE_RESET',
};

export const clientInvoiceResetAction = () => ({
    type: CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_RESET,
});

export const clientInvoiceSearchAction = (payload) => ({
    type: CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_SEARCH,
    ...payload,
});

export const clientInvoiceEditAction = (payload) => ({
    type: CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_EDIT,
    ...payload,
});

export const clientInvoiceUpsertAction = (payload) => ({
    type: CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPSERT,
    ...payload,
});
export const clientInvoiceUploadAction = (payload) => ({
    type: CLIENT_INVOICE_ACTIONS.CLIENT_INVOICE_UPLOAD,
    ...payload,
});

export default {
    CLIENT_INVOICE_ACTIONS,
    clientInvoiceSearchAction,
    clientInvoiceUpsertAction,
    clientInvoiceEditAction,
    clientInvoiceUploadAction,
};
