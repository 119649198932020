// LagDeductionDetail.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from 'formik';
import { costFormat } from 'src/utils/CommonUtils';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { sumWorkingTimes } from "src/utils/utils";
import { getHubstaffData } from "src/api/SalaryApi";
import { setHubStaffLiveDataAction } from "./actions/SalaryActions";

const LagDeductionDetail = ({
    lagHourDetails,
    leaveDetails,
    values,
    title,
    hubstaffTime, 
    pfDeduction,
    taxDeduction,
    otherDeduction,
    extraDeduction,
    totalDeduction,
    employeeDetails,
    className,
}) => {


    const { hubstaff } = useSelector((state) => state.SalaryReducer?.search?.data, shallowEqual);
    const { totalWorkingTime, totalAllowedWorkingTime } = sumWorkingTimes(hubstaff, 0); 
          // State to hold the day count and the calculated amount
  const [days, setDays] = useState(null);
  const dispatch = useDispatch();
  const ratePerDay = leaveDetails?.daySalary.toFixed(2)
  const amount = days * ratePerDay;

  // Handle change for days input
  const handleDayChange = (e) => {
    const value =e.target.value;// parseInt(e.target.value, 10) || 0; // Parse integer or default to 0
    setDays(value);
  };
  useEffect(() => {
    if(values.lagHourDetails){
        const day_salary = leaveDetails?.daySalary;
        const workHoursPerDay = 8;  
        const laghour = Math.max(0, (Number(values.lagHourDetails.requiredHours) - Number(values?.lagHourDetails?.exactHours)).toFixed(2));
         values.lagHourDetails.lagHours = Number(laghour);
        if (day_salary) { 
            const perHourSalary = Number(day_salary) / workHoursPerDay;
            values.lagHourDetails.lagHoursAmount = (perHourSalary * laghour).toFixed(2);  
        }
        if (day_salary && values?.lagHourDetails.productionLagDay) { 
           values.lagHourDetails.productionLagDayAmount = (day_salary *  Number(values.lagHourDetails.productionLagDay)).toFixed(2);
        }
    }
}, [lagHourDetails]);
  
const handleLoadHubStaffLiveData=async (userId,year,month)=>{
    const hubStaff = await getHubstaffData({userId,year,month});
    dispatch(setHubStaffLiveDataAction({data:hubStaff.data}))
    // return hubStaff
}

    return (
        <div className={`${className}`}>
            <div className="setbdn100">
                <h4 className="card-title">{title}</h4>
                <div className="radius-10 border p-16">
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-bottom">
                        <label className="form-label mb-0 text-neutral-500">Total Working Hours</label>
                        <span className="text-md fw-semibold text-neutral-800">{lagHourDetails?.totalWorkingHours}</span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-bottom">
                        <label className="form-label mb-0 text-neutral-500">Required Hours</label>
                        <span className={`text-md fw-semibold text-neutral-800 ${lagHourDetails?.requiredHours < 0 ? 'text-red' : ''}`}>
                            {lagHourDetails?.requiredHours}
                        </span>
                    </div>



                    {/* {formGroups.map(({ label, name, type, placeholder, options }) => (
                        <div className="align-items-center d-flex justify-content-between py-8" key={name}>
                            <label className="form-label mb-0">{label}</label>
                            <div className="inpset001">
                                {type === "number" ? (
                                    <Field
                                        name={name}
                                        type="number"
                                        className="form-control w-160-px ms-auto"
                                        placeholder={placeholder}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    <Field as="select" name={name} className="form-select w-160-px" onChange={handleChange}>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Field>
                                )}
                                <ErrorMessage name={name} component="div" className="text-danger small mt-1" />
                            </div>
                        </div>
                    ))} */}


                    <div className="align-items-center d-flex justify-content-between py-8 mt-16">
                        <label className="form-label mb-0">Exact Hours</label>
                        <div className="inpset001">
                            <Field
                                id="exactHours"
                                type="text"
                                name="lagHourDetails.exactHours"
                                className="form-control text-end w-160-px ms-auto"
                                placeholder="0"
                                onInput={(e) => {
                                    if (e.target.value > lagHourDetails?.requiredHours) {
                                      e.target.value = lagHourDetails?.requiredHours; // Programmatically limit input to 520
                                    }
                                  }}
                                // value={lagHourDetails?.exactHours}
                                // onChange={(e) => onInputChange("exactHours", e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Hubstaff and Lag Hours */}
                    <div className="align-items-center d-flex justify-content-between py-16 mt-16 border-top">
                        <span className="d-flex align-items-center">
                            <img src="/assets/images/hubstaff.png" className="img-fluid" alt="hubstaff" />
                        </span>
                        <div className="d-flex align-items-center gap-2 border radius-6 py-4 px-16  cursor-pointer" 
                        data-bs-toggle="modal" data-bs-target="#HubstaffTimeModal"
                        >
                            <span className="text-dblue text-sm fw-semibold">Hubstaff Time</span>
                            {totalWorkingTime} 
                            {
                                hubstaffTime.additional>0?<span className="text-green">+ {hubstaffTime.additional}</span>:<span className="text-red">{hubstaffTime.additional}</span>
                            }  
                        </div>
                        <div className="d-flex align-items-center gap-2 border radius-6 py-4 px-16 cursor-pointer" 
                        data-bs-toggle="modal" data-bs-target="#HubstaffLiveDataModal"
                        onClick={() => handleLoadHubStaffLiveData(values.userId._id, values.salaryYear, values.salaryMonth)}
                        >
                            <i className="mdi mdi-circle text-green"></i>
                            <span className="text-dblue text-sm fw-semibold">Hubstaff Live Data</span>
                        </div>
                    </div>

                    <div className="align-items-center d-flex justify-content-between py-12 border-bottom border-top mb-16">
                        <div className="LagHours">Lag Hours</div>
                        <div className="hours">{values?.lagHourDetails?.lagHours} hours</div>
                        {values?.lagHourDetails?.lagHoursAmount>0&&<div className={`Tamont text-red`}>
                            {values?.lagHourDetails?.lagHoursAmount < 0 ? `-₹ ${Math.abs(values?.lagHourDetails?.lagHoursAmount)}` : `₹ ${values?.lagHourDetails?.lagHoursAmount}`}
                        </div> }
                        
                    </div>

                    <div className="align-items-center d-flex justify-content-between pt-8 pb-16 border-bottom mb-16">
      <label className="form-label mb-0">Production Lag</label>
      <div className="d-flex gap-2 align-items-center">
        <div className="input-group w-160-px">
          <span className="input-group-text bg-base">Day</span>
          <Field
            id="productionLagDay"
            type="text"
            name="lagHourDetails.productionLagDay"
            className="form-control flex-grow-1 text-end"
            placeholder="0"
            // value={days}
            // onChange={handleDayChange}
          />
        </div>
        <div className="input-group w-160-px">
          <span className="input-group-text bg-base border-end-0">₹</span>
          <Field
            id="productionLagDayAmount"
            type="text"
            name="lagHourDetails.productionLagDayAmount"
            className="form-control flex-grow-1 text-end border-start-0"
            placeholder="0"
            // value={amount}
            disabled
          />
        </div>
      </div>
    </div>

                    {/* Deduction Options */}
                    <div className="align-items-center d-flex justify-content-between py-8">
                        <label className="form-label mb-0">Consider for Deduction:</label>
                        <div className="inpset001">
                            <div className="d-flex align-items-center flex-wrap gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        checked={true}
                                        name="considerDeduction"
                                        id="horizontal1"
                                        // onChange={() => onRadioChange("considerDeduction", "Yes")}
                                    />
                                    <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal1">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check checked-secondary d-flex align-items-center gap-2">
                                    <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="considerDeduction"
                                        id="horizontal2"
                                        // onChange={() => onRadioChange("considerDeduction", "No")}
                                    />
                                    <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="horizontal2">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Deduction Inputs */}
                    {[
                        { id:"pfDeduction",label: "PF Deduction", value: pfDeduction, name: "lagHourDetails.pfDeduction" },
                        { id:"taxDeduction",label: "Tax Deduction", value: taxDeduction, name: "lagHourDetails.taxDeduction" },
                        { id:"otherDeduction",label: "Other Deduction", value: otherDeduction, name: "lagHourDetails.otherDeduction" },
                        { id:"extraDeduction",label: "Extra Deduction", value: extraDeduction, name: "lagHourDetails.extraDeduction" },
                        { id:"totalDeduction",label: "Total Deduction", value: totalDeduction, name: "lagHourDetails.totalDeduction" },
                    ].map((item, index) => (
                        <div className="align-items-center d-flex justify-content-between py-8" key={index}>
                            <label className="form-label mb-0">{item.label}</label>
                            <div className="inpset001">
                                <Field
                                    id={item.id}
                                    type="text"
                                    name={item.name}
                                    className="form-control text-end w-160-px ms-auto"
                                    placeholder="0"
                                    // value={item.value}
                                    // onChange={(e) => onInputChange(item.name, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}


                    <div className={`align-items-center d-flex justify-content-between py-8 py-16 px-16 bg-primary-50 radius-10 ${employeeDetails.totalLeaveDeduction < 0 ? 'text-red bg-danger-100' : ''}`}>
                        <label className="form-label mb-0 text-dblue">Total Deduction</label>
                        <span className={`text-xl fw-bold ${lagHourDetails?.totalDeduction < 0 ? 'text-red' : ''}`}>
                            {costFormat(lagHourDetails?.totalDeduction||0)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Define prop types
LagDeductionDetail.propTypes = {
    title: PropTypes.string.isRequired,
    totalWorkingHours: PropTypes.number.isRequired,
    requiredHours: PropTypes.number.isRequired,
    exactHours: PropTypes.number.isRequired,
    hubstaffTime: PropTypes.shape({
        hours: PropTypes.number,
        additional: PropTypes.number,
    }).isRequired,
    lagHours: PropTypes.number.isRequired,
    lagAmount: PropTypes.number.isRequired,
    pfDeduction: PropTypes.number.isRequired,
    taxDeduction: PropTypes.number.isRequired,
    otherDeduction: PropTypes.number.isRequired,
    extraDeduction: PropTypes.number.isRequired,
    totalDeduction: PropTypes.number.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onRadioChange: PropTypes.func.isRequired,
};

export default LagDeductionDetail;
