import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const VendorDeliveryPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    totalDurations: PropTypes.string,
});

export const VendorDeliveryDefaultProps = { 
    totalDurations: '',
    _id: undefined
};

export const VendorDeliveryYupSchema = Yup.object().shape({ 
    totalDurations: Yup.number().required('Required')
    .positive('Must be a positive number'),
    perUnitCost: Yup.number().required('Required')
    .positive('Must be a positive number'),
    date: Yup.string().required('Required'),
    hsnCode:Yup.string().required('Required'),
});
export const VendorDeliveryInvoiceYupSchema = Yup.object().shape({ 
    totalDurations: Yup.number().required('Required')
    .positive('Must be a positive number'), 
    date: Yup.string().required('Required'),
});

export default {
    VendorDeliveryDefaultProps,
    VendorDeliveryPropTypes,
    VendorDeliveryYupSchema,
};
