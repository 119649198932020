import React, { useState } from 'react';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import Select2Component from 'src/components/Select2Component';


const FeedbackMailchain = () => {
    
  const [viewAll, setViewAll] = useState(false);

  const handleViewAllChange = (e) => {
    setViewAll(e.target.checked);
    // Expand or collapse all accordions based on checkbox state
    const accordions = document.querySelectorAll(".lmsn001");
    accordions.forEach((accordion) => {
      const collapse = new window.bootstrap.Collapse(accordion, {
        toggle: false,
      });
      viewAll ? collapse.hide() : collapse.show();
    });
  };
    return (
        <>
            <div className="modal fade cusmodl" id="ViewdescriptionModual" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between pb-0 border-0"><h6 className="mb-2 fw-bold text-lg mb-0">Content Development|Data Visualisation</h6><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
                        <div className="modal-body">
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-end mb-20"> <label className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0">
                                    View All
                                    <input
                                        type="checkbox"
                                        checked={viewAll}
                                        onChange={handleViewAllChange}
                                    />
                                    <span className="checkmark" />
                                </label></div>
                                <div className="accordion acodLMSNew" id="accordionExample">
                                    <div className="accordion-item p-0 mb-0">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button collapsed px-20 py-16 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                <div class="chat-sidebar-single w-100 p-0">
                                                    <div class="img imgsSet bgafnone">
                                                        K
                                                    </div>
                                                    <div class="info">
                                                        <h6 class="text-sm mb-1">Kathryn Murphy</h6>
                                                        <p class="mb-0 text-xs">hey! there i'm...</p>
                                                    </div>
                                                    <div class="action text-end">
                                                        <p class="mb-0 text-neutral-400 text-xs lh-1 text-nowrap ms-12">12:30 PM</p>
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse lmsn001 collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body px-20 py-20">
                                                <div className="d-flex align-items-start gap-3">
                                                    {/* <img src="/assets/images/user-list/user-list1.png" alt="" className="w-40-px h-40-px rounded-pill" /> */}
                                                    <div>
                                                        {/* <div className="d-flex align-items-center flex-wrap gap-2">
                            <h6 className="mb-0 text-lg">Kathryn Murphy</h6>
                            <span className="text-secondary-light text-md">kathrynmurphy@gmail.com</span>
                        </div> */}
                                                        <div className="mt-0">
                                                            <p className="mb-16 text-primary-light">Hi William</p>
                                                            <p className="mb-16 text-primary-light">
                                                                Just confirming that we transferred $63.86 to you via PayPal
                                                                <a href="javascript:void(0)" className="text-primary-600 text-decoration-underline">(info367@gmail.com)</a>
                                                                which you earned on the themewow Market since your last payout.
                                                            </p>
                                                            <p className="mb-0 text-primary-light">Thank you for selling with us!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item p-0 mb-0">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed  px-20 py-16 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <div class="chat-sidebar-single w-100 p-0">
                                                    <div class="img imgsSet bgafnone">
                                                        K
                                                    </div>
                                                    <div class="info">
                                                        <h6 class="text-sm mb-1">Kathryn Murphy</h6>
                                                        <p class="mb-0 text-xs">hey! there i'm...</p>
                                                    </div>
                                                    <div class="action text-end">
                                                        <p class="mb-0 text-neutral-400 text-xs lh-1 text-nowrap ms-12">02/Oct/2024 12:30 PM</p>
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse lmsn001 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body px-20 py-20">
                                                <div className="d-flex align-items-start gap-3">
                                                    {/* <span className="imgsSet"><img src="/assets/images/user-list/user-list2.png" alt="" className="w-40-px h-40-px rounded-pill" /></span> */}
                                                    <div>
                                                        {/* <div className="d-flex align-items-center flex-wrap gap-2">
                            <h6 className="mb-0 text-lg">Subrata Sen</h6>
                            <span className="text-secondary-light text-md">subratasen@gmail.com</span>
                        </div> */}
                                                        <div className="mt-0">
                                                            <p className="mb-0 text-primary-light">Awesome, thank you so much!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item p-0 mb-0">
                                        <h2 className="accordion-header" id="heading3">
                                            <button className="accordion-button collapsed  px-20 py-16 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                <div class="chat-sidebar-single w-100 p-0">
                                                    <div class="img imgsSet bgafnone">
                                                        K
                                                    </div>
                                                    <div class="info">
                                                        <h6 class="text-sm mb-1">Kathryn Murphy</h6>
                                                        <p class="mb-0 text-xs">hey! there i'm...</p>
                                                    </div>
                                                    <div class="action text-end">
                                                        <p class="mb-0 text-neutral-400 text-xs lh-1 text-nowrap ms-12">12:30 PM</p>
                                                    </div>
                                                </div>
                                            </button>
                                        </h2>
                                        <div id="collapse3" className="accordion-collapse lmsn001 collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                            <div className="accordion-body px-20 py-20">
                                                <div className="d-flex align-items-start gap-3">
                                                    {/* <span className="imgsSet"><img src="/assets/images/user-list/user-list2.png" alt="" className="w-40-px h-40-px rounded-pill" /></span> */}
                                                    <div>
                                                        {/* <div className="d-flex align-items-center flex-wrap gap-2">
                            <h6 className="mb-0 text-lg">Subrata Sen</h6>
                            <span className="text-secondary-light text-md">subratasen@gmail.com</span>
                        </div> */}
                                                        <div className="mt-0">
                                                            <p className="mb-0 text-primary-light">Awesome, thank you so much!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedbackMailchain