import { useState } from "react"
import SubProjectTree from "./SubProjectsTree"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { productionAssignClientIdAction } from "src/containers/production-container/actions/ProductionActions"
import ModuleTree from "./ModuleTree"

const ProjectTree = ({ index, item }) => {
  const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch();
  const handleSelectProject = (e, client) => {
    e.stopPropagation(); // Prevent event propagation
    setIsOpen(!isOpen)
    dispatch(productionAssignClientIdAction({
      data: {
        clientId: client._id,
        ClientProjectCode: client.ClientProjectCode
      }
    }))
  }
  return (
    <>
      {/* <div className="accordion acodne110" id="accordionExample"> 
      <div className="accordion-item">
        <h2 className="accordion-header" id="sub-headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sub-collapse6"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <div className="d-flex gap-3 align-items-center">
              {`${currentRecord?.RefClientId?.ClientProjectCode}(${currentRecord?.ProjectSubCode})`}
            </div>
          </button>
        </h2>
        <div
          id="sub-collapse6"
          className="accordion-collapse collapse show"
          aria-labelledby="sub-headingOne"
          data-bs-parent="#sub-accordion2"
        >
          <div className="accordion-body">
            <div
              className="accordion sub-accordion newSubCategory"
              id="sub-accordion3"
            >
              {
                currentRecord?.modules?.map((module, moduleIndex) => { 
                  return <ModuleTree module={module} key={moduleIndex} />
                })
              }
            </div>
          </div>
        </div>
      </div>
      </div> */}
      <div className="accordion acodne110" id="accordionExample"> 
      <div className="accordion-item">
        <h2 className="accordion-header" id="sub-headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#sub-collapseOne${currentRecord?._id}`}
            aria-expanded="true"
            aria-controls="collapseOne"
          // onClick={(e) => { handleSelectProject(e, item) }}
          >
            <div className="d-flex gap-3 align-items-center">
              {`${currentRecord?.RefClientId?.ClientProjectCode}(${currentRecord?.ProjectSubCode})`}
            </div>
          </button>
        </h2>
        {
          currentRecord?.modules?.map((module, moduleIndex) => {
            return <ModuleTree module={module} projectId={currentRecord?._id} key={moduleIndex} />
          })
        }
      </div>
      </div>
    </>
  )
}
export default ProjectTree