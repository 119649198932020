import React, { useState } from "react";

const MeasurementDropdown = ({handleUnitOfMeasureSelect}) => {
  const [selectedOption, setSelectedOption] = useState(null); // Changed to store a single option
  
  const options = [
    "Per Page",
    "Per Book",
    "Per PDF",
    "Per File",
    "Per QID",
    "Per Assessment",
    "Per Question",
    "Per Solution",
    "Per Word",
    "Per Character",
    "Per Minute",
    "Per Hour"
  ];

  const handleRadioChange = (option) => {
    handleUnitOfMeasureSelect(option);
    setSelectedOption(option); // Set the selected option
  };
  const renderButtonLabel = () => {
    return selectedOption ? selectedOption : "Select Measurement"; // Show selected option or default text
  };

  return (
    <div className="dropdown">
      <button
        className="btn border px-18 text-neutral-900 py-9 dropdown-toggle toggle-icon w-100 d-flex justify-content-between align-items-center w-200-px text-md"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {renderButtonLabel()}
      </button>
      <div className="dropdown-menu">
        <form className="navbar-search">
          <input type="text" name="search" placeholder="Search" />
          <span className="icon">
            <iconify-icon icon="ion:search-outline" className="icon" />
          </span>
        </form>
        <ul className="mx-hight200px px-12 py-12">
          {options.map((option, index) => (
            <li key={index}>
              <label className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0 dropdown-item">
                {option}
                <input
                  type="radio"
                  name="measurement" // Same name for all radio buttons
                  checked={selectedOption === option} // Check if this option is selected
                  onChange={() => handleRadioChange(option)} // Handle change
                />
                <span className="checkmark" />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MeasurementDropdown;
