import React, { useEffect, useState } from "react"; 

const RejectionAccessModal = ({ reApplyPendingList }) => {
  useEffect(() => {
    if (reApplyPendingList) {
      const modalElement = document.getElementById("RejectionAccessModal");
      const bootstrapModal = new window.bootstrap.Modal(modalElement, {
        backdrop: "static",
        keyboard: false,
      });
      bootstrapModal.show();

      // Cleanup to hide modal on component unmount
      return () => bootstrapModal.hide();
    }
  }, [reApplyPendingList]);

  return (
    <div
      className="modal fade"
      id="RejectionAccessModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content"> 
          <div className="modal-body text-center">
          <h5 className="fw-semibold mb-24 actbnred d-flex align-items-center gap-3 text-dark text-2xl"><img src="/assets/images/action-required-icon.svg" className="img-fluid" alt="" width="50" height="50"/> Urgent Action Required!!</h5>
          <div className="d-flex justify-content-between align-items-center px-16 py-12 bgne00w1 mb-24"><div className="T_P_tskText d-flex align-items-center gap-4 fw-semibold">Re-apply for Correct Production <span className="badge text-sm fw-semibold rounded-pill bg-warning-600 px-20 py-6 radius-4 text-white">Pending</span></div><div className="T_P_tsk mt-0">{reApplyPendingList.length}</div></div>
          <button type="button" className="btn-info-600 rounded-pill px-20 py-9" data-bs-dismiss="modal" aria-label="Close"> Action Now</button> 
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectionAccessModal
