import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const vendorSearchAPI = function (data) {
    const url = `${config.baseUrl}master/vendor/search`;
    return postRequest({
        url,
        data,
    });
};
export const vendorInvoiceSearchAPI = function (data) {
    const url = `${config.baseUrl}vendorInvoice/search`;
    return postRequest({
        url,
        data,
    });
};
export const getvendorAPI = function (data) {
    const url = `${config.baseUrl}master/vendor/getvendor`;
    return postRequest({
        url,
        data,
    });
};

export const vendorCreateAPI = function (data) {
    const url = `${config.baseUrl}master/vendor/save`;
    return postRequest({
        url,
        data,
    });
};
export const vendorUploadAPI = function (data) {
    const url = `${config.baseUrl}master/vendor/upload`;
    return postRequest({
        url,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
     
}

export const vendorUpdateAPI = function ({
    vendorId,
    data,
}) {
    const url = `${config.baseUrl}master/vendor/update/${vendorId}`;
    return putRequest({
        url,
        data,
    });
};
