import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const getUserInfoAndProductionGroupAPI = function (data) {
    const url = `${config.baseUrl}perfomance/getUserInfoAndProductionGroup`;
    return postRequest({url,data});
};
export const getHubStaffHourDateWiseAPI = function (data) {
    const url = `${config.baseUrl}report/get-hubstaff-date-wise`;
    return postRequest({
        url,
        data,
    });
};
export const getProductivityVsActivityAPI = function (data) {
    const url = `${config.baseUrl}report/get-productivity-vs-activity`;
    return postRequest({
        url,
        data,
    });
};
export const getProductivityVsHubstaffAPI = function (data) {
    const url = `${config.baseUrl}report/get-productivity-vs-hubstaff`;
    return postRequest({
        url,
        data,
    });
};
export const getProductionStatusWiseAPI = function (data) {
    const url = `${config.baseUrl}report/get-production-status-wise`;
    return postRequest({
        url,
        data,
    });
};
export const getIndividualProjectWiseCostAPI = function (data) {
    const url = `${config.baseUrl}report/get-individual-project-wise-cost`;
    return postRequest({
        url,
        data,
    });
};
export const getIndividualProjectModuleWiseCostAPI = function (data) {
    const url = `${config.baseUrl}report/get-individual-project-module-wise-cost`;
    return postRequest({
        url,
        data,
    });
};
export const getIndividualProjectTaskWiseCostAPI = function (data) {
    const url = `${config.baseUrl}report/get-individual-project-task-wise-cost`;
    return postRequest({
        url,
        data,
    });
};

export const getProductivityAPI = function (data) {
    const url = `${config.baseUrl}perfomance/save-Project-sub-category`;
    return putRequest({
        url,
        data,
    });
};
export const getProductionStatusAPI = function (data) {
    const url = `${config.baseUrl}perfomance/save-Project-sub-category`;
    return putRequest({
        url,
        data,
    });
};
export const getProductionInvestmentAPI = function (data) {
    const url = `${config.baseUrl}perfomance/save-Project-sub-category`;
    return putRequest({
        url,
        data,
    });
};
export const getProductionAPI = function (data) {
    const url = `${config.baseUrl}perfomance/save-Project-sub-category`;
    return putRequest({
        url,
        data,
    });
};