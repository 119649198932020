import React, { useCallback, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import DateRangePickerWithAll from "src/components/DateRangePickerWithAll";
import ProductionReviewActionModal from "./ProductionReviewActionModal";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProductionStatusWiseAPI } from "src/api/PerfomanceAPI";
import { secondsToTimeWithPoint } from "src/utils/utils";

const ProductionReview = () => {
  const { userId } = useParams();
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const [totalMinutes, setTotalMinutes] = useState(0);
  const [minutesData, setMinutesData] = useState({
    Accepted: 0,
    Pending: 0,
    Rejected: 0,
  });
  const [dateRangeStatus, setDateRangeStatus] = useState([
    moment().subtract(29, "days"),
    moment(),
  ]);
  const [where, setWhere] = useState({
    userId,
    $expr: {
      $and: [
        { $eq: [{ $year: "$date" }, selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] }
      ]
    }
  });
  useEffect(() => {
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, selectedYear] },
          { $eq: [{ $month: "$date" }, selectedMonth] }
        ]
      }
    }); 
  }, [selectedMonth, selectedYear]);
  const search = useCallback(async () => {
    const hubstaffData = await getProductionStatusWiseAPI({ where });
    const response = hubstaffData.data.data;     
    setMinutesData(response);
    setTotalMinutes(response.Accepted + response.Pending + response.Rejected);
     
  }, [where]);
  useEffect(() => {
    search();
  }, [where]);
  // State to hold total minutes and production data dynamically


  // Update the chart and table dynamically based on the selected date range
  // useEffect(() => {
  //   // Assuming the data is fetched or recalculated based on the selected date range
  //   // Here we mock the data update for example purpose
  //   const updatedMinutesData = {
  //     Accepted: Math.floor(Math.random() * 150 + 50), // Random data
  //     Pending: Math.floor(Math.random() * 200 + 100),
  //     Rejected: Math.floor(Math.random() * 100 + 50),
  //   };
  //   setMinutesData(updatedMinutesData);
  //   setTotalMinutes(updatedMinutesData.Accepted + updatedMinutesData.Pending + updatedMinutesData.Rejected);
  // }, [dateRangeStatus]);

  // Chart options
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "",
      align: "left",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat:
        "{series.name}: <b>{point.percentage:.1f}%</b><br>" +
        "<span style='font-size: 1.2em'><b>{point.name}</b></span><br>" +
        `<span style="font-size: 1.2em;">{point.mins} Mins</span>`,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format:
            '<span style="opacity: 1;font-size: 16px; color:#20A271; font-weight:normal;">{point.percentage:.1f} %</span><br>' +
            '<span style="font-size: 14px; color:#323338;"><b>{point.name}</b></span><br>' +
            '<span style="font-size: 14px; color:#7B7E8B; font-weight:normal;">{point.mins} Mins</span>',
          connectorColor: "rgba(128,128,128,0.5)",
        },
        showInLegend: true,
      },
    },
    legend: {
      enabled: false,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      // labelFormatter: function () {
      //   // Add a custom heading for the first legend item
      //   if (this.name === "Custom Heading") {
      //     return `<span style="font-weight: bold; font-size: 14px; text-decoration: underline;">${this.name}</span>`;
      //   }
      //   return this.name; // Return the name for other items
      // },
    },
    series: [
      {
        name: "Production Review",
        data: [
          { name: "Custom Heading", y: null, color: "transparent", showInLegend: true }, // Custom heading item
          { name: "Accepted", y: minutesData.Accepted, mins: minutesData.Accepted, color: "#00E272" },
          { name: "Pending", y: minutesData.Pending, mins: minutesData.Pending, color: "#FF9900" },
          { name: "Rejected", y: minutesData.Rejected, mins: minutesData.Rejected, color: "#FF5656" },
        ],
      },
    ],
  };
  
  

  return (
    <>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center py-16">
                  <h3 className="card-title">Production Review</h3>
                  <div>
                    {/* <DateRangePickerWithAll
                      value={dateRangeStatus}
                      onChange={setDateRangeStatus}
                    /> */}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center border-top border-bottom py-16 text-neutral-900">
                  Total Minutes{" "}
                  <span className="text-neutral-900 fw-bold text-lg">{totalMinutes} Mins</span>
                </div>
               <div className="pb-24 border-bottom">
               <HighchartsReact highcharts={Highcharts} options={options} />
               </div>

                {/* Dynamic Table */}
                <div className="table-responsive mt-24">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="border-start-0 border-bottom-0 border-endcolor"></th>
                        <th className="text-neutral-500 fw-medium text-center">Total Production</th>
                        <th className="text-neutral-500 fw-medium text-center">Accepted</th>
                        {/* <th className="text-neutral-500 fw-medium text-center">Hubstaff Duration</th>  */}
                        <th className="text-neutral-500 fw-medium text-center">Pending</th>
                        <th className="text-neutral-500 fw-medium text-center">Rejected</th>
                        <th className="text-neutral-500 fw-medium text-center">Consider for deduction</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-start-0 border-bottom-0 border-top-0 border-endcolor">
                          <div className="d-flex align-items-center gap-2 fw-bold text-lg text-neutral-600">
                            <span><img src="/assets/images/hours.svg" className="img-fluid" alt="" /></span>
                            Hours
                          </div>
                        </td>
                        <td className="text-sm text-center text-neutral-700">{secondsToTimeWithPoint(totalMinutes)} hrs</td>
                        <td className="text-sm text-center text-success-500">{secondsToTimeWithPoint(minutesData.Accepted)} hrs</td> 
                        {/* <td className="text-sm text-center text-success-500">{secondsToTimeWithPoint(minutesData.Accepted)} hrs</td> */}
                        <td className="text-sm text-center text-violet-600">{secondsToTimeWithPoint(minutesData.Pending)} hrs</td>
                        <td className="text-sm text-center text-danger-500">{secondsToTimeWithPoint(minutesData.Rejected)} hrs</td>
                        <td className="text-sm text-center"> <button type="button" className="badge text-sm fw-semibold bg-info-600 px-20 py-9 radius-4 text-white" data-bs-toggle="modal" data-bs-target="#ActionModal">Action</button>
                        </td>
                      </tr> 
                      {/* <tr>
                    <td className="border-start-0 border-bottom-0 border-top-0 border-endcolor"><div className="d-flex  align-items-center gap-2 fw-bold text-lg text-neutral-600"><span><img src="/assets/images/days.svg" className="img-fluid" altt="" /></span>  Days</div></td>
                    <td className="text-sm text-center text-neutral-700">{totalMinutes} hrs</td> 
                    <td className="text-sm text-center text-success-500">{minutesData.Accepted} hrs</td> 
                        <td className="text-sm text-center text-success-500">{minutesData.Accepted} Days</td>
                    <td className="text-sm text-center text-violet-600"> 5 hrs</td> 
                    <td className="text-sm text-center text-danger-500"> 3 hrs</td>  
                    <td className="text-sm text-center"> <button type="button" className="badge text-sm fw-semibold bg-info-600 px-20 py-9 radius-4 text-white" data-bs-toggle="modal" data-bs-target="#ActionModal">Action</button></td> 
                  </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProductionReviewActionModal />
    </>
  );
};

export default ProductionReview;
