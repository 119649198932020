import React, { useEffect, useRef, useState } from 'react'
import SearchInput from './SearchInput'
import DatePickerComponent from './DatePickerComponent';
import DropDownAuditTable from './DropDownAuditTable';
import MilestoneModal from './MilestoneModal';
import AuditCommentModal from './AuditCommentModal';
import FilterAuditReportModal from './FilterAuditReportModal';



const listItems = [
    {
        iconClass: 'mdi mdi-checkbox-blank-circle',
        label: 'Active',
        value: '320',
        isCurrency: false,
    },
    {
        iconClass: 'mdi mdi-checkbox-blank-circle',
        label: 'Inactive',
        value: '320',
        isCurrency: false,
    },
    {
        iconClass: '', // No icon for this item
        label: 'CMD',
        value: '₹ 36.5K',
        isCurrency: true,
    },
];




const milestones = [
    {
        name: 'Milestone 1',
        days: '(1-10 days)',
        amount: '₹ 25K',
        total: '₹45K',
        percentage: '+30%',
    },
    {
        name: 'Milestone 2',
        days: '(11-20 days)',
        amount: '₹ 25K',
        total: '₹45K',
        percentage: '-20%',
    },
    {
        name: 'Milestone 3',
        days: '(11-30 days)',
        amount: '₹ 25K',
        total: '₹45K',
        percentage: '+109%',
    },
];


const AuditReport = () => {
    const [isFlatPikerReset, setIsFlatPikerReset] = useState(false);
    const handleSearchInputChange = (e) => {
    };

    const handleDateRangeChange = (selectedDates) => {
    }


    // table section start code
    const [tableData] = useState([
        {
            id: 1,
            name: 'Sandeep Dubey',
            projectCode: 'P001-A',
            status: 'Active',
            cmd: 250000,
            milestones: [1500, 1000, 1000, 1000, 1000, 1000],
            documentation: 'Agreement',
            updatedBy: 'Monish Ahmad',
            updatedOn: '9 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 2,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Active',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 3,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Inactive',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 4,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Active',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 5,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Inactive',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 6,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Inactive',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 7,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Active',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 8,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Inactive',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 9,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Active',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 10,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Inactive',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        },
        {
            id: 11,
            name: 'Amit Sharma',
            projectCode: 'P002-B',
            status: 'Active',
            cmd: 600000,
            milestones: [2000, 1500, 2000, 1000, 1500, 2000],
            documentation: 'Contract',
            updatedBy: 'John Doe',
            updatedOn: '10 Sep 2024',
            auditorComment: 'Auditor comment',
        }
    ]);

    // State to track which accordion is open
    const [openAccordion, setOpenAccordion] = useState(null);

    // Function to toggle accordion and allow only one open at a time
    const toggleAccordion = (id) => {
        if (openAccordion === id) {
            setOpenAccordion(null); // If the same is clicked, close it
        } else {
            setOpenAccordion(id); // Open the clicked accordion
        }
    };
    // table section end code

   

    
// Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start

    
    return (
        <>
            <div class="card">
                <div className="card-header">
                    <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"><button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30"><g data-name="20-Arrow Left"><path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"></path><path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"></path></g></svg></button> Audit Report</h2>
                    <ul className="d-flex align-items-center gap-2"><li className="fw-medium"><a href="/" className="d-flex align-items-center gap-1 hover-text-primary"><iconify-icon icon="solar:home-smile-angle-outline" classname="icon text-lg"></iconify-icon>Home</a></li><li>-</li><li className="fw-medium"> Audit Report</li></ul>

                </div>

                <div class="card-body">
                    <div class="row">
                        <div className="col-md-5">
                            <div className="listCntbg">
                                {listItems.map((item, index) => (
                                    <div className="text-center listCnt" key={index}>
                                        {/* Conditionally render icon if it exists */}
                                        {item.iconClass && <i className={item.iconClass}></i>}
                                        {/* Conditionally render the currency symbol for CMD */}
                                        {item.isCurrency && <span className="smbllist">&#8377;</span>}
                                        <span className="d-block">{item.label}</span>
                                        <div className="countNresukt">{item.value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="listCntbg">
                                {milestones.map((milestone, index) => (
                                    <div className="text-center listCnt" key={index}>
                                        <span className="d-block">{milestone.name}</span>
                                        <span className="badge text-sm fw-semibold px-20 py-9 rounded-pill">
                                            {milestone.days}
                                        </span>
                                        <div className="countNresukt">
                                            {milestone.amount}
                                            <span className="kset">/{milestone.total}</span>{' '}
                                            <span className={`pftlos ${milestone.percentage.startsWith('+') ? 'plus' : 'minus'}`}>
                                                {milestone.percentage}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div class="col-md-12 pb-16 pt-24">
                            <span class="text-md fw-normal text-neutral-400 mb-12">Results: 45</span>
                            <h5 class="card-title text-primary-900"><i className="mdi mdi-account-multiple-outline mdi-24px"></i> Audit Report Details</h5>
                        </div>

                        <div className="d-flex align-items-center gap-4 mt-0">
                            {/* Search start */}
                            <SearchInput handleSearchInputChange={handleSearchInputChange} />
                            {/* Search End */}
                            <DatePickerComponent
                                onDateRangeChange={handleDateRangeChange}
                            />
                            {/* Filter button start */}
                            <div className="FilterBtn d-flex gap-2">
                                <button
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#FilterAuditReportModal"
                                    aria-controls="filterRightPopup"
                                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="2em"
                                        height="1.5em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                                        />
                                    </svg>{" "}
                                    Filter
                                </button>
                                <button type="reset"
                                    className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2">Reset</button>
                            </div>
                            {/* Filter button end */}
                        </div>

                        <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                <thead>
                    <tr className="dnd-moved">
                        <th className="w30px dragablefalse" draggable="false">
                            <input className="form-check-input" type="checkbox" />
                        </th>
                        <th className="w30px dragablefalse" draggable="false">
                            <label className="form-check-label">S.No.</label>
                        </th>
                        <th>Project Code </th>
                        <th className="text-center">Project Owner </th>
                        <th className="text-center">Status </th>
                        <th className="text-center">CMD</th>
                        <th className="text-center">Milestone 1 <span className="d-block">(1 - 10)</span></th>
                        <th className="text-center">Milestone 2 <span className="d-block">(11 - 20)</span></th>
                        <th className="text-center">Milestone 3 <span className="d-block">(21 - 30)</span></th>
                        <th className="text-center">Delivered <span className="d-block">(1 - 10)</span></th>
                        <th className="text-center">Delivered <span className="d-block">(11 - 20)</span></th>
                        <th className="text-center">Delivered <span className="d-block">(21 - 30)</span></th>
                        <th className="text-center">Documentation</th>
                        <th className="text-start" colSpan={3}>Feedback log </th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, index) => (
                        <React.Fragment key={row.id}>
                            <tr className="dnd-moved">
                                <td>
                                    <input className="form-check-input" type="checkbox" />
                                </td>
                                <td draggable="false">
                                    <label className="form-check-label">{index + 1}</label>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center gap-2">
                                        <button
                                            className={`btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 ${openAccordion === row.id ? '' : 'collapsed'}`}
                                            draggable="false"
                                            onClick={() => toggleAccordion(row.id)}
                                            aria-expanded={openAccordion === row.id}
                                            aria-controls={`demo${row.id}`}
                                        >
                                                                                        <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-caret-down-fill"
                                                viewBox="0 0 16 16"
                                                >
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>

                                        </button>
                                        <a href="javascript:void(0)" className="text-primary-600">{row.projectCode}</a>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center gap-2">
                                        <span className="userimg" />
                                        <div className="flex-grow-1">
                                            <h6 className="text-md mb-0 fw-medium text-primary-600">{row.name}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td><div className={row.status === 'Active' ? 'text-green' : 'text-red'}>{row.status} <i className="mdi mdi-checkbox-blank-circle"></i> </div></td>
                                <td><div>₹ {row.cmd}</div></td>
                                {row.milestones.map((milestone, index) => (
                                    <td key={index} className={index === 0 ? 'green text-white' : (index === 1 ? 'red text-white' : 'blue text-white')} data-bs-toggle="modal"   data-bs-target="#MilestoneModal">
                                        <div className="">{`₹ ${milestone}`}</div>
                                    </td>
                                ))}
                                <td><div className="">{row.documentation}</div></td>
                                <td colSpan={3}>
                                    <button type="button" className="btn rounded-pill btn-outline-primary-600 radius-8 px-14 py-6 text-sm d-flex align-items-center gap-2">
                                        <i className="mdi mdi-eye"></i>{" "}View{" "}
                                    </button>
                                </td>
                            </tr>
                            <tr id={`demo${row.id}`} className={`accordion-collapse collapse ${openAccordion === row.id ? 'show' : ''} bgliggry`}>
                                <td className="text-center" colSpan={5}>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <i className="mdi mdi-calendar mdi-24px text-primary-800"></i>
                                        <span className="badge text-sm fw-semibold rounded-pill bg-pink-600 px-20 py-9 radius-4 text-white">Week 1</span>
                                    </div>
                                </td>
                                <td><div>₹ 250000</div></td>
                                {row.milestones.map((milestone, index) => (
                                    <td key={index} className={index === 0 ? 'text-green' : (index === 1 ? 'text-red' : 'text-dblue')}>
                                        {`₹ ${milestone}`}
                                    </td>
                                ))}
                                     <td><DropDownAuditTable /> </td>
                                <td className="text-center">
                                    <div className="updateBy">
                                        <span className="d-block">Update by:</span>
                                        <span className="fw-semibold">{row.updatedBy}</span>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="updateOn">
                                        <span className="d-block">Updated on:</span>
                                        <span className="fw-bold">{row.updatedOn}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="AuditorCmt">
                                        <span className="fw-semibold">{row.auditorComment}</span>
                                        <button className="px-12 py-6 ms-4 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-eye mdi-24px"></i>
                                        </button>
                                        <button className="px-12 py-6 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-pencil mdi-24px"></i>
                                        </button>
                                        <button className="btn btn-primary-600 px-20 py-6 text-sm rounded-pill">Update</button>
                                    </div>
                                </td>
                            </tr>
                            <tr id={`demo${row.id}`} className={`accordion-collapse collapse ${openAccordion === row.id ? 'show' : ''} bgliggry`}>
                                <td className="text-center" colSpan={5}>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <i className="mdi mdi-calendar mdi-24px text-primary-800"></i>
                                        <span className="badge text-sm fw-semibold rounded-pill bg-pink-600 px-20 py-9 radius-4 text-white">Week 2</span>
                                    </div>
                                </td>
                                <td><div>₹ 250000</div></td>
                                {row.milestones.map((milestone, index) => (
                                    <td key={index} className={index === 0 ? 'text-green' : (index === 1 ? 'text-red' : 'text-dblue')}>
                                        {`₹ ${milestone}`}
                                    </td>
                                ))}
                                     <td><DropDownAuditTable /> </td>
                                <td className="text-center">
                                    <div className="updateBy">
                                        <span className="d-block">Update by:</span>
                                        <span className="fw-semibold">{row.updatedBy}</span>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="updateOn">
                                        <span className="d-block">Updated on:</span>
                                        <span className="fw-bold">{row.updatedOn}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="AuditorCmt">
                                        <span className="fw-semibold">{row.auditorComment}</span>
                                        <button className="px-12 py-6 ms-4 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-eye mdi-24px"></i>
                                        </button>
                                        <button className="px-12 py-6 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-pencil mdi-24px"></i>
                                        </button>
                                        <button className="btn btn-primary-600 px-20 py-6 text-sm rounded-pill">Update</button>
                                    </div>
                                </td>
                            </tr>
                            <tr id={`demo${row.id}`} className={`accordion-collapse collapse ${openAccordion === row.id ? 'show' : ''} bgliggry`}>
                                <td className="text-center" colSpan={5}>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <i className="mdi mdi-calendar mdi-24px text-primary-800"></i>
                                        <span className="badge text-sm fw-semibold rounded-pill bg-pink-600 px-20 py-9 radius-4 text-white">Week 3</span>
                                    </div>
                                </td>
                                <td><div>₹ 250000</div></td>
                                {row.milestones.map((milestone, index) => (
                                    <td key={index} className={index === 0 ? 'text-green' : (index === 1 ? 'text-red' : 'text-dblue')}>
                                        {`₹ ${milestone}`}
                                    </td>
                                ))}
                                     <td><DropDownAuditTable /> </td>
                                <td className="text-center">
                                    <div className="updateBy">
                                        <span className="d-block">Update by:</span>
                                        <span className="fw-semibold">{row.updatedBy}</span>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="updateOn">
                                        <span className="d-block">Updated on:</span>
                                        <span className="fw-bold">{row.updatedOn}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="AuditorCmt">
                                        <span className="fw-semibold">{row.auditorComment}</span>
                                        <button className="px-12 py-6 ms-4 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-eye mdi-24px"></i>
                                        </button>
                                        <button className="px-12 py-6 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-pencil mdi-24px"></i>
                                        </button>
                                        <button className="btn btn-primary-600 px-20 py-6 text-sm rounded-pill">Update</button>
                                    </div>
                                </td>
                            </tr>
                            <tr id={`demo${row.id}`} className={`accordion-collapse collapse ${openAccordion === row.id ? 'show' : ''} bgliggry`}>
                                <td className="text-center" colSpan={5}>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <i className="mdi mdi-calendar mdi-24px text-primary-800"></i>
                                        <span className="badge text-sm fw-semibold rounded-pill bg-pink-600 px-20 py-9 radius-4 text-white">Week 4</span>
                                    </div>
                                </td>
                                <td><div>₹ 250000</div></td>
                                {row.milestones.map((milestone, index) => (
                                    <td key={index} className={index === 0 ? 'text-green' : (index === 1 ? 'text-red' : 'text-dblue')}>
                                        {`₹ ${milestone}`}
                                    </td>
                                ))}
                                     <td><DropDownAuditTable /> </td>
                                <td className="text-center">
                                    <div className="updateBy">
                                        <span className="d-block">Update by:</span>
                                        <span className="fw-semibold">{row.updatedBy}</span>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="updateOn">
                                        <span className="d-block">Updated on:</span>
                                        <span className="fw-bold">{row.updatedOn}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="AuditorCmt">
                                        <span className="fw-semibold">{row.auditorComment}</span>
                                        <button className="px-12 py-6 ms-4 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-eye mdi-24px"></i>
                                        </button>
                                        <button className="px-12 py-6 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-pencil mdi-24px"></i>
                                        </button>
                                        <button className="btn btn-primary-600 px-20 py-6 text-sm rounded-pill">Update</button>
                                    </div>
                                </td>
                            </tr>
                            <tr id={`demo${row.id}`} className={`accordion-collapse collapse ${openAccordion === row.id ? 'show' : ''} bgliggry`}>
                                <td className="text-center" colSpan={5}>
                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                        <i className="mdi mdi-calendar mdi-24px text-primary-800"></i>
                                        <span className="badge text-sm fw-semibold rounded-pill bg-pink-600 px-20 py-9 radius-4 text-white">Week 5</span>
                                    </div>
                                </td>
                                <td><div>₹ 250000</div></td>
                                {row.milestones.map((milestone, index) => (
                                    <td key={index} className={index === 0 ? 'text-green' : (index === 1 ? 'text-red' : 'text-dblue')}>
                                        {`₹ ${milestone}`}
                                    </td>
                                ))}
                                     <td><DropDownAuditTable /> </td>
                                <td className="text-center">
                                    <div className="updateBy">
                                        <span className="d-block">Update by:</span>
                                        <span className="fw-semibold">{row.updatedBy}</span>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="updateOn">
                                        <span className="d-block">Updated on:</span>
                                        <span className="fw-bold">{row.updatedOn}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="AuditorCmt">
                                        <span className="fw-semibold">{row.auditorComment}</span>
                                        <button className="px-12 py-6 ms-4 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-eye mdi-24px"></i>
                                        </button>
                                        <button className="px-12 py-6 text-dblue" data-bs-toggle="modal"   data-bs-target="#AuditCommentModal">
                                            <i className="mdi mdi-pencil mdi-24px"></i>
                                        </button>
                                        <button className="btn btn-primary-600 px-20 py-6 text-sm rounded-pill">Update</button>
                                    </div>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>

                      


                    </div>
                </div>
            </div>

            <MilestoneModal />
            <AuditCommentModal />
            <FilterAuditReportModal />
        </>
    )
}

export default AuditReport
