import React, { useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import LeaveTable from 'src/components/Salary/LeaveTable';
import { useNavigate } from 'react-router-dom';
import SalaryComponent from 'src/components/Salary/SalaryCard';
import AttendanceCalendar from 'src/containers/production-container/AttendanceCalendar';
import GroupCounter from 'src/containers/production-container/GroupCounter';
import { salarySearchAction } from 'src/containers/salary-container/actions/SalaryActions';
import { useEffect } from 'react';
import { SalaryDefaultProps } from 'src/containers/salary-container/SalaryPropTypes';
import EmployeeDetails from 'src/containers/salary-container/EmployeeDetails';
import ShowDetailsEmployee from 'src/components/Salary/ShowDetailsEmployee';
import SalarySlip from 'src/containers/salary-container/SalarySlip';
 

const SalaryContainer = () => { 
    const dispatch = useDispatch();
    const {
        search,
    } = useSelector((state) => state.SalaryReducer, shallowEqual);
    const {
        _id:userId,
    } = useSelector((state) => state.auth.user, shallowEqual); 
    const salarySearch = () => {
        const payload = {
            where,
        }
        dispatch(salarySearchAction(payload));
    }
    const [where, setWhere] = useState(
        { _id: userId, active: true,type:"employee" }
    );
    const [user, setUser] = useState('');
    const [initialValues, setInitialValues] = useState( SalaryDefaultProps );
    const contentRef = useRef();

    useEffect(() => {
        salarySearch()
    }, [where])
    useEffect(() => {
        setUser({ ...search?.data?.userId, ...search?.data?.personal });
        setInitialValues(search?.data); 
    }, [search]);




    const [employeeDetails, setEmployeeDetails] = useState({
        totalLeaves: 0,
        adjustedSickLeaves: '1L',
        adjustedCasualLeaves: '1L',
        effectiveLeaves: 0.5,
        sickLeavesAmount: 800.00,
        totalDeduction: -400.00,
    }); 
    const handleDownload = () => {
        console.log('Downloading salary slip...');
    };  

 
       // State to track the visibility of the details and button label
  const [showDetails, setShowDetails] = useState(false);

  // Toggle function to switch between "Show" and "Hide" states
  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };
  const handleDownloadPDF = () => {
    const content = contentRef.current;

    // Use html2canvas to take a snapshot of the content.
    html2canvas(content, { scale: 3 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Calculate the scaled dimensions to maintain the aspect ratio
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const aspectRatio = canvasHeight / canvasWidth;
        const contentHeightInPDF = pdfWidth * aspectRatio;

        let position = 0;

        // Loop to create multiple pages if content height exceeds one page
        while (position < contentHeightInPDF) {
            pdf.addImage(
                imgData,
                'PNG',
                0,
                position,
                pdfWidth,
                pdfWidth * aspectRatio
            );
            position += pdfHeight;

            // Add new page if content overflows
            if (position < contentHeightInPDF) pdf.addPage();
        }

        pdf.save('SalarySlip.pdf');
    });
};

    return (
        <>
            <div className="card h-100">
                <div className="card-body p-24">
                    <div className="d-flex justify-content-between align-items-center border-bottom pb-16">
                        <div>
                            <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                                {/* <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={30} height={30}>
                                        <g data-name="20-Arrow Left">
                                            <path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
                                            <path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                                        </g>
                                    </svg>
                                </button> */}
                                Employee Salary Slip
                            </h2>
                            <ul className="d-flex align-items-center gap-2">
                                <li className="fw-medium">
                                    <a href="index-2.html" className="d-flex align-items-center gap-1 hover-text-primary">
                                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg" />
                                        Special Access
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">
                                    <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                        HR
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">Salary Slip</li>
                            </ul>
                        </div>
                        <div style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}>
                            <SalarySlip contentRef={contentRef} user={user} initialValues={initialValues}/>
                        </div>
                        
                        <div
                            className={`badge text-sm fw-semibold rounded-pill px-20 py-9 radius-4 ${
                                initialValues.status === false ? "text-warning-600 bg-warning-100" : ""
                            }`}
                            style={{ color: initialValues.status === true ? "green" : "orange" }}
                            >
                            {initialValues.status === false ? (
                           <>
                                <span>Salary Slip Status<i className="mdi mdi-clock mdi-16px"></i></span> Pending
                            </>
                            ) : (
                                <>
                            <a
                            href="#"
                            className="btn btn-primary text-sm btn-sm px-12 py-4 radius-8 d-flex align-items-center gap-2"
                            onClick={handleDownloadPDF}
                            >
                            Download Salary Slip <i className="mdi mdi-download mdi-18px"></i>
                        </a>
                                <span>Salary Slip generated<i className="mdi mdi-check-circle mdi-24px"></i></span>
                                </>
                            )}
                            </div>
                    </div>

                    {/* <EmployeeDetails employeeDetails={employeeDetails} /> */}
                    <EmployeeDetails user={user} />
                    <div className="mt-24">
                        <h4 className="card-title">Production Details</h4>
                        <GroupCounter />
                        <AttendanceCalendar />
                        <div className="d-flex flex-wrap align-items-center gap-4 py-16 border-bottom">
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-green-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Presents</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-pink-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Holidays</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-yellow-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Sick Leave</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-blue-500 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Casual Leave</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-zinc-300 rounded-circle" />
                                <span className="text-zinc-300 fw-medium">Week Off</span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="w-12-px h-12-px bg-yellow-200 rounded-circle" />
                                <span className="text-neutral-900 fw-medium">Half Day</span>
                            </div>
                        </div>
                        <div className='text-end d-flex justify-content-end pt-16'> <button
                        className="text-primary-600 hover-text-primary d-flex align-items-center gap-1 fw-semibold"
                        onClick={toggleDetails}
                        >
                        {showDetails ? 'Hide Details' : 'Show Details'}
                        </button></div>
                        {showDetails && (
                        <div className="showdtalponew">
                        <ShowDetailsEmployee  />
                        </div>
                        )}
                        </div>

                    {/* <div className='my-24'>
                    <SalaryComponent employeeDetails={employeeDetails} />
                    </div> */}
                    <div className="row">
                        <div className="col-md-12 col-xl-6">
                            <div className="radius-10 border p-16">
                                 <div className="">
                                <h3 className="text-xxl">Leave Details</h3>
                                <ul className="py-16">
                                    <li className="d-flex justify-content-between align-items-center py-2">Total Leaves <span className="fw-semibold text-neutral-900">{initialValues.leaveDetails?.totalAbsent} Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Adjusted Sick Leaves <span className="fw-semibold text-neutral-900">{initialValues.leaveDetails?.adjustedSL} Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Adjusted Casual Leaves <span className="fw-semibold text-neutral-900">{initialValues.leaveDetails?.adjustedCL} Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Effective Leaves <span className="fw-semibold text-neutral-900">{initialValues.leaveDetails?.otherLeaveDeduction} Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 border-top mt-16">Adjusted Casual Leaves Amount <span className="fw-semibold text-neutral-900">₹{initialValues.leaveDetails?.adjustedCasualLeavesAmount?.toFixed(2)}</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 border-bottom">Adjusted Sick Leaves Amount <span className="fw-semibold text-neutral-900">₹{initialValues.leaveDetails?.adjustedSickLeavesAmount?.toFixed(2)}</span></li>
                                    <li className="d-flex justify-content-between align-items-center pt-4 pb-16 my-16 text-neutral-900 fw-medium border-bottom">Total Leave Deductions Amount <span className="fw-semibold text-neutral-900 text-xxl"> -₹{initialValues.leaveDetails?.totalLeaveDeductionAmount}</span></li>
                                </ul>
                            </div>

                               <div className="">
                               <h3 className="text-xxl">Lag Deduction Detail</h3>
                               <ul className="py-16">
                                    <li className="d-flex justify-content-between align-items-center py-2">Total Working Hours   <span className="fw-semibold text-neutral-900">{initialValues.lagHourDetails?.totalWorkingHours} h</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Required Hours   <span className="fw-semibold text-neutral-900">{initialValues.lagHourDetails?.requiredHours} h</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Exact Hours   <span className="fw-semibold text-neutral-900">{initialValues.lagHourDetails?.exactHours} h</span></li> 
                                    <li className="d-flex justify-content-between align-items-center py-10 border-top mt-16 gap-2"><span className="w-100">Lag Hours</span> <div className="w-100"><span className="fw-semibold text-neutral-900">{initialValues.lagHourDetails?.lagHours}</span> hours</div>  <span className="fw-semibold text-neutral-900 w-100 text-end">-₹{initialValues?.lagHourDetails?.lagHoursAmount}</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 border-bottom gap-2"><span className="w-100">Production Lag</span> <div className="w-100"><span className="fw-semibold text-neutral-900">{initialValues.lagHourDetails?.productionLagDay}</span> Days</div> <span className="fw-semibold text-neutral-900 w-100 text-end">{initialValues?.lagHourDetails?.productionLagDayAmount}</span></li>
                                    <li className="d-flex justify-content-between align-items-center pt-4 pb-16 my-16 text-neutral-900 fw-medium border-bottom">Total Deductions <span className="fw-semibold text-neutral-900 text-xxl"> -₹{initialValues?.lagHourDetails?.lagHoursAmount||0+initialValues?.lagHourDetails?.productionLagDayAmount||0}</span></li> 
                                </ul>
                               </div>

                               <div className="">
                               <h3 className="text-xxl">Encashment Details</h3>
                               <ul className="py-16">  
                                    <li className="d-flex justify-content-between align-items-center py-10 gap-2"><span className="w-100">Leave Encashment</span> <div className="w-100"><span className="fw-semibold text-neutral-900">{initialValues.encashmentDetails?.leaveEncashment}</span> Days</div>  <span className="fw-semibold text-neutral-900 w-100 text-end">+₹{initialValues.encashmentDetails?.leaveEncashmentAmount}</span></li> 
                                    <li className="d-flex justify-content-between align-items-center py-10 gap-2"><span className="w-100">Incentives</span> <div className="w-100"><span className="fw-semibold text-neutral-900">{initialValues.encashmentDetails?.incentives}</span> Days</div> <span className="fw-semibold text-neutral-900 w-100 text-end">+₹{initialValues.encashmentDetails?.incentivesAmount}</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10 gap-2"><span className="w-100">Previous Month</span> <div className="w-100"><span className="fw-semibold text-neutral-900">{initialValues.encashmentDetails?.previousMonth}</span> Days</div>  <span className="fw-semibold text-neutral-900 w-100 text-end">+₹{initialValues.encashmentDetails?.previousMonth}</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-10">Performance bonus   <span className="fw-semibold text-neutral-900">{initialValues.encashmentDetails?.performanceBonus}</span></li> 

                                    <li className="d-flex justify-content-between align-items-center pt-4 pb-16 my-16 text-neutral-900 fw-medium border-bottom">Total Encashment Amount <span className="fw-semibold text-neutral-900 text-xxl"> +₹{initialValues.encashmentDetails?.totalEncashment}</span></li> 
                                </ul>
                               </div>

                               <div className="">
                                <h3 className="text-xxl">Final Salary</h3>
                                <div className="text-xxl d-flex justify-content-between align-items-center text-neutral-500 fw-medium border-bottom py-20">Salary <span>₹{initialValues?.monthlySalary?.toFixed(2)}</span></div>
                                <ul className="py-16">
                                    <li className="d-flex justify-content-between align-items-center py-2">Leave Deduction <span className="fw-semibold text-neutral-900">{initialValues?.otherLeaveDeductionAmount?.toFixed(2)} Day</span></li>
                                    <li className="d-flex justify-content-between align-items-center py-2">Lag Deduction <span className="fw-semibold text-neutral-900">{initialValues?.lagHourDetails?.lagHoursAmount} Day</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">PF Deduction <span className="fw-semibold text-neutral-900">{initialValues?.lagHourDetails?.pfDeduction}</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">Tax Deduction <span className="fw-semibold text-neutral-900">{initialValues?.lagHourDetails?.taxDeduction}</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">Other Deduction <span className="fw-semibold text-neutral-900">{initialValues?.lagHourDetails?.otherDeduction}</span></li>  
                                    <li className="d-flex justify-content-between align-items-center py-2">Total Encashment <span className="fw-semibold text-neutral-900">{initialValues?.encashmentDetails?.totalEncashment}</span></li> 
                                    <li className="d-flex justify-content-between align-items-center mt-16 text-neutral-900 fw-medium border-bottom bg-primary-100 p-16 radius-10">Total Payable Net Amount
                                     <span className="fw-semibold text-dblue text-xxl">₹{initialValues?.netSalary}</span></li>  
                                </ul>
                                <div className="Remarks d-inline-flex gap-2"><span className="fw-medium">Remarks:</span> {initialValues?.remarks}</div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-12 col-xl-6">
                            <LeaveTable className="pssitiky" leaves={initialValues.leaves} />
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};
export default SalaryContainer