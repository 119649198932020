import React from 'react'
import { formatDate } from 'src/utils/utils';
const ViewInvoiceListModal = ({ selectedDelivery }) => {
  console.log('selectedDelivery=>',selectedDelivery)
  const invoiceData = selectedDelivery[0]?.vendorInvoiceDetails
  ?.filter((invoiceDt) => invoiceDt && invoiceDt.invoiceMain) // Ensure valid data
  .map((invoiceDt, index) => ({
    id: index + 1,
    _id: invoiceDt._id,
    mainId: invoiceDt.invoiceMain._id,
    invoiceNo: invoiceDt.invoiceMain.invoiceNo,
    date: invoiceDt.invoiceMain.date,
    qty: invoiceDt.paidUnit,
    vendorId: selectedDelivery[0]?.vendorId, // Safely access vendorId
  })) || []; // Return an empty array if no valid data exists

  // Function to handle the PDF generation and download for ClientInvoiceDownload
  const handleOpenInvoice = (invoice) => {
    if (invoice.vendorId.gstNo)
      window.open(`/projects/view/tax-invoice-preview/${invoice.mainId}`, '_blank');
    else
      window.open(`/projects/view/freelancer-invoice-preview/${invoice.mainId}`, '_blank');
  };
  return (
    <div>
      <div className="modal fade cusmodl LeaveDetailsModal" id="viewInvoiceListModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between border-0">
              <h6 className='modal-title'>Invoice List</h6>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
            <div className="modal-body py-0">
              <div className="table-container1">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr>
                      <th className="w-32-px">S.No.</th>
                      <th>Invoice No.</th>
                      <th>Date</th>
                      <th className="text-center">Qty.</th>
                      <th className="text-center">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData?.map((invoice, index) => (
                      <tr key={invoice.id}>
                        <td className="w-32-px">{String(index + 1).padStart(2, '0')}</td>
                        <td>{invoice.invoiceNo||"NA"}</td>
                        <td>{formatDate(invoice.date)}</td>
                        <td className="text-center">{invoice.qty}</td>
                        <td className="text-center">
                          <button className="download-button border px-20 py-9 radius-6"
                            onClick={() => { handleOpenInvoice(invoice) }}
                          >
                            Preview Invoice
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewInvoiceListModal
